import { format, isToday, isTomorrow, isYesterday } from 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getClassroomEvents } from '../../../../services/classrooms/eventsService';
import { enUS, ru, kk } from 'date-fns/locale';
import Add from './BREAD/Add/Add';
import EventCard from './EventCard/EventCard';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';

export default function SectionEvents({ classroom, section, isTeacher }) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dateEvents, setDateEvents] = useState({});
  const [events, setEvents] = useState([]);

  const groupItems = (items) => {
    return items.reduce((acc, item) => {
      const date = new Date(item.start_time).toISOString().split('T')[0];

      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };
  useEffect(() => {
    setEvents([]);
    setDateEvents({});
  }, [section]);

  const fetchEvents = useCallback(() => {
    if (!section) return;
    if (loading) return;
    setLoading(true);
    getClassroomEvents({ section, page_size: 50 })
      .then((res) => {
        setEvents(res.results);
        setDateEvents(groupItems(res.results));
      })
      .catch((err) => {
        toast.error(t('Failed to load classroom data'));
        console.error(
          'Error loading classroom data:',
          err.response ? err.response.data : err.message,
        );
      })
      ?.finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, t]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <div className='flex flex-col gap-4'>
      {isTeacher && (
        <Add
          classroom={classroom}
          section={section}
          addObject={(obj) => {
            setDateEvents(groupItems([...events, obj]));
            setEvents((prev) => [...prev, obj]);
          }}
        />
      )}
      {loading ? (
        <div className='relative'>
          <SimpleLoader className='h-5' />
        </div>
      ) : (
        Object.keys(dateEvents).map((key) => {
          let toDayEvents = dateEvents[key];
          return (
            <div key={key} className='flex flex-col gap-2'>
              <div className='flex flex-row w-full items-center gap-2'>
                <span className='min-w-fit flex flex-row gap-2'>
                  {`${
                    isYesterday(new Date(key))
                      ? 'Yesterday: '
                      : isToday(new Date(key))
                      ? 'Today: '
                      : isTomorrow(new Date(key))
                      ? 'Tomorrow: '
                      : ''
                  }${format(new Date(key), 'do MMMM', { locale: enUS })}
                `}
                </span>
                <div className='border-t w-full border-gray-400' />
              </div>

              <div className='flex flex-col gap-6'>
                {toDayEvents?.map((event) => {
                  return (
                    <EventCard
                      key={event.uuid}
                      event={event}
                      isTeacher={isTeacher}
                      removeEvent={() => {
                        const newEvents = events.filter(
                          (e) => e.uuid !== event.uuid,
                        );
                        setDateEvents(groupItems(newEvents));
                        setEvents(newEvents);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
