import { useState } from 'react';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import { joinVideoConference } from '../../../../../../services/videoConference/videoConferenceService';

export default function MeetingView({ event }) {
  const [loading, setLoading] = useState(false);

  return (
    <div className={`overflow-hidden w-full flex flex-row justify-end`}>
      <Button
        color='success'
        text='Join meeting'
        loading={loading}
        onClick={() => {
          setLoading(true);
          joinVideoConference(event?.event?.meeting?.uuid, {})
            .then((res) => window.open(res.link, '_blank').focus())
            .catch(() => {})
            .finally(() => setLoading(false));
        }}
      />
    </div>
  );
}
