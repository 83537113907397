import React, { useCallback, useRef, useState } from 'react';
import useAutosizeTextArea from '../../../../../../utils/hooks/useAutosizeTextArea';

export default function TextAnswer({ content }) {
  const [value, setValue] = useState(content?.answer?.answer ?? '');
  const ref = useRef();
  useAutosizeTextArea(ref, value);

  return (
    <div className='flex flex-col'>
      <textarea
        className='h-auto border-b focus:outline-none overflow-hidden disabled:bg-transparent'
        ref={ref}
        style={{ resize: 'none' }}
        value={value}
        disabled
      />
    </div>
  );
}
