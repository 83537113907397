import { Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import {
  AcademicCapIcon,
  Bars3Icon,
  ChartBarIcon,
  ClipboardDocumentIcon,
  DocumentCheckIcon,
  DocumentPlusIcon,
  HomeIcon,
  PencilSquareIcon,
  PuzzlePieceIcon,
  XMarkIcon,
  Squares2X2Icon,
  UserCircleIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { classNames } from '../../../../utils/helpers/helpers';
import TokenService from '../../../../services/token.service';
import logo from '../../../../assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import IconMenuItem from './IconMenuItem';
import {
  AcademicCapIcon as SolidAcademicCapIcon,
  PencilSquareIcon as SolidPencilSquareIcon,
  MapIcon,
  DocumentIcon,
  ListBulletIcon,
  VideoCameraIcon,
  BookOpenIcon,
} from '@heroicons/react/24/solid';
import { SidebarContext } from '../../../../utils/contexts/SidebarContext';
import BentoBoxIcon from '../../../Icons/BentoBoxIcon';

export default function NavSidebar({ user, setUser, className }) {
  const [t] = useTranslation();
  const { setId } = useContext(SidebarContext);
  const navigate = useNavigate();

  const [navigation, setNavigation] = useState([]);
  // const userNavigation = [{ name: 'Profiel', href: '/profile' }, { name: 'Sign out', href: '#' }];

  useEffect(() => {
    if (user?.uuid) {
      if (user?.roles.some((r) => r.name === 'STUDENT')) {
        setNavigation([
          {
            name: 'Feed',
            href: 'feed',
            icon: Squares2X2Icon,
          },
          // {
          //   name: t('roadmap.roadmap'),
          //   href: 'roadmap',
          //   icon: MapIcon,
          // },
          // {
          //   name: 'Dashboard',
          //   href: 'dashboard',
          //   icon: RectangleGroupIcon,
          // },
          // {
          //   name: 'Universities',
          //   href: 'universities',
          //   icon: AcademicCapIcon,
          // },
          // {
          //   name: 'Personality & Skills',
          //   href: 'tests',
          //   icon: PencilSquareIcon,
          // },
          // {
          //   name: t('performance.performance'),
          //   href: 'performance',
          //   icon: TrophyIcon,
          // },
          // {
          //   name: 'Courses',
          //   href: 'courses',
          //   icon: ClipboardDocumentIcon,
          // },
          {
            name: 'Rating',
            href: 'rating',
            icon: StarIcon,
          },
          {
            name: 'Problem Sets',
            href: 'study-plans',
            icon: ClipboardDocumentIcon,
          },
          {
            name: 'Problems',
            href: 'projects',
            icon: PuzzlePieceIcon,
          },
        ]);
      }
      if (user?.roles.some((role) => role.name === 'PROJECTREVIEWER')) {
        setNavigation([
          {
            name: 'Feed',
            href: 'feed',
            icon: Squares2X2Icon,
          },
          // {
          //   name: t('dashboard.dashboard'),
          //   href: 'stats',
          //   icon: HomeIcon,
          // },
          // {
          //   name: 'Universities',
          //   href: 'universities',
          //   icon: AcademicCapIcon,
          // },
          // {
          //   name: 'Personality & Skills',
          //   href: 'tests',
          //   icon: PencilSquareIcon,
          // },
          // {
          //   name: t('stats.leaderboard'),
          //   href: 'leaderboard',
          //   icon: ChartBarIcon,
          // },
          {
            name: 'Submissions',
            href: 'submissions',
            icon: DocumentCheckIcon,
          },
        ]);
      }
      if (user?.roles.some((r) => r.name === 'PROJECTADMIN')) {
        setNavigation([
          {
            name: 'Feed',
            href: 'feed',
            icon: Squares2X2Icon,
          },
          // {
          //   name: t('dashboard.dashboard'),
          //   href: 'stats',
          //   icon: HomeIcon,
          // },
          // {
          //   name: t('stats.leaderboard'),
          //   href: 'leaderboard',
          //   icon: ChartBarIcon,
          // },
          // {
          //   name: 'Universities',
          //   href: 'universities',
          //   icon: AcademicCapIcon,
          // },
          // {
          //   name: 'Personality & Skills',
          //   href: 'tests',
          //   icon: PencilSquareIcon,
          // },
          // {
          //   name: 'Courses',
          //   href: 'courses',
          //   icon: ClipboardDocumentIcon,
          // },
          {
            name: 'Rating',
            href: 'rating',
            icon: StarIcon,
          },
          {
            name: 'Problem Sets',
            href: 'study-plans',
            icon: ClipboardDocumentIcon,
          },
          {
            name: 'Problems',
            href: 'projects',
            icon: PuzzlePieceIcon,
          },
          {
            name: 'Problem\n assignments',
            href: 'assignments',
            icon: DocumentPlusIcon,
          },
          {
            name: 'Problem\n submissions',
            href: 'submissions',
            icon: DocumentCheckIcon,
          },
        ]);
      }
      if (user.roles.some((r) => r.name === 'ADMIN')) {
        setNavigation((prev) => [
          {
            name: 'Feed',
            href: 'feed',
            icon: Squares2X2Icon,
          },
          // {
          //   name: t('dashboard.dashboard'),
          //   href: 'stats',
          //   icon: HomeIcon,
          // },
          // {
          //   name: t('stats.leaderboard'),
          //   href: 'leaderboard',
          //   icon: ChartBarIcon,
          // },
          // {
          //   name: 'Universities',
          //   href: 'universities',
          //   icon: AcademicCapIcon,
          // },
          // {
          //   name: 'Personality & Skills',
          //   href: 'tests',
          //   icon: PencilSquareIcon,
          // },
          // {
          //   name: 'Courses',
          //   href: 'courses',
          //   icon: ClipboardDocumentIcon,
          // },
          {
            name: 'Rating',
            href: 'rating',
            icon: StarIcon,
          },
          {
            name: 'Problem Sets',
            href: 'study-plans',
            icon: ClipboardDocumentIcon,
          },
          {
            name: 'Problems',
            href: 'projects',
            icon: PuzzlePieceIcon,
          },
          {
            name: 'Assignments',
            href: 'assignments',
            icon: DocumentPlusIcon,
          },
          {
            name: 'Submissions',
            href: 'submissions',
            icon: DocumentCheckIcon,
          },

          // { name: 'Result', href: 'results', icon: PencilSquareIcon },
        ]);
      }
    }

    return () => {};
    // eslint-disable-next-line
  }, [user]);

  return (
    <Disclosure as='nav' className={`shadow-md z-30 ${className}`}>
      {({ open }) => (
        <>
          <div className='mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex h-[7vh] items-center justify-between'>
              <div className='flex items-center'>
                <div className='flex-shrink-0'>
                  <img src={logo} alt='logo' className='w-10 h-10 mr-6' />
                </div>
                <div className='hidden md:block'>
                  <div className='flex items-baseline space-x-6'>
                    {navigation.map((item, i) => (
                      <IconMenuItem key={i} item={item} />
                    ))}
                  </div>
                </div>
              </div>
              <div className='hidden md:block'>
                <div></div>
                <div className='ml-4 flex items-center md:ml-6'>
                  {/* <button type='button' className='rounded-md p-1 '>
                    <span className='sr-only'>View notifications</span>
                    <VideoCameraIcon
                      className='h-6 w-6'
                      aria-hidden='true'
                      onClick={async () => {
                        navigate('video-conference');
                      }}
                    />
                  </button> */}

                  <Popover className='z-20'>
                    {({ open }) => (
                      <>
                        <Popover.Button className='focus:outline-none'>
                          <BentoBoxIcon className='h-6 w-6' />
                        </Popover.Button>

                        <Transition
                          show={open}
                          enter='transition duration-100 ease-out'
                          enterFrom='transform scale-95 opacity-0'
                          enterTo='transform scale-100 opacity-100'
                          leave='transition duration-75 ease-out'
                          leaveFrom='transform scale-100 opacity-100'
                          leaveTo='transform scale-95 opacity-0'
                        >
                          <Popover.Panel
                            static
                            className='absolute top-full -right-6 mt-2'
                          >
                            {/* <div className='flex flex-row justify-end bg-white p-4 rounded w-fit h-fit border items-center'> */}
                            <div className='grid grid-cols-3 bg-white p-4 rounded w-72 h-fit border items-center'>
                              <NavLink
                                to='video-conference'
                                className={({ isActive }) =>
                                  `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                                }
                              >
                                <VideoCameraIcon
                                  className='h-10 w-10'
                                  aria-hidden='true'
                                />
                                <div className='whitespace-pre-line leading-tight'>
                                  {'Meeting'}
                                </div>
                              </NavLink>
                              {user?.roles?.every(
                                (role) => role.name !== 'STUDENT',
                              ) && (
                                <NavLink
                                  to='forms'
                                  className={({ isActive }) =>
                                    `flex flex-col items-center justify-center relative text-sm font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                                  }
                                >
                                  <DocumentIcon
                                    className='h-10 w-10'
                                    aria-hidden='true'
                                  />
                                  <ListBulletIcon className='z-10 h-5 top-[1.6rem] left-[1.85rem] absolute text-white' />
                                  <div className='whitespace-pre-line leading-tight'>
                                    {'Forms'}
                                  </div>
                                </NavLink>
                              )}
                              <NavLink
                                to='classrooms'
                                className={({ isActive }) =>
                                  `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                                }
                              >
                                <BookOpenIcon className='h-10 w-10' />
                                <div className='whitespace-pre-line leading-tight'>
                                  {'Classrooms'}
                                </div>
                              </NavLink>
                              <NavLink
                                to='tests'
                                className={({ isActive }) =>
                                  `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                                }
                              >
                                <SolidPencilSquareIcon
                                  className='h-10 w-10'
                                  aria-hidden='true'
                                />
                                <div className='whitespace-pre-line leading-tight'>
                                  {'Personality & Skills'}
                                </div>
                              </NavLink>
                              <NavLink
                                to='roadmap'
                                className={({ isActive }) =>
                                  `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                                }
                              >
                                <MapIcon
                                  className='h-10 w-10'
                                  aria-hidden='true'
                                />
                                <div className='whitespace-pre-line leading-tight'>
                                  {t('roadmap.roadmap')}
                                </div>
                              </NavLink>
                              <NavLink
                                to='universities'
                                className={({ isActive }) =>
                                  `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                                }
                              >
                                <SolidAcademicCapIcon
                                  className='h-10 w-10'
                                  aria-hidden='true'
                                />
                                <div className='whitespace-pre-line leading-tight'>
                                  {'Universities'}
                                </div>
                              </NavLink>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  {/* Profile dropdown */}
                  <Menu as='div' className='relative ml-3 z-20'>
                    <div>
                      <Menu.Button className='rounded-full p-1 border border-purple-500'>
                        <span className='sr-only'>Open user menu</span>
                        {/* <UserCircleIcon
                          className='h-8 w-8'
                          aria-hidden='true'
                        /> */}
                        {/* sdsad */}
                        {!user?.profile_picture ? (
                          <img
                            src={user?.profile_picture}
                            alt='User Avatar'
                            className='w-8 h-8 rounded-full object-cover'
                          />
                        ) : (
                          <UserCircleIcon
                            className='h-8 w-8'
                            aria-hidden='true'
                          />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className=' ring-black absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
                        {/* {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <button
                                // href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full px-4 py-2 text-left text-sm font-semibold text-gray-700',
                                )}
                                onClick={() => {
                                  TokenService.removeTokens();
                                  setUser(null);
                                  navigate('/login');
                                }}
                              >
                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))} */}
                        {user?.roles?.some(
                          (role) => role.name === 'STUDENT',
                        ) && (
                          <Menu.Item key={'profile'}>
                            {({ active }) => (
                              <button
                                // href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full px-4 py-2 text-left text-sm font-semibold text-gray-700',
                                )}
                                onClick={() => {
                                  navigate(`/profile/${user?.uuid}`);
                                }}
                              >
                                {'Profile'}
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        <Menu.Item key={'signout'}>
                          {({ active }) => (
                            <button
                              // href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block w-full px-4 py-2 text-left text-sm font-semibold text-gray-700',
                              )}
                              onClick={() => {
                                TokenService.removeTokens();
                                setUser(null);
                                navigate('/login');
                              }}
                            >
                              {'Sign out'}
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className='flex flex-row items-center gap-2 md:hidden'>
                <Popover className='z-20 '>
                  {({ open }) => (
                    <>
                      {/* <Disclosure.Button
                    onClick={() => setId('')}
                    className=''
                  >
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button> */}
                      <Popover.Button
                        className='inline-flex items-center justify-center rounded-md p-2 text-gray-400  
                      focus:outline-none'
                      >
                        <BentoBoxIcon className='h-6 w-6 block' />
                      </Popover.Button>

                      <Transition
                        show={open}
                        enter='transition duration-100 ease-out'
                        enterFrom='transform scale-95 opacity-0'
                        enterTo='transform scale-100 opacity-100'
                        leave='transition duration-75 ease-out'
                        leaveFrom='transform scale-100 opacity-100'
                        leaveTo='transform scale-95 opacity-0'
                      >
                        <Popover.Panel
                          static
                          className='absolute top-full -right-6 mt-2'
                        >
                          {/* <div className='flex flex-row justify-end bg-white p-4 rounded w-fit h-fit border items-center'> */}
                          <div className='grid grid-cols-3 bg-white p-4 rounded w-72 h-fit border items-center'>
                            <NavLink
                              to='video-conference'
                              className={({ isActive }) =>
                                `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                              }
                            >
                              <VideoCameraIcon
                                className='h-10 w-10'
                                aria-hidden='true'
                              />
                              <div className='whitespace-pre-line leading-tight'>
                                {'Meeting'}
                              </div>
                            </NavLink>
                            {user?.roles?.every(
                              (role) => role.name !== 'STUDENT',
                            ) && (
                              <NavLink
                                to='forms'
                                className={({ isActive }) =>
                                  `flex flex-col items-center justify-center relative text-sm font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                                }
                              >
                                <DocumentIcon
                                  className='h-10 w-10'
                                  aria-hidden='true'
                                />
                                <ListBulletIcon className='z-10 h-5 top-[1.6rem] left-[1.85rem] absolute text-white' />
                                <div className='whitespace-pre-line leading-tight'>
                                  {'Forms'}
                                </div>
                              </NavLink>
                            )}
                            <NavLink
                              to='classrooms'
                              className={({ isActive }) =>
                                `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                              }
                            >
                              <BookOpenIcon className='h-10 w-10' />
                              <div className='whitespace-pre-line leading-tight'>
                                {'Classrooms'}
                              </div>
                            </NavLink>
                            <NavLink
                              to='tests'
                              className={({ isActive }) =>
                                `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                              }
                            >
                              <SolidPencilSquareIcon
                                className='h-10 w-10'
                                aria-hidden='true'
                              />
                              <div className='whitespace-pre-line leading-tight'>
                                {'Personality & Skills'}
                              </div>
                            </NavLink>
                            <NavLink
                              to='roadmap'
                              className={({ isActive }) =>
                                `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                              }
                            >
                              <MapIcon
                                className='h-10 w-10'
                                aria-hidden='true'
                              />
                              <div className='whitespace-pre-line leading-tight'>
                                {t('roadmap.roadmap')}
                              </div>
                            </NavLink>
                            <NavLink
                              to='universities'
                              className={({ isActive }) =>
                                `flex flex-col items-center justify-center relative text-xs font-medium w-20 h-20 rounded-lg 
                                    text-center text-purple-500 hover:bg-gray-200`
                              }
                            >
                              <SolidAcademicCapIcon
                                className='h-10 w-10'
                                aria-hidden='true'
                              />
                              <div className='whitespace-pre-line leading-tight'>
                                {'Universities'}
                              </div>
                            </NavLink>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <div className='-mr-2 flex md:hidden'>
                  {/* Mobile menu button */}
                  <Disclosure.Button
                    onClick={() => setId('')}
                    className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-purple-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-purple-600'
                  >
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='md:hidden z-50'>
            <div className='space-y-1 px-2 pt-2 pb-3 sm:px-3 z-50'>
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  // as={NavLink}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-purple-200'
                      : 'text-gray-600 hover:bg-purple-400 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium z-50',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <span>{item.name}</span>
                </NavLink>
              ))}
            </div>
            <div className='border-t border-gray-700 pt-4 pb-3'>
              <div className='flex items-center px-5'>
                <div className='flex-shrink-0'>
                  {/* <img
                        className='h-10 w-10 rounded-full'
                        src={user.imageUrl}
                        alt=''
                      /> */}
                </div>
                <div className='ml-3'>
                  <div className='text-base font-medium leading-none'>
                    {user?.name}
                  </div>
                  <div className='text-sm font-medium leading-none'>
                    {user?.name}
                  </div>
                </div>
                {/* <button
                  type='button'
                  className='ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                >
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button> */}
              </div>
              <div className='mt-3 space-y-1 px-2'>
                {/* {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    className='block rounded-md px-3 py-2 text-base font-medium hover:bg-gray-700 hover:text-white'
                    onClick={() => {
                      TokenService.removeTokens();
                      setUser(null);
                      navigate('/login');
                    }}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))} */}
                <Disclosure.Button
                  key={'profile'}
                  className='block rounded-md px-3 py-2 text-base font-medium hover:bg-gray-700 hover:text-white'
                  onClick={() => {
                    navigate(`/profile/${user?.uuid}`);
                  }}
                >
                  {'Profile'}
                </Disclosure.Button>
                <Disclosure.Button
                  key={'signout'}
                  className='block rounded-md px-3 py-2 text-base font-medium hover:bg-gray-700 hover:text-white'
                  onClick={() => {
                    TokenService.removeTokens();
                    setUser(null);
                    navigate('/login');
                  }}
                >
                  {'Sign out'}
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
