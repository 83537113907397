import React from 'react';

const TextArea = React.forwardRef(
  (
    {
      className,
      label,
      required,
      type,
      value,
      textCenter,
      inputClassName,
      errorMsg,
      ...rest
    },
    ref,
  ) => {
    return (
      <label className={'block ' + className}>
        <span className='flex flex-row gap-1'>
          {label}
          <span className={`text-error`}>{required && '*'}</span>
        </span>
        <textarea
          type={type}
          className={`px-3 py-2 shadow-sm border-gray-400 focus:outline-none border focus:border-primary focus:ring-primary block w-full rounded-lg focus:ring-1 ${
            textCenter && 'text-center'
          } ${errorMsg && 'ring-1 ring-error'} ${inputClassName}`}
          value={value}
          ref={ref}
          {...rest}
        />
        {errorMsg && (
          <span className='mt-1 text-xs font-semibold text-error'>
            {errorMsg}
          </span>
        )}
      </label>
    );
  },
);

export default TextArea;
