import { useCallback, useEffect, useState } from 'react';
import SimpleLoader from '../../../../../components/Loader/SimpleLoader';
import { getClassroomEvent } from '../../../../../services/classrooms/eventsService';
import AssignmentEditForm from './Edit/AssignmentEditForm';
import MaterialEditForm from './Edit/MaterialEditForm';
import MeetingEditForm from './Edit/MeetingEditForm';
import AssignmentView from './View/AssignmentView';
import MaterialView from './View/MaterialView';
import MeetingView from './View/MeetingView';

export default function FullEventCard({ uuid, isTeacher }) {
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState();

  const fetchEvent = useCallback(() => {
    setIsLoading(true);
    getClassroomEvent(uuid)
      ?.then((res) => {
        setEvent(res);
      })
      ?.finally(() => {
        setIsLoading(false);
      });
  }, [uuid]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  return (
    <div className={`overflow-hidden w-full min-h-20 p-4 border border-t-0`}>
      {isLoading ? (
        <div className='relative'>
          <SimpleLoader className='h-5' />
        </div>
      ) : isTeacher ? (
        <>
          {event?.event?.type === 'meeting' ? (
            <MeetingEditForm
              event={event}
              updateEvent={setEvent}
              setIsLoading={setIsLoading}
            />
          ) : event?.event?.type === 'material' ? (
            <MaterialEditForm
              event={event}
              updateEvent={setEvent}
              setIsLoading={setIsLoading}
            />
          ) : event?.event?.type === 'assignment' ? (
            <AssignmentEditForm
              event={event}
              updateEvent={setEvent}
              setIsLoading={setIsLoading}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {' '}
          {event?.event?.type === 'meeting' ? (
            <MeetingView event={event} />
          ) : event?.event?.type === 'material' ? (
            <MaterialView event={event} />
          ) : event?.event?.type === 'assignment' ? (
            <AssignmentView event={event} refreshEvent={fetchEvent} />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}
