import React, { useCallback, useRef, useState } from 'react';
import { postRateAnswer } from '../../../../../services/forms/formContentService';
import Checkboxes from './Options/Checkboxes';
import FileAnswer from './Options/FileAnswer';
import MultipleChoice from './Options/MultipleChoice';
import TextAnswer from './Options/TextAnswer';

export default function ContentCard({ content, user, setPoints }) {
  const pointTimerRef = useRef();
  const [point, setPoint] = useState(content?.answer?.point);

  const corrects = content?.questionselectcontent
    ?.filter((f) => f.is_correct)
    ?.map((f) => f.uuid);

  const is_all_correct =
    corrects?.every((f) => content?.answer?.choices?.includes(f)) &&
    content?.answer?.choices?.length === corrects?.length;

  const debounceSaveData = useCallback(
    (value) => {
      if (pointTimerRef.current) {
        clearTimeout(pointTimerRef.current);
      }
      pointTimerRef.current = setTimeout(() => {
        // if (field === 'name' && value === '') {
        //   pointTimerRef.current.innerHTML = title || form.name;
        //   return;
        // }

        postRateAnswer(content?.uuid, {
          user: user?.user?.uuid,
          point: value,
        })
          ?.then(() => {
            setPoints((prev) => prev - point + +value);
            setPoint(value);
          })
          ?.catch((e, v) => {});
      }, 1000);
    },
    [content?.uuid, point, setPoints, user?.user?.uuid],
  );

  return (
    <div className='relative flex flex-col bg-white rounded-lg p-6 gap-2'>
      <div className='flex flex-row justify-between'>
        <div
          className='pb-2'
          dangerouslySetInnerHTML={{
            __html: content.name, // fix description
          }}
        />
        <div className='flex flex-row gap-2 items-center'>
          <input
            type='number'
            min='0'
            className='border-b focus:outline-none w-12 text-center'
            defaultValue={content?.answer?.point}
            onChange={(e) => debounceSaveData(e.target.value)}
          />{' '}
          / {content?.points}
        </div>
      </div>

      {content.attachment_img && (
        <div className='relative w-fit my-2 group/image'>
          <img
            alt='attachment'
            className='max-h-64 w-fit h-fit :'
            src={content.attachment_img}
          />
        </div>
      )}
      {content.test_type === 'SELECTONE' && (
        <MultipleChoice content={content} is_all_correct={is_all_correct} />
      )}
      {content.test_type === 'SELECT' && (
        <Checkboxes content={content} is_all_correct={is_all_correct} />
      )}
      {content.test_type === 'TEXT' && <TextAnswer content={content} />}
      {content.test_type === 'FILE' && <FileAnswer content={content} />}
    </div>
  );
}
