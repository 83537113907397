import React, { useEffect, useState } from 'react';
import { CalendarDaysIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { getPosts } from '../../../../../services/Feeds/FeedService';
import { formatDate } from '../../../../../pages/Feed/FormatDate';

export default function NewPosts() {
  const [updates, setUpdates] = useState([]);
  const [pinnedPost, setPinnedPost] = useState(null);

  useEffect(() => {
    getPosts()
      .then((res) => {
        const pinned = res.results.filter(
          (update) => update.is_pinned === true,
        );
        setPinnedPost(pinned);
        if (pinned?.uuid) {
          setUpdates(
            res.results.filter((update) => !update.is_pinned).slice(0, 3),
          );
        } else {
          setUpdates(
            res.results.filter((update) => !update.is_pinned).slice(0, 3),
          );
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  }, []);

  const navigate = useNavigate();

  // return (
  //   <div className='border border-gray-100 rounded-md shadow-lg p-4 mt-2'>

  //   </div>
  // );

  // eslint-disable-next-line no-unreachable
  return (
    <div className='border border-gray-100 rounded-md shadow-lg p-4 mt-2 overflow-y-visible'>
      <span className='mt-2 font-bold'>New Posts</span>
      <div className='ml-1'>
        {pinnedPost?.length > 0 &&
          pinnedPost?.map((pinned, i) => (
            <div key={i} className='mb-2 relative'>
              <svg
                className='absolute right-0 top-0 text-red-400 h-5 rotate-90'
                role='img'
                fill='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M13.352 14.585l-4.509 4.614.72-4.062L3.428 7.57 0 7.753 7.58 0v2.953l7.214 6.646 4.513-1.105-4.689 4.982L24 24l-10.648-9.415z' />
              </svg>
              <div
                onClick={() => {
                  navigate(`/feed/${pinned?.uuid}`);
                }}
                className=' cursor-pointer text-blue-600 text-base hover:text-blue-500 w-[90%]'
              >
                {pinned?.title}
              </div>
              <div className='flex flex-row justify-start mt-2 items-center'>
                <img
                  className='w-8 h-7 rounded-2xl'
                  src={
                    pinned?.author_info.profile_picture ||
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                  }
                  alt=''
                />
                <span className='text-base text-gray-600 ml-2'>
                  {pinned?.author_info.first_name}
                </span>
              </div>
              <div className='flex flex-row justify-between items-center mt-3 mb-1'>
                <div className='flex flex-row justify-start items-center'>
                  {' '}
                  <HandThumbUpIcon className='w-5 h-4 text-gray-500' />{' '}
                  <span className='text-sm text-gray-500'>
                    {pinned?.count_likes}
                  </span>
                </div>
                <div className='pr-4 text-xs italic text-gray-500 flex flex-row justify-between items-center'>
                  {' '}
                  <CalendarDaysIcon className='w-5 h-4' />{' '}
                  {formatDate(pinned?.created)}
                </div>
              </div>
              <hr></hr>
            </div>
          ))}
        {updates?.length > 0 ? (
          updates?.map((upd, i) => (
            <div key={i} className='mb-2'>
              <div
                onClick={() => {
                  navigate(`/feed/${upd.uuid}`);
                }}
                className=' cursor-pointer text-blue-600 text-base  hover:text-blue-500'
              >
                {upd?.title}
              </div>
              <div className='flex flex-row justify-start mt-2 items-center'>
                {' '}
                <img
                  className='w-8 h-7 rounded-2xl'
                  src={
                    upd?.author_info.profile_picture ||
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                  }
                  alt=''
                />
                <span className='text-base text-gray-600 ml-2'>
                  {upd?.author_info.first_name} {upd?.author_info.last_name}{' '}
                </span>
              </div>
              <div className='flex flex-row justify-between items-center mt-3 mb-1'>
                <div className='flex flex-row justify-start items-center'>
                  {' '}
                  <HandThumbUpIcon className='w-5 h-4 text-gray-500' />{' '}
                  <span className='text-sm text-gray-500'>
                    {upd?.count_likes}
                  </span>
                </div>
                <div className='pr-4 text-xs italic text-gray-500 flex flex-row justify-between items-center'>
                  {' '}
                  <CalendarDaysIcon className='w-5 h-4' />{' '}
                  {formatDate(upd?.created)}
                </div>
              </div>
              <hr></hr>
            </div>
          ))
        ) : (
          <span className='italic text-sm text-gray-600'>
            No latest updates
          </span>
        )}
        <div className='flex justify-center items-center cursor-pointer text-blue-600 text-sm hover:text-blue-500'>
          <a href='/feed'>see more</a>
        </div>
      </div>
    </div>
  );
}
