import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSkills } from '../../services/projects/skillsService';
import { getStudentsRatingStats } from '../../services/stats/statsService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { Link } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import { ArchiveBoxIcon, CheckIcon } from '@heroicons/react/24/outline';

const PAGE_SIZE = 50;

export default function Rating() {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [loadingSkills, setLoadingSkills] = useState(false);

  const [n, setN] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoadingSkills(true);
    setLoadingData(true);
    getSkills({ uuid: null, level: 1 })
      .then((res) => {
        // console.log('skills', res?.results);
        setSkills(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoadingSkills(false));

    getStudentsRatingStats({})
      .then((res) => {
        setData(res?.results);
        setN(res?.count);
        setPage(1);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  }, []);

  const addData = () => {
    setLoadingMore(true);
    getStudentsRatingStats({
      skill: selectedSkill,
      page_size: PAGE_SIZE,
      page: page + 1,
    })
      .then((res) => {
        setData((prev) => prev.concat(res?.results));
        setPage(page + 1);
      })
      .catch(() => {})
      .finally(() => setLoadingMore(false));
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='font-semibold'>Filter by subject</div>
      <div className='flex flex-row gap-2 flex-wrap'>
        <button
          key={'all'}
          className={`${
            selectedSkill === null
              ? 'font-bold border-2 border-blue-600 text-blue-600 bg-blue-300'
              : ''
          } bg-gray-100 border-2 rounded-full px-4 py-2 text-xs flex`}
          onClick={async () => {
            setLoadingData(true);
            setSelectedSkill(null);

            await getStudentsRatingStats({
              skill: null,
              page: 1,
              page_size: PAGE_SIZE,
            })
              .then((res) => {
                setData(res.results);
                setN(res?.count);
                setPage(1);
              })
              .finally(() => {
                setLoadingData(false);
              });
          }}
        >
          <ArchiveBoxIcon className='inline-block h-4 mr-2' />
          <span className=''>All Subjects</span>
        </button>
        {skills.map((p) => (
          <button
            key={p?.uuid}
            className={`${
              selectedSkill === p?.uuid
                ? 'font-bold border-2 border-blue-600 text-blue-600 bg-blue-300'
                : ''
            } bg-gray-100 border-2 rounded-full px-4 py-2 text-xs flex`}
            onClick={async () => {
              setLoadingData(true);
              setSelectedSkill(p?.uuid);

              await getStudentsRatingStats({
                skill: p?.uuid,
                page: 1,
                page_size: PAGE_SIZE,
              })
                .then((res) => {
                  setData(res.results);
                  setN(res?.count);
                  setPage(1);
                })
                .finally(() => {
                  setLoadingData(false);
                });
            }}
          >
            {/* <ArchiveBoxIcon className='inline-block h-4 mr-2' /> */}
            {selectedSkill === p?.uuid ? (
              <CheckIcon className={`inline-block h-4 mr-1`} />
            ) : null}
            <span className=''>{p?.name}</span>
          </button>
        ))}
      </div>
      {loadingData && page === 1 ? (
        <div className='flex flex-col p-8 items-center'>
          <SimpleLoader className={'h-12 w-12'} />
        </div>
      ) : (
        <div className='border-2 rounded-xl p-2'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='border-b-2 sticky top-0 bg-white'>
                <th className='pb-2 px-2'>#</th>
                <th className='text-left'>{t('stats.student')}</th>
                <th className='text-left'>{t('Subject')}</th>
                <th>Point</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, index) => (
                <tr key={index} className='border-b-2 last:border-b-0'>
                  <td className='py-1 px-2 text-center'>{index + 1}</td>
                  <td>
                    <Link
                      to={`/profile/${d?.uuid}`}
                      className='hover:underline cursor-pointer'
                    >
                      {`${d?.first_name} ${d?.last_name}`}
                    </Link>
                  </td>
                  <td>{d?.skill}</td>
                  <td className='text-center'>{d?.point}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {page * PAGE_SIZE < n && (
            <div className='flex flex-row justify-center py-4'>
              <Button
                loading={loadingMore}
                text={t('stats.see_more')}
                onClick={() => addData()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
