import React, { useCallback, useRef, useState } from 'react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import {
  deleteFormContent,
  patchFormContent,
} from '../../../../../../services/forms/formContentService';
import SimpleLoader from '../../../../../../components/Loader/SimpleLoader';

export default function ContentFooter({
  content,
  selected,
  setContents,
  setPoints,
}) {
  const pointRefTimer = useRef();
  const [point, setPoint] = useState(content.points);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    toast.dark(
      <>
        <div>You sure want to delete content "{content.name}"?</div>
        {/*to translate*/}
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text={'Yes'} // to translate
            color='error-solid'
            autoFocus
            onClick={() => {
              setIsDeleting(true);
              deleteFormContent(content.uuid)
                ?.then(() => {
                  setPoints((prev) => prev - point);
                  setContents((prev) =>
                    prev.filter((e) => e.uuid !== content.uuid),
                  );
                })
                ?.catch((err) => {
                  console.log(err);
                  toast.error(
                    `Error when deleting "${content.name}"`, // to translate
                  );
                })
                ?.finally(() => setIsDeleting(false));
            }}
          />
          <Button
            text={'No'} // to translate
            className='text-white'
            onClick={toast.dismiss}
          />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  const saveContent = useCallback(
    (value, field) => {
      patchFormContent(content.uuid, { [field]: value });
    },
    [content.uuid],
  );

  const debounceSaveData = useCallback(
    (value, field) => {
      // Отправить запрос через 2 секунду после последнего ввода
      if (pointRefTimer.current) {
        clearTimeout(pointRefTimer.current);
      }

      pointRefTimer.current = setTimeout(() => {
        saveContent(value, field);
        setPoints((prev) => prev - point + +value);
        setPoint(value);
        console.log(value);
      }, 1000);
    },
    [point, saveContent, setPoints],
  );

  return (
    <>
      <div
        className={`${
          selected ? 'flex' : 'hidden'
        } flex-row justify-end gap-1 items-center`}
      >
        {/* <DocumentDuplicateIcon
                  className='p-2.5 h-11 cursor-pointer hover:bg-slate-100 rounded-full'
                  title={'Dublicate'} // to translate
                  onClick={() => {}}
                /> */}
        {isDeleting ? (
          <div className='relative '>
            <SimpleLoader className='p-2.5 h-10' />
          </div>
        ) : (
          <TrashIcon
            className='p-2.5 h-10 cursor-pointer hover:bg-slate-100 rounded-full'
            title={'Delete'} // to translate
            onClick={handleDelete}
          />
        )}
        <div className='border-l-2 h-8' />
        <EllipsisVerticalIcon className='p-2.5 h-10 cursor-pointer hover:bg-slate-100 rounded-full' />
        {content?.test_type !== 'CONTENT' && (
          <>
            <input
              className='w-10 border-b text-center focus:outline-none'
              type='number'
              min='0'
              defaultValue={content.points}
              onChange={(e) => {
                debounceSaveData(e?.target?.value, 'points');
              }}
            />
            points {/*to translate*/}
          </>
        )}
      </div>
    </>
  );
}
