import React, { useState, useEffect, useContext } from 'react';
import { HandThumbUpIcon as HandThumbUpIconOutline } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../FormatDate';
import { HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/24/solid';
import { likePost } from '../../../services/Feeds/FeedService';
import { UserContext } from '../../../utils/contexts/UserContext';
import RichTextEditor from '../../../components/forms/Inputs/RichTextEditor';

function PostCard({ post }) {
  const [isLiked, setIsLiked] = useState(false);

  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  useEffect(() => {
    const liked = localStorage.getItem(`liked_${user.uuid}_${post.uuid}`);
    setIsLiked(liked === 'true');
  }, [post.length, post.uuid, user.uuid]);

  const truncateHTML = (html, maxLength) => {
    const truncatedText = html.replace(/<\/?[a-z][^>]*>/gi, '');
    const words = truncatedText.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return truncatedText;
  };

  const handleLike = () => {
    likePost(post.uuid)
      .then((res) => {
        setIsLiked(true);
        localStorage.setItem(`liked_${user.uuid}_${post.uuid}`, 'true');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleUnlike = () => {
    likePost(post.uuid)
      .then(() => {
        setIsLiked(false);
        localStorage.setItem(`liked_${user.uuid}_${post.uuid}`, 'false');
      })
      .catch((error) => {
        console.error('Error unliking post:', error);
      });
  };

  return (
    <div className='flex flex-col w-full h-auto mt-4 pr-5 pl-2 border border-gray-200 cursor-pointer rounded-md shadow-lg'>
      <div className='flex flex-row justify-between mt-4 items-center ml-4'>
        <div className='flex flex-row items-center'>
          <img
            src={
              post.author_info.profile_picture ||
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
            }
            className={
              post.author_info.profile_picture
                ? 'w-10 h-10 rounded-md'
                : 'w-10 h-10 rounded-md'
            }
            alt='profileImage'
          />
          <p className='ml-2'>
            <span className='font-semibold'>
              {post.author_info.first_name} {post.author_info.last_name}
            </span>
            <span className='px-1'>|</span>
            <span className='italic text-xs text-gray-500'>
              {formatDate(post.created)}
            </span>
          </p>
        </div>

        {post.is_pinned ? (
          <div className='flex flex-row '>
            <svg
              className='text-red-400 h-5 mr-2 mt-1 rotate-90'
              role='img'
              fill='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M13.352 14.585l-4.509 4.614.72-4.062L3.428 7.57 0 7.753 7.58 0v2.953l7.214 6.646 4.513-1.105-4.689 4.982L24 24l-10.648-9.415z' />
            </svg>
            <span className='font-semibold'>Pinned Post</span>
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className='flex ml-4 justify-start items-center mt-6'
        onClick={() => navigate(`${'/feed/' + post?.uuid}`)}
      >
        {post.title && (
          <h1 className=' font-semibold text-blue-500 hover:text-blue-400'>
            {post.title}
          </h1>
        )}
      </div>
      <div className='flex justify-start items-center mt-3'>
        <RichTextEditor
          readOnly={true}
          value={truncateHTML(post.content, 30)}
        />
      </div>
      <div className='flex flex-row justify-end mt-3 mb-2 md:gap-4 lg:gap-4 gap-2'>
        {isLiked ? (
          <HandThumbUpIconSolid
            onClick={handleUnlike}
            className='w-6 text-blue-500'
          />
        ) : (
          <HandThumbUpIconOutline
            onClick={handleLike}
            className='w-6 text-gray-500'
          />
        )}
      </div>
    </div>
  );
}

export default PostCard;
