import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getSubmissions = ({
  page,
  page_size,
  classroom,
  assignment,
  user,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/submissions/`, {
          params: { page, page_size, classroom, assignment, user },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getSubmission = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/submissions/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postSubmission = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/class/submissions/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.error(
            'Error response:',
            err.response ? err.response.data : err.message,
          );
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchClassroomSection = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/class/submissions/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteSubmission = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/class/submissions/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getStudentSubmissionsReview = ({ page, page_size, classroom }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/students_review/`, {
          params: { page, page_size, classroom },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
export const postSubmissionReview = (submision, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/class/submissions/${submision}/review/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.error(
            'Error response:',
            err.response ? err.response.data : err.message,
          );
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchClassroomSectionReview = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/class/students_review/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteSubmissionReview = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/class/students_review/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
