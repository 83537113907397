import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getClassrooms = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/classroom/`, {
          params: { page, page_size },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getClassroom = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/classroom/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postClassroom = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/class/classroom/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.error('Error response:', err.response ? err.response.data : err.message);
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchClassroom = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/class/classroom/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteClassroom = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/class/classroom/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getClassroomUsers = (classroomId) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/classroom_users/`, {
          params: { classroom: classroomId },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          console.error('Error response:', err.response ? err.response.data : err.message);
          reject('axios error', err);
        });
    } catch (error) {
      console.error('System error:', error);
      reject('system error', error);
    }
  });
};

export const getClassroomTeachers = (classroomId) => {
  return new Promise((resolve, reject) => {
    getClassroomUsers(classroomId)
      .then((data) => {
        const teachers = data.filter(user => user.role_type === 'TEACHER');
        resolve({ results: teachers });
      })
      .catch((err) => reject(err));
  });
};

export const getClassroomStudents = (classroomId) => {
  return new Promise((resolve, reject) => {
    getClassroomUsers(classroomId)
      .then((data) => {
        const students = data.filter(user => user.role_type === 'STUDENT');
        resolve({ results: students });
      })
      .catch((err) => reject(err));
  });
};

export const getClassroomOwner = (classroomId) => {
  return new Promise((resolve, reject) => {
    getClassroomUsers(classroomId)
      .then((data) => {
        const owners = data.filter(user => user.role_type === 'OWNER');
        resolve({ results: owners });
      })
      .catch((err) => reject(err));
  });
};

export const postClassroomUser = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/class/classroom_users/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error('Error response:', err.response ? err.response.data : err.message);
          reject('axios error', err);
        });
    } catch (error) {
      console.error('System error:', error);
      reject('system error', error);
    }
  });
};

export const deleteClassroomUser = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/class/classroom_users/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error('Error response:', err.response ? err.response.data : err.message);
          reject('axios error', err);
        });
    } catch (error) {
      console.error('System error:', error);
      reject('system error', error);
    }
  });
};

