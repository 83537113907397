import React from 'react';

const BentoBoxIcon = ({ className }) => {
  return (
    <svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      strokeWidth='1.5'
      stroke='currentColor'
      className={className ? className : 'h-5'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='5' cy='5' r='3' fill='currentColor' />
      <circle cx='21' cy='5' r='3' fill='currentColor' />
      <circle cx='37' cy='5' r='3' fill='currentColor' />
      <circle cx='5' cy='21' r='3' fill='currentColor' />
      <circle cx='21' cy='21' r='3' fill='currentColor' />
      <circle cx='37' cy='21' r='3' fill='currentColor' />
      <circle cx='5' cy='37' r='3' fill='currentColor' />
      <circle cx='21' cy='37' r='3' fill='currentColor' />
      <circle cx='37' cy='37' r='3' fill='currentColor' />
    </svg>
  );
};

export default BentoBoxIcon;
