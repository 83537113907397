import React from 'react';
import { postSubmissionReview } from '../../../../../../services/classrooms/submitionServise';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import { useForm } from 'react-hook-form';
import Input from '../../../../../../components/forms/Inputs/Input';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import { toast } from 'react-toastify';

export default function Review({ submission }) {
  // not used
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <form
      onSubmit={handleSubmit((data) => {
        console.log(submission);
        postSubmissionReview(submission?.uuid, data)?.then(() => {
          toast.success('Grade saved');
        });
      })}
      className='flex flex-col gap-2'
    >
      <TextArea
        inputClassName='focus:outline-none'
        label={'Comment'}
        {...register('comment', {
          value: submission?.my_review?.comment || '',
        })}
      />
      <div className='flex flex-row justify-end items-center gap-2'>
        <Input
          type='number'
          onFocus={(e) => e.target.select()}
          className='w-16'
          errorMsg={errors.point?.message}
          min='0'
          max='100'
          inputClassName='border-0 bg-gray-200 pr-2 text-end'
          {...register('point', {
            value: submission?.my_review?.point || 0,
            min: 0,
            max: 100,
            required: '"points" is required',
          })}
        />{' '}
        / 100
      </div>
      <Button
        type='submit'
        className='self-end w-24'
        color='success'
        text='Submit'
      />
    </form>
  );
}
