import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../components/forms/Inputs/Input';
import { useTranslation } from 'react-i18next';
import Select from '../../../components/forms/Select/Select';
import { Button } from '../../../components/forms/Buttons/Button';
import { SparklesIcon } from '@heroicons/react/24/solid';
import {
  generateProjectWithAI,
  postProject,
} from '../../../services/projects/projectService';
import { PROJECT_METHODS } from '../../../utils/constants/constants';
import { toast } from 'react-toastify';
import { UserContext } from '../../../utils/contexts/UserContext';
import { PreferredPairContext } from '../../../utils/contexts/PreferredPairContext';
import CustomCreatableSelect from '../../../components/forms/Select/CustomCreatableSelect';
import RichTextEditor from '../../../components/forms/Inputs/RichTextEditor';
import { useNavigate } from 'react-router-dom';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { getSkills } from '../../../services/projects/skillsService';

const schema = yup.object({
  title: yup.string().required(),
  // level: yup.string().required(),
  // subject: yup.string().required(),
  // method: yup.number().required(),
  tags: yup
    .array()
    .of(yup.string())
    .required('Tags are required')
    .min(1, 'At least one tag is required')
    .max(5, 'Maximum of 5 tags allowed'),
  content: yup.string().required(),
  // evaluationCriteria: yup.string().required(),
});

export default function ContentPart({
  content,
  setContent,
  setBody,
  levels,
  subjects,
}) {
  const {
    formState: { errors },
    // trigger,
    control,
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [t] = useTranslation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { user } = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const { pair } = useContext(PreferredPairContext);

  const [topics, setTopics] = useState([]);
  const [aiLoading, setAiLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();
  const handleCheckFields = async (data) => {
    // if (Object.keys(errors).length === 0) {
    const body = {
      name: data.title,
      level: data.level?.label,
      skill: data.topic,
      // ai_img_url: bgImageUrl,

      content: data.content,
      tags: data.tags,
      // description: data.description,
      // essential_tools_resources: data.essentialToolsResources,
      // roadmap_with_tasks: data.roadmapWithTasks,
      // evaluation_criteria: data.evaluationCriteria,
    };
    setBody(body);
    setContent(data);
    setIsLoading();
    postProject(body)
      .then(() => {
        toast.success('Success');
      })
      .catch((err) => {
        toast.error('error');
      })
      .finally(() => {
        navigate(-1);
      });
    // setIsImagePart(true);
    // } else {
    //   toast.error('Eroro');
    // }
  };

  useEffect(() => {
    setValue('title', content?.title);
    setValue('level', content?.level?.value);
    setValue('subject', content?.subject?.value);
    setValue('method', content?.method);
    setValue('tags', content?.tags);
    setValue('content', content?.content);

    return () => {};
  }, [content, setValue]);

  useEffect(() => {
    if (pair?.uuid && levels?.length) {
      if (pair?.childs?.length) {
        if (pair?.childs[0] >= pair?.childs[1]) {
          setValue('subject', pair?.childs[0]?.uuid);
          setValue(
            'level',
            levels?.find(
              (s) => s?.label === pair?.childs[0]?.recommended_difficulty,
            )?.value,
          );
        } else {
          setValue('subject', pair?.childs[1]?.uuid);
          setValue(
            'level',
            levels?.find(
              (s) => s?.label === pair?.childs[1]?.recommended_difficulty,
            )?.value,
          );
        }
      } else {
        setValue('subject', pair?.uuid);
        setValue(
          'level',
          levels?.find((s) => s?.label === pair?.recommended_difficulty)?.value,
        );
      }
    }

    return () => {};
  }, [pair, levels, setValue]);

  // useEffect(() => {
  //   if (user?.roles?.some((role) => role?.name === 'STUDENT') && pair) {
  //     setValue('subject')
  //     console.log('pair', pair);
  //   }

  //   return () => {};
  // }, [user, pair]);

  return (
    <form onSubmit={handleSubmit(handleCheckFields)}>
      {isLoading ? (
        <div>
          <SimpleLoader className='relative' />
        </div>
      ) : (
        <>
          <div>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-12'>
                <Input
                  type='text'
                  className='pr-1'
                  label={t('Title')}
                  required
                  errorMsg={errors?.title?.message}
                  {...register('title')}
                />
              </div>
              <div className='col-span-12 md:col-span-4'>
                <Controller
                  control={control}
                  name='level'
                  render={({ field }) => (
                    <Select
                      label={t('Level')}
                      textStyles='text-sm'
                      required
                      errorMsg={errors?.level?.message}
                      isSearchable={false}
                      className='w-full overflow-visible'
                      value={field.value}
                      options={levels}
                      // placeholder={t('announcement.select_positions')}
                      onChange={(e) => {
                        setValue('level', e);
                        // console.log('e', e);
                        // console.log('field', field);
                        // field.onChange(e.label);
                      }}
                      // onChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div className='col-span-12 md:col-span-4'>
                <Controller
                  control={control}
                  name='subject'
                  render={({ field }) => (
                    <Select
                      // {...field}
                      label={t('Subject')}
                      textStyles='text-sm'
                      required
                      errorMsg={errors?.subject?.message}
                      isSearchable={false}
                      className='w-full overflow-visible'
                      value={field.value}
                      options={subjects}
                      // placeholder={t('announcement.select_positions')}
                      onChange={(e) => {
                        field.onChange(e);
                        getSkills({ parent: e?.value }).then((res) => {
                          setTopics(
                            res?.results?.map((s) => ({
                              label: s?.name,
                              value: s?.uuid,
                            })),
                          );
                        });
                      }}
                      // onChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div className='col-span-12 md:col-span-4'>
                <Controller
                  control={control}
                  rules={{ required: 'Topic is required' }}
                  name='topic'
                  render={({ field }) => (
                    <Select
                      // {...field}
                      label={t('Topic')}
                      textStyles='text-sm'
                      required
                      errorMsg={errors?.topic?.message}
                      isSearchable={false}
                      className='w-full overflow-visible'
                      isDisabled={!watch('subject')}
                      value={field.value}
                      options={topics}
                      // placeholder={t('announcement.select_positions')}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      // onChange={field.onChange}
                    />
                  )}
                />
              </div>

              <div className='col-span-12 hidden'>
                <Controller
                  control={control}
                  id='tags'
                  name='tags'
                  render={({ field }) => (
                    <label
                      htmlFor='tags'
                      className='block text-sm font-medium text-gray-700'
                    >
                      <CustomCreatableSelect
                        create={(label) => {
                          // console.log('label', label);
                          // postProfession({ name: label })
                        }}
                        onChange={field.onChange}
                      />
                    </label>
                  )}
                />
              </div>

              <div className='col-span-12'>
                <label
                  htmlFor='description'
                  className='block text-sm font-medium text-gray-700'
                >
                  Description
                  <div className='mt-1'>
                    <Controller
                      control={control}
                      name='content'
                      render={({ field }) => (
                        <>
                          <RichTextEditor
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                          <span>
                            To write the formula please use{' '}
                            <a
                              className='text-primary hover:underline'
                              target='_blank'
                              rel='noreferrer'
                              href='https://www.overleaf.com/learn/latex/Integrals%2C_sums_and_limits'
                            >
                              LaTeX
                            </a>
                          </span>
                        </>
                      )}
                    />
                  </div>
                </label>
              </div>

              {/* <div className='col-span-12'>
              <Input
                type='text'
                className='w-full'
                label={t('Evaluation criteria')}
                required
                errorMsg={errors?.evaluationCriteria?.message}
                {...register('evaluationCriteria')}
              />
            </div> */}
            </div>
          </div>
          <>
            <label>Tags</label>
            <Controller
              control={control}
              name='tags'
              defaultValue={[]}
              render={({ field }) => (
                <>
                  <div className='flex min-h-[8rem] w-4/5 flex-row flex-wrap items-start gap-1 rounded-lg border p-4'>
                    {field?.value?.map((iin) => {
                      return (
                        <div
                          key={iin}
                          className='group flex max-h-fit max-w-fit flex-row items-center gap-1 rounded-full border bg-slate-100 py-1 px-2 hover:animate-shake hover:bg-slate-200'
                        >
                          <span className='text-sm opacity-100 group-hover:text-error'>
                            {iin}
                          </span>
                          <XMarkIcon
                            className='child group-hover hidden h-3 cursor-pointer group-hover:flex group-hover:text-error'
                            onClick={() => {
                              field.onChange(
                                field.value.filter((v) => v !== iin),
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className='mt-2 flex flex-row'>
                    <Input
                      ref={inputRef}
                      placeholder={'Enter tag'}
                      className='w-80 rounded-l-lg border'
                      inputClassName='rounded-r-none focus:outline-none'
                      minLength='12'
                      maxLength='12'
                    />
                    <Button
                      type='button'
                      className='rounded-l-none'
                      Icon={PlusIcon}
                      onClick={() => {
                        let tag = inputRef.current.value;
                        if (tag?.length < 2) {
                          toast.error('Tag lenght must be more than 1');
                          return;
                        }

                        if (tag?.lenght > 5) {
                          toast.error('Tag lenght must be less than 6');
                          return;
                        }
                        if (field.value?.includes(tag)) {
                          toast.error('Tag already in list');
                          return;
                        }
                        field.onChange([...field.value, tag]);
                        inputRef.current.value = '';
                      }}
                    />
                  </div>
                </>
              )}
            />
          </>
          <div className='mt-10 flex flex-row justify-end gap-4'>
            {/* <Button
              color='primary-solid'
              text='Fill with AI'
              reverse
              Icon={SparklesIcon}
              iconColor='text-yellow-400'
              type='button'
              loading={aiLoading}
              onClick={async () => {
                // reset();
                const values = getValues();
                let body = {};
                if (values?.subject && values?.level) {
                  if (values?.title) {
                    body = {
                      user_input: {
                        name: values?.title,
                        level: values?.level?.label,
                        subject: values?.subject?.label,
                      },
                    };
                  } else {
                    body = {
                      user_input: {
                        level: values?.level?.label,
                        subject: values?.subject?.label,
                      },
                    };
                  }

                  setAiLoading(true);

                  await generateProjectWithAI(body)
                    .then((res) => {
                      setValue('title', res?.title);
                      setValue(
                        'level',
                        levels?.find((l) => l.label === res?.level),
                      );
                      setValue(
                        'subject',
                        subjects?.find((s) => s.label === res?.subject),
                      );
                      setValue(
                        'method',
                        PROJECT_METHODS?.find((m) => m.value === res?.method)
                          ?.label,
                      );
                      setValue('tags', res?.tags);
                      setValue('description', res?.description);
                      setValue(
                        'essentialToolsResources',
                        res?.essential_tools_resources,
                      );
                      setValue('roadmapWithTasks', res?.roadmap_with_tasks);
                    })
                    .finally(() => {
                      setAiLoading(false);
                    });
                } else {
                  toast.info('Please choose Subject and Level');
                }
              }}
              // loading={loading}
            /> */}

            <Button
              type='submit'
              color='success'
              text='Create'
              loading={isLoading}
            />
          </div>{' '}
        </>
      )}
    </form>
  );
}
