import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

export default function MultipleChoice({ content, is_all_correct }) {
  const [fields] = useState(content?.questionselectcontent);
  const [selected] = useState(content?.answer?.choices[0]);

  return (
    <div className='flex flex-col'>
      {fields?.map((field) => {
        const is_checked = selected === field?.uuid;
        return (
          <div
            key={field?.uuid}
            className={`flex flex-row justify-between p-2 rounded ${
              field?.is_correct && is_checked && 'bg-green-100'
            } ${!field?.is_correct && is_checked && 'bg-red-100'}`}
          >
            <div className='flex flex-row gap-2'>
              <input
                readOnly
                className='cursor-pointer w-5'
                type='radio'
                id={`${field?.uuid}`}
                checked={is_checked}
                name={`${content?.uuid}`}
              />
              <label className='cursor-pointer' htmlFor={`${field?.uuid}`}>
                {field?.content}
              </label>
            </div>
            {field?.is_correct && is_checked && (
              <CheckIcon className='h-5 text-success' />
            )}
            {!field?.is_correct && is_checked && (
              <XMarkIcon className='h-5 text-error' />
            )}
          </div>
        );
      })}
      {!is_all_correct && (
        <div>
          Correct answer
          {fields
            ?.filter((f) => f.is_correct)
            ?.map((field) => {
              return (
                <div key={field?.uuid} className={`flex flex-row gap-2 p-2`}>
                  <input
                    readOnly
                    className='cursor-pointer w-5'
                    type='radio'
                    id={`${field?.uuid}`}
                    checked={true}
                    name={`${content?.uuid}-correct-answers`}
                  />
                  <label className='cursor-pointer' htmlFor={`${field?.uuid}`}>
                    {field?.content}
                  </label>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
