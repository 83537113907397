import { CalendarIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClassroomDateTimePicker from '../../../../../../components/forms/DatePicker/ClassroomDateTimePicker';
import Input from '../../../../../../components/forms/Inputs/Input';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import Select from '../../../../../../components/forms/Select/Select';
import {
  deleteClassroomEvent,
  postClassroomEvent,
} from '../../../../../../services/classrooms/eventsService';
import { postClassroomMeetingEvent } from '../../../../../../services/classrooms/meetingService';

export default function MeetingForm({
  section,
  onClose,
  addObject,
  classroom,
}) {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    postClassroomEvent({
      section: section,
      start_time: data.start_time,
    })?.then((event) => {
      postClassroomMeetingEvent({
        meeting: {
          ...data.meeting,
          name: `${classroom?.name} - ${data.name}`,
          start_time: data.start_time,
          logout_url: window.location.href,
        },
        event: event.uuid,
        name: data.name,
      })
        ?.then((meeting) => {
          addObject({ ...event, event: meeting });
          onClose();
        })
        ?.catch(() => deleteClassroomEvent(event.uuid))
        ?.finally(() => setIsLoading(false));
    });
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col h-full gap-2'
    >
      <div className='flex flex-col px-4 gap-2'>
        <span className='text-2xl font-bold'>Schedule a meeting</span>
        <Input
          {...register('name', { required: '"Title" is required' })}
          errorMsg={errors?.name?.message}
          inputClassName='focus:outline-none'
        />
        <Controller
          control={control}
          name='start_time'
          defaultValue={new Date()}
          rules={{
            required: '"Start time" is required',
          }}
          render={({ field }) => (
            <div className='flex flex-row gap-1 items-center'>
              <CalendarIcon className='h-5 text-primary' />
              <ClassroomDateTimePicker
                date={field.value}
                setDate={field.onChange}
              />
              {errors?.start_time?.message && (
                <span className='mt-1 text-left text-error'>
                  {errors?.start_time?.message}
                </span>
              )}
            </div>
          )}
        />
      </div>
      <div className='flex flex-col min-h-full bg-purple-50 w-full p-6 gap-4'>
        <TextArea
          className='text-sm font-medium '
          errorMsg={errors?.meeting?.welcome_text?.message}
          inputClassName='border-black border rounded-xl focus:border-current focus:ring-0'
          rows={5}
          label='Welcome message for chat'
          {...register('meeting.welcome_text', {
            required: '"Welcome text" is required',
          })}
        />
        {/*banner_color */}
        {/*record */}
        <TextArea
          className='text-sm font-medium '
          errorMsg={errors?.meeting?.banner_text?.message}
          inputClassName='border-black border rounded-xl focus:border-current focus:ring-0'
          rows={5}
          label='Banner message'
          {...register('meeting.banner_text', {
            required: '"Banner message" is required',
          })}
        />
        <Controller
          control={control}
          name='meeting.guest_policy'
          rules={{
            required: '"Guest policy" is required',
          }}
          render={({ field }) => (
            <div>
              <Select
                label='Guest policy'
                value={field.value}
                onChange={(v) => {
                  field.onChange(v?.value);
                }}
                options={[
                  { label: 'Accept guests', value: 'ALWAYS_ACCEPT' },
                  { label: 'Deny guests', value: 'ALWAYS_DENY' },
                  { label: 'Ask moderator', value: 'ASK_MODERATOR' },
                ]}
                className='text-sm font-medium text-gray-700'
              />
              {errors?.meeting?.guest_policy?.message && (
                <span className='font-semibold text-xs text-left text-error'>
                  {errors?.meeting?.guest_policy?.message}
                </span>
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name='meeting.banner_color'
          render={({ field }) => (
            <div className='flex flex-row gap-1.5 items-center'>
              <span className='mr-4'>Meeting color</span>
              {[
                { color: 'text-blue-500', hex: '#3b82f6' },
                { color: 'text-yellow-500', hex: '#eab308' },
                { color: 'text-violet-500', hex: '#8b5cf6' },
                { color: 'text-red-500', hex: '#ef4444' },
                { color: 'text-green-500', hex: '#22c55e' },
              ].map((e) => {
                return (
                  <span
                    key={e.hex}
                    className={`p-1.5 bg-current ${
                      e.color
                    } border-2 rounded-full cursor-pointer ${
                      field.value !== e.hex
                        ? 'border-transparent'
                        : 'ring-1 ring-current'
                    }`}
                    onClick={() => field.onChange(e.hex)}
                  />
                );
              })}
            </div>
          )}
        />

        <button className='bg-primary text-white p-2 rounded-xl' type='submit'>
          Create meeting
        </button>
      </div>
    </form>
  );
}
