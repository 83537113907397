import React, { useMemo, useRef, Fragment } from 'react';
import RichTextEditor from '../../../../components/forms/Inputs/RichTextEditor';
import { getResultDescriptions } from '../../../../services/tests/resultService';
import Export from './Export';

const CHART_COLORS = [
  'bg-red-500',
  'bg-orange-500',
  'bg-green-500',
  'bg-blue-500', // 75-99.99
  'bg-blue-500', // 100
];

const MIT = ({ result, description, colors, setDescription, toScroll }) => {
  const ref = useRef();
  const domains = useMemo(() => {
    return Object.keys(result.result)
      .filter((e) => e !== 'result')
      .sort((a, b) => (result.result[a] < result.result[b] ? 1 : -1));
  }, [result.result]);

  return (
    <div className='flex flex-col gap-2'>
      {!result ? (
        'No results'
      ) : !description ? (
        'No result description'
      ) : (
        <>
          <div className='flex flex-col gap-4 p-8' ref={ref}>
            <div className='flex flex-row justify-between'>
              <span className='text-blue-500 text-3xl font-[800]'>
                Multiple Intelligences Survey
              </span>
              <div
                className='flex flex-row gap-2 min-w-fit'
                data-html2canvas-ignore
              >
                {description.files.map((file) => {
                  return (
                    <Export
                      key={file.uuid}
                      file={file}
                      text={file.name}
                      result={result.uuid}
                      params={{ user: result.user, file: file.uuid }}
                      fileName={file.name}
                      screen_ref={ref}
                    />
                  );
                })}
              </div>
            </div>
            <div className='flex flex-col items-start gap-2 p-4 mt-8 rounded-2xl shadow-[0_0_1rem_rgba(0,0,0,0.25)]'>
              <span className='text-xl font-semibold'>
                Your Result by Sections:{' '}
                <span>{result.result?.result} Intelligence</span>
              </span>
              <RichTextEditor value={description?.description} readOnly />
              <div className='text-blue-500 text-2xl font-medium text-center py-2 w-full'>
                <span>{'Top 5 Professions recommended for this section'}</span>
              </div>
              <div className='flex flex-row justify-center flex-wrap gap-[2%] gap-y-4 px-8 w-full'>
                {description?.professions_related?.map((prof, i) => {
                  return (
                    <span
                      key={prof.uuid + i}
                      className={`${
                        colors[i % colors.length]
                      } p-2 rounded flex flex-row items-center text-white min-w-fit w-1/4 max-h-14 ${
                        prof.profession?.code &&
                        'cursor-pointer hover:brightness-[0.85]'
                      }`}
                      onClick={() => {
                        if (!prof.profession?.code) return;
                        window.open(
                          `universities?search=${prof.profession?.code}`,
                          '_blank',
                        );
                      }}
                    >
                      <span className='pl-2 text-2xl lg:text-4xl h-8 lg:h-10 font-semibold'>
                        {`${i + 1}`}{' '}
                      </span>
                      <span className='px-4 text-center w-full break-normal lg:text-xl font-bold'>{` ${prof.profession?.name}`}</span>
                    </span>
                  );
                })}
              </div>
              <div className='flex flex-row justify-start w-full text-sm text-error animate-pulse'>
                <span data-html2canvas-ignore>
                  Click to name of section to view result
                </span>
              </div>
              <div className='flex flex-row gap-10 w-full'>
                <div className='flex flex-col gap-2'>
                  {domains.map((item) => {
                    let selected = item === description.result?.result;
                    return (
                      <span
                        key={item}
                        className={`h-10 ${
                          selected ? 'font-black' : 'cursor-pointer'
                        } `}
                        onClick={() => {
                          if (selected) {
                            return;
                          }
                          getResultDescriptions({
                            result: item,
                          })?.then((res_desc) => {
                            if (res_desc.count) {
                              setDescription(res_desc.results[0]);
                              (toScroll ?? ref)?.current?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                              });
                            }
                          });
                        }}
                      >
                        {item}
                      </span>
                    );
                  })}
                </div>
                <div className='flex flex-col gap-2 w-full'>
                  {domains.map((item) => {
                    let percentage = result.result[item] * 100;
                    let selected = item === description.result?.result;
                    return (
                      <div
                        key={item}
                        style={{ width: `${percentage}%` }}
                        className={`min-w-fit h-10 rounded-lg text-white flex flex-row justify-end
                      px-2 text-lg items-center font-bold ${
                        !selected && 'cursor-pointer'
                      } ${CHART_COLORS[Math.floor(percentage / 25)]}`}
                        onClick={() => {
                          if (selected) {
                            return;
                          }
                          getResultDescriptions({
                            result: item,
                          })?.then((res_desc) => {
                            if (res_desc.count) {
                              setDescription(res_desc.results[0]);
                              (toScroll ?? ref)?.current?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                              });
                            }
                          });
                        }}
                      >
                        {percentage?.toFixed(0)}%
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='flex flex-row justify-center w-full -space-x-[1.1rem] py-5'>
                {[...Array(4)].map((_, i) => {
                  return (
                    <Fragment key={i}>
                      {!i && <span className='translate-y-3'>{0}%</span>}
                      <div
                        className={`w-10 md:w-20 lg:w-32 h-3 ${CHART_COLORS[i]}
                      ${i === 0 && 'rounded-l-lg'} ${
                          i === 3 && 'rounded-r-lg'
                        }`}
                      />
                      <span className='translate-y-3 translate-x-2'>
                        {25 * (i + 1)}%
                      </span>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MIT;
