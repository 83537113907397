import React, { useCallback, useRef, useState } from 'react';
import { postAnswerFormQuestion } from '../../../../../services/forms/formContentService';
import useAutosizeTextArea from '../../../../../utils/hooks/useAutosizeTextArea';

export default function TextAnswer({ content }) {
  const [value, setValue] = useState(content?.answer?.answer ?? '');
  const ref = useRef();
  const refTimer = useRef();
  const focusIndicatorRef = useRef();
  useAutosizeTextArea(ref, value);

  const saveForm = useCallback(
    ({ value }) => {
      let body = {};
      body['answer'] = value;
      postAnswerFormQuestion(content.uuid, body)?.then(() => {});
    },
    [content.uuid],
  );

  const debounceSaveData = useCallback(
    (value) => {
      // Отправить запрос через 1 секунду после последнего ввода
      if (refTimer.current) {
        clearTimeout(refTimer.current);
      }

      refTimer.current = setTimeout(() => {
        saveForm({ value });
        console.log(value);
      }, 1000);
    },
    [saveForm],
  );

  const handleFocus = () => {
    if (focusIndicatorRef.current) {
      focusIndicatorRef.current.style.width = '100%';
    }
  };

  const handleBlur = () => {
    if (focusIndicatorRef.current) {
      focusIndicatorRef.current.style.width = '0%';
    }
  };

  return (
    <div className='flex flex-col'>
      <textarea
        className='h-auto border-b focus:outline-none overflow-hidden'
        ref={ref}
        placeholder={'Your answer'} // to translate
        onChange={(e) => {
          setValue(e.target.value);
          debounceSaveData(e.target.value);
        }}
        style={{ resize: 'none' }}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <div className='flex flex-row justify-center text-transparent'>
        <div
          ref={focusIndicatorRef}
          className='w-0 border-b text-violet-700 border-current transition-[width] delay-200'
        />
      </div>
    </div>
  );
}
