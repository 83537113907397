// DateTimePicker.jsx
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import './ClassroomDateTimePicker.css'; // Create a custom CSS file to style the datepicker

function ClassroomDateTimePicker({ date, setDate }) {
  return (
    <div className='flex border border-gray-300 rounded-[0.6rem] max-w-fit '>
      <DatePicker
        selected={date}
        onChange={(date) => {
          setDate(date);
        }}
        showTimeSelect
        timeFormat='HH:mm'
        timeIntervals={5}
        dateFormat='dd MMM HH:mm'
        customInput={<CustomInput />}
      />
    </div>
  );
}

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div
    className='flex flex-row items-center h-9 px-1'
    onClick={onClick}
    ref={ref}
  >
    <span className='flex flex-row justify-center items-center w-20 h-9 border-gray-300 border-r'>
      {value && format(new Date(value), 'dd MMM')}
    </span>
    <span className='flex flex-row justify-center w-20'>
      {value && format(new Date(value), 'HH:mm')}
    </span>
  </div>
));

export default ClassroomDateTimePicker;
