import { useState } from 'react';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import { startVideoConference } from '../../../../../../services/videoConference/videoConferenceService';

export default function MeetingEditForm({ event }) {
  const [loading, setLoading] = useState(false);
  return (
    <div className={`overflow-hidden w-full flex flex-row justify-end`}>
      <Button
        color='success'
        text='Start meeting'
        loading={loading}
        onClick={() => {
          setLoading(true);
          startVideoConference(event?.event?.meeting?.uuid)
            .then((res) => {
              window.open(res.moder_link, '_blank').focus();
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      />
    </div>
  );
}
