export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function getColorForProjectLevel(level) {
  switch (level) {
    case 'District':
      return 'text-blue-600';
    case 'Region':
      return 'text-green-600';
    case 'National':
      return 'text-orange-600';
    case 'International':
      return 'text-red-600';
    default:
      return 'text-black';
  }
}

export const formatDate = (date, separator) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join(separator ?? '.');
};

export const extractVideoID = (url) => {
  var match = url.match(/(?:\/|%3D|v=|vi=)([0-9A-Za-z_-]{11})(?:[%#?&]|$)/);
  return match ? match[1] : null;
};

export const drugReorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
