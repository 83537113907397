import React from 'react';

export default function TextAnswer() {
  return (
    <div className='flex flex-col gap-4 ml-6'>
      <input
        className='w-full border-b focus:outline-none cursor-default'
        placeholder={'Text answer'} // to translate
        readOnly
      />
    </div>
  );
}
