import React from 'react';

const Campus = ({ info }) => {
  return (
    <div className='flex flex-col gap-8 border rounded-lg p-4 lg:px-36 md:px-24'>
      {info?.campusInformation?.map((ci) => (
        <div key={ci?.id} className='flex flex-col gap-2'>
          <div className='text-2xl font-semibold'>{ci?.title}</div>
          <div>{ci?.description?.replace(/(<([^>]+)>)/gi, '')}</div>
          <div className=''>
            <img
              src={`https://unitap.org/nova/files/${ci?.image}`}
              alt='img'
              className='object-cover w-full h-72 rounded-xl object-bottom'
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Campus;
