import bilAPI from '../api';

export const getOrganizationsStats = ({ uuid, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/organizations_by_status/`, {
          params: {
            uuid,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getOverallStatusStats = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/overall_by_status/`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getStudentsStatsBySkills = ({
  organization,
  group,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/students_by_skills/`, {
          params: {
            organization,
            group,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getStudentsStatsByLevels = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/students_by_levels/`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getSchoolsLeaderboard = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/organizations_projects_stats/`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getGradeStats = ({ organization, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/groups_projects_stats/`, {
          params: {
            organization,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getGeneralStats = ({ organization, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/general_stats/`, {
          params: {
            organization_identifier: organization,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getGeneralStudentsLeaderboard = ({
  organization,
  page,
  page_size,
  ordering,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/student_leaderboard/`, {
          params: {
            organization,
            page,
            page_size,
            ordering,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getGeneralAllSchoolsStats = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/organization_leaderboard/`, {
          params: { page_size: 60 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getGeneralSchoolStats = (schoolUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/groups_leaderboard/`, {
          params: { organization_identifier: schoolUuid },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getGenerallGradeStats = (gradeUuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/group_students_leaderboard/`, {
          params: { group_identifier: gradeUuid },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getStudentsRatingStats = ({ skill, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/statistics/students_point/`, {
          params: { skill, page, page_size },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

// export const getStudentsStats = ({ group, page, page_size }) => {
//   return new Promise((resolve, reject) => {
//     try {
//       bilAPI
//         .get(`/statistics/groups_projects_stats/`, {
//           params: {
//             group,
//             page,
//             page_size,
//           },
//         })
//         .then((res) => {
//           resolve(res.data);
//         })
//         .catch((err) => {
//           reject('axios error', err);
//         });
//     } catch (error) {
//       reject('system error', error);
//     }
//   });
// };
