import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import GripIcon from '../../../../../../../../components/Icons/GripIcon';
import { patchFormContentQuestion } from '../../../../../../../../services/forms/formContentQuestionService';
import { drugReorder } from '../../../../../../../../utils/helpers/helpers';

export default function DragableOptions({
  fields,
  setFields,
  selected,
  children,
}) {
  const [draggingId, setDraggingId] = useState();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = drugReorder(
      fields,
      result.source.index,
      result.destination.index,
    );

    const movedItem = fields[result.source.index];
    console.log(movedItem);
    console.log(movedItem.uuid);
    setFields(reorderedItems);
    setDraggingId(null);

    patchFormContentQuestion(movedItem.uuid, {
      order: result.destination.index + 1,
    });
  };

  const onDragStart = (start) => {
    setDraggingId(start.draggableId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId='contents'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='flex flex-col gap-4'
          >
            {children?.map((child, i) => (
              <Draggable key={i} draggableId={i?.toString()} index={i}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    // {...provided.dragHandleProps}
                    style={{
                      userSelect: 'none',
                      ...provided.draggableProps.style,
                    }}
                    className='relative'
                  >
                    <div
                      className={`flex flex-row items-center group/option w-full ${
                        snapshot.isDragging && 'opacity-50'
                      }`}
                    >
                      <div
                        {...provided.dragHandleProps}
                        className={`${
                          (draggingId && draggingId !== i?.toString()) ||
                          !selected
                            ? ''
                            : 'group-hover/option:visible'
                        } ${
                          !snapshot.isDragging && 'invisible'
                        } text-secondary w-6`}
                      >
                        <GripIcon className='h-4' />
                      </div>
                      {child}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ); // to translate
}
