import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bgImage from '../../../assets/images/bg.jpg';
import { Button } from '../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../../../utils/contexts/UserContext';
import Input from '../../../components/forms/Inputs/InputBil';
import { toast } from 'react-toastify';
import { postClassroom } from '../../../services/classrooms/classroomService';

const CreateClassroom = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [classroomTitle, setClassroomTitle] = useState('');
  const [classroomDescription, setClassroomDescription] = useState('');

  const handleCreateClassroom = () => {
    if (!classroomTitle || !classroomDescription) {
      toast.error(t('roadmap.required_fields_empty'));
      return;
    }
    setLoadingCreate(true);
    const body = {
      name: classroomTitle,
      description: classroomDescription,
      created_by: user?.uuid,
    };

    postClassroom(body)
      .then(() => {
        toast.success(t('roadmap.saved_successfully'));
        navigate('/classrooms');
      })
      .catch((err) => {
        toast.error(t('Failed to create classroom'));
        console.error(
          'Error creating classroom:',
          err.response ? err.response.data : err.message,
        );
      })
      .finally(() => setLoadingCreate(false));
  };

  return (
    <div>
      <div className='flex flex-col'>
        <div
          className='h-48 flex flex-row'
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
          }}
        >
          <div className='w-1/3 px-4 py-8'>
            <Button
              text={t('buttons.back')}
              color='secondary-solid'
              Icon={ChevronLeftIcon}
              onClick={() => navigate('/classrooms')}
            />
          </div>
          <div className='text-white w-2/3 flex flex-col h-full justify-end gap-8 px-8 py-8'>
            <div className='text-2xl lg:text-4xl font-medium pl-4'>
              {t('classrooms.classroom')}
            </div>
            <div className='flex flex-row justify-end'>
              <Button
                color={'success'}
                text={t('buttons.save')}
                onClick={handleCreateClassroom}
                loading={loadingCreate}
              />
            </div>
          </div>
        </div>

        <div className='flex lg:flex-row md:flex-row flex-col'>
          <div className='lg:w-1/3 md:w-1/3 px-4'>
            <div
              className='rounded-xl border shadow-lg py-4 px-6 flex flex-col divide-y-2
                w-full bg-white lg:-mt-12 md:-mt-12 -mt-4 font-medium lg:text-lg'
            >
              <div
                className={`py-2 flex flex-row items-center gap-2 cursor-pointer font-bold`}
              >
                <DocumentTextIcon className='h-4' />
                {t('classrooms.overview')}
              </div>
            </div>
          </div>
          <div className='lg:w-2/3 md:w-2/3 py-6 px-4'>
            <div className='flex flex-col gap-4'>
              <Input
                className='font-bold'
                label={t('classrooms.title')}
                onChange={(e) => setClassroomTitle(e.target.value)}
                value={classroomTitle}
                required
              />
              <div className='flex flex-col'>
                <label className='block text-gray-700 text-sm font-bold mb-2'>
                  {t('classrooms.description')}
                </label>
                <textarea
                  className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-y'
                  value={classroomDescription}
                  onChange={(e) => setClassroomDescription(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateClassroom;
