import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Submissions from './Submissions/Submissions';
import Users from './Users';
import Grades from './Grades/Grades';

const Settings = () => {
  // not used
  const [t] = useTranslation();
  const [settingsTab, setSettingsTab] = useState('users');

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-row gap-4'>
          <div
            className={`cursor-pointer ${
              settingsTab === 'users'
                ? 'font-bold border-b-2 border-blue-500'
                : ''
            }`}
            onClick={() => setSettingsTab('users')}
          >
            {t('classrooms.users')}
          </div>
          <div
            className={`cursor-pointer ${
              settingsTab === 'submissions'
                ? 'font-bold border-b-2 border-blue-500'
                : ''
            }`}
            onClick={() => setSettingsTab('submissions')}
          >
            {t('classrooms.submissions')}
          </div>
          <div
            className={`cursor-pointer ${
              settingsTab === 'grades'
                ? 'font-bold border-b-2 border-blue-500'
                : ''
            }`}
            onClick={() => setSettingsTab('grades')}
          >
            {t('classrooms.grades')}
          </div>
        </div>
        {settingsTab === 'users' && <Users />}
        {settingsTab === 'submissions' && <Submissions />}
        {settingsTab === 'grades' && <Grades />}
      </div>
    </>
  );
};

export default Settings;
