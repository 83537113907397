import { DocumentIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ListBulletIcon } from '@heroicons/react/24/solid';
import { Controller, useForm } from 'react-hook-form';
import MultipleUpload from '../../../../../../components/upload/MultipleUpload';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import { postSubmission } from '../../../../../../services/classrooms/submitionServise';
import { useContext } from 'react';
import { UserContext } from '../../../../../../utils/contexts/UserContext';
import { extractVideoID } from '../../../../../../utils/helpers/helpers';
import { toast } from 'react-toastify';

export default function AssignmentView({ event, refreshEvent }) {
  const { user } = useContext(UserContext);
  // const [isLoading, setIsLoading] = useState(false);
  // const [saved, setSaved] = useState(event);
  const [t] = useTranslation();
  const { control, register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (!data) return;
    const body = new FormData();
    body.append('content', data.content);
    body.append('assignment', event.event.uuid);
    body.append('user', user.uuid);
    if (data.attached_files?.length) {
      data.attached_files.forEach((file) => {
        body.append('attached_files', file);
      });
    }
    postSubmission(body)
      ?.then(() => {
        refreshEvent();
        toast.info('Successfully submitted!');
      })
      ?.catch(() => {
        toast.error('Something went wrong!');
      });
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`overflow-hidden w-full`}
    >
      <textarea
        readOnly
        className='focus:outline-none w-full'
        style={{ resize: 'none' }}
        label={'Description'}
        value={event?.event?.content}
      />
      {!!event?.event?.attached_files?.length && (
        <div className='flex flex-row gap-2 flex-wrap my-6'>
          {event?.event?.attached_files?.map((file) => {
            const fileName = file.upload
              .split('/')
              ?.at(-1)
              ?.replace(`${file.uuid}_`, '');
            return (
              <a href={file.upload} target='_blank' rel='noreferrer'>
                <div
                  className='flex flex-col justify-center p-2 rounded-2xl border w-24 h-24 
                      text-primary hover:underline cursor-pointer group hover:bg-gray-200'
                  title={fileName}
                >
                  <DocumentIcon className='h-16 text-slate-400 group-hover:text-inherit' />
                  <span className='truncate text-sm max-w-[5.5rem]'>
                    {fileName}
                  </span>
                </div>
              </a>
            );
          })}
        </div>
      )}
      {event?.event?.video_url && extractVideoID(event?.event?.video_url) && (
        <iframe
          className='w-full aspect-video'
          src={`https://www.youtube.com/embed/${extractVideoID(
            event?.event?.video_url,
          )}`}
          title={'Assignment video'}
          // allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowfullscreen='allowfullscreen'
        />
      )}
      {!!event?.event?.attached_projects?.length && (
        <div className='border-t py-6'>
          Problems
          <div className='flex flex-col gap-1 '>
            {event?.event?.attached_projects?.map((f, i) => (
              <a href={`/projects/${f.uuid}`} target='_blank' rel='noreferrer'>
                <div
                  key={f.uuid}
                  className='flex flex-row items-center group mt-1 gap-1 border p-2 rounded-2xl hover:bg-gray-200'
                >
                  <span className='truncate'>{`${i + 1}. ${f.name}`}</span>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
      {!!event?.event?.attached_forms?.length && (
        <div className='border-t py-6'>
          Quizzes
          <div className='flex flex-row gap-2'>
            {event?.event?.attached_forms?.map((f, i) => (
              <a
                href={`/forms/${f.uuid}/view`}
                target='_blank'
                rel='noreferrer'
              >
                <div
                  key={f.uuid}
                  className='flex flex-col items-center group mt-1 hover:bg-gray-200
                        gap-1 p-4 w-24 h-24 border rounded-2xl'
                  title={f.name}
                >
                  <div className='p-1 rounded bg-violet-500'>
                    <ListBulletIcon className='h-8 text-white' />
                  </div>
                  <span className='truncate text-sm max-w-[5.5rem]'>
                    {f.name}
                  </span>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}

      {event?.event?.my_submissions?.assignment ? (
        <>
          {'Your submission'}
          <div className='flex flex-row gap-2 flex-wrap my-2'>
            {event?.event?.my_submissions?.attached_files?.map((file) => {
              const fileName = file.upload
                .split('/')
                ?.at(-1)
                ?.replace(`${file.uuid}_`, '');
              return (
                <a href={file.upload} target='_blank' rel='noreferrer'>
                  <div
                    className='flex flex-col justify-center p-2 rounded-2xl border w-24 h-24 
                      text-primary hover:underline cursor-pointer group hover:bg-gray-200'
                    title={fileName}
                  >
                    <DocumentIcon className='h-16 text-slate-400 group-hover:text-inherit' />
                    <span className='truncate text-sm max-w-[5.5rem]'>
                      {fileName}
                    </span>
                  </div>
                </a>
              );
            })}
          </div>
          <textarea
            readOnly
            className='focus:outline-none w-full'
            style={{ resize: 'none' }}
            label={'Description'}
            value={event?.event?.my_submissions?.content}
          />
        </>
      ) : (
        <>
          <Controller
            control={control}
            name='attached_files'
            defaultValue={[]}
            render={({ field }) => (
              <div>
                Attach file - limit 5mb
                <div className='bg-white p-4 border-black border rounded-xl'>
                  <MultipleUpload
                    sizeLimit={5 * 1024 * 1024}
                    files={field.value}
                    setFiles={field.onChange}
                  />
                </div>
              </div>
            )}
          />
          <TextArea label='Comment' className='mt-4' {...register('content')} />
          <Button
            className='w-full mt-4'
            text='Send'
            type='submit'
            color='primary'
          />
        </>
      )}
    </form>
  );
}
