import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import Pagination from '../../../components/Pagination/Pagination';
import { getProjects } from '../../../services/projects/projectService';
import { getProjectsStudyPlan } from '../../../services/projects/projectsStudyPlanService';
import {
  ArchiveBoxIcon,
  MagnifyingGlassIcon,
  CheckIcon,
  XMarkIcon,
  ClockIcon,
  PlayIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import Select from '../../../components/forms/Select/Select';
import { FILTER_SELECT_STYLE } from '../../../utils/constants/constants';
import InputWithIcon from '../../../components/forms/Inputs/InputWithIcon';
import { Link, useNavigate } from 'react-router-dom';
import { getSkills } from '../../../services/projects/skillsService';
import { getColorForProjectLevel } from '../../../utils/helpers/helpers';
import { getParameters } from '../../../services/parameters/parametersService';
import { debounce } from 'lodash';
import { PreferredPairContext } from '../../../utils/contexts/PreferredPairContext';
import { UserContext } from '../../../utils/contexts/UserContext';

const PAGE_SIZE = 20;

export default function StudentProjects() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { pair } = useContext(PreferredPairContext);
  const searchTimer = useRef();
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [studentProjects, setStudentProjects] = useState([]);
  const [pairedSkills, setPairedSkills] = useState([]);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [studyPlans, setStudyPlans] = useState([]);

  useEffect(() => {
    getProjectsStudyPlan(6)
      .then((res) => {
        setStudyPlans(res.results);
      })
      .catch((err) => {
        console.log('studyPlans error', err);
      });
  }, []);

  useEffect(() => {
    if (pair?.uuid) {
      setSelectedSubject(pair?.uuid);

      getProjects({
        uuid: null,
        page_size: PAGE_SIZE,
        skill: pair?.uuid,
      }).then((res) => {
        setStudentProjects(res.results);
        setCount(res?.count);
      });
    } else {
      setSelectedSubject(null);
      getProjects({
        uuid: null,
        page_size: PAGE_SIZE,
      }).then((res) => {
        setStudentProjects(res.results);
        setCount(res?.count);
      });
    }

    return () => {};
  }, [pair]);

  useEffect(() => {
    getSkills({ uuid: null, level: 2 }).then((res) => {
      setPairedSkills(res?.results);
    });

    getParameters({ category: 'level' }).then((res) => {
      setLevels(res?.results?.map((l) => ({ label: l?.name, value: l?.uuid })));
    });

    return () => {};
  }, []);

  const onPageChange = async (pg) => {
    await getProjects({
      uuid: null,
      page: pg,
      search: searchTerm ? searchTerm : null,
      skill: selectedSubject,
      status: selectedStatus,
      level: selectedLevel,
      page_size: PAGE_SIZE,
    }).then((res) => {
      setStudentProjects(res.results);
      setCount(res?.count);
    });
    setPage(pg);
  };

  // lodash debounce work like timeout, not debounce
  // const handleSearch = debounce((value) => {
  //   getProjects({
  //     uuid: null,
  //     page: 1,
  //     search: value,
  //     skill: selectedSubject,
  //     status: selectedStatus,
  //     level: selectedLevel,
  //     page_size: PAGE_SIZE,
  //   }).then((res) => {
  //     setStudentProjects(res.results);
  //     setCount(res?.count);
  //   });
  //   // console.log(`Searching for "${value}"`);
  // }, 300); // 1000ms debounce delay

  const debounceSearch = useCallback(
    (value) => {
      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        getProjects({
          uuid: null,
          page: 1,
          search: value,
          skill: selectedSubject,
          status: selectedStatus,
          level: selectedLevel,
          page_size: PAGE_SIZE,
        }).then((res) => {
          setStudentProjects(res.results);
          setCount(res?.count);
        });
      }, 500);
    },
    [selectedLevel, selectedStatus, selectedSubject],
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debounceSearch(value);
  };

  // Optional: You can use this effect to clear results when the search term changes
  // useEffect(() => {
  //   setStudentProjects([]);
  // }, [searchTerm]);

  return (
    <div className='w-screen lg:w-full flex flex-col gap-4'>
      {/* {true && (
        <div className='flex flex-row justify-between'>
          <div className='flex flex-col justify-between border w-80 h-36 rounded-md bg-teal-700 p-4'>
            <div>
              <p className='text-white text-xl'>LeetCode's Interview</p>
              <p className='text-gray-100 text-sm'>
                System Design fo
                 Interviews and Beyond
              </p>
            </div>
            <Button
              text='Start Learning'
              className='h-8 bg-white text-teal-700'
            />
          </div>
          <div className='flex flex-col justify-between border w-80 h-36 rounded-md bg-purple-400 p-4'>
            <div>
              <p className='text-white text-xl'>LeetCode's Interview</p>
              <p className='text-gray-100 text-sm'>
                System Design for Interviews and Beyond
              </p>
            </div>
            <Button
              text='Start Learning'
              className='h-8 bg-white text-purple-700'
            />
          </div>
          <div className='flex flex-col justify-between border w-80 h-36 rounded-md bg-sky-400 p-4'>
            <div>
              <p className='text-white text-xl'>LeetCode's Interview</p>
              <p className='text-gray-100 text-sm'>
                System Design for Interviews and Beyond
              </p>
            </div>
            <Button text='Start Learning' className='h-8 text-sky-700' />
          </div>
        </div>
      )} */}
      {true && (
        <div>
          <div className='flex flex-row justify-between my-4 font-semibold'>
            <div className='font-bold'>Study Plan</div>
            <div>
              <a
                href='/study-plans'
                className='text-blue-600 hover:text-blue-800'
              >
                see all...
              </a>
            </div>
          </div>
          <div className='grid grid-cols-3 gap-3'>
            {/* {Array.from({ length: 6 }).map((_, i) => (
              <div
                key={i}
                className='flex flex-row gap-4 border w-80 h-28 rounded-md shadow p-2'
              >
                <div className='h-24 w-24 bg-blue-950 rounded-md'></div>
                <div className='flex items-center'>
                  <div className='flex flex-col'>
                    <div>New Ienstein</div>
                    <div>40 projects</div>
                  </div>
                </div>
              </div>
            ))} */}

            {studyPlans?.map((sp, i) => (
              <div
                key={i}
                className='flex cursor-pointer flex-row gap-3 border h-28 rounded-md shadow p-2'
                onClick={() => {
                  navigate(`/study-plans/${sp?.uuid}`);
                }}
              >
                <div className='h-24 w-24 bg-slate-50  rounded-md'>
                  {sp.picture ? (
                    <img
                      src={sp.picture}
                      alt=''
                      className='w-full h-full object-cover rounded-md'
                    />
                  ) : (
                    <img
                      src='/static/media/8q.77924428b4fce79edfac.png'
                      alt=''
                      className='w-auto h-auto px-1 mt-4 object-cover rounded-md'
                    />
                  )}
                </div>
                <div className='flex flex-col justify-center items-start'>
                  <div className='ml-2'>
                    <h1 className='font-bold'>{sp.title}</h1>
                  </div>
                  <div className='flex flex-row ml-2'>
                    <div cl>
                      <p className='text-gray-600'>
                        problems: {sp.projects_count}
                      </p>
                    </div>
                    <div className='ml-8'>
                      <p className='text-gray-600'>
                        sections: {sp.sections_count}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className='flex flex-col gap-4 pt-4 px-2 md:px-0'>
        <div className='flex flex-row gap-2 flex-wrap'>
          <button
            key={'all'}
            className={`${
              selectedSubject === null
                ? 'font-bold border-2 border-blue-600 text-blue-600 bg-blue-300'
                : ''
            } bg-gray-100 border-2 rounded-full px-4 py-2 text-xs flex`}
            onClick={async () => {
              setSelectedSubject(null);

              await getProjects({
                uuid: null,
                search: searchTerm ? searchTerm : null,
                skill: null,
                status: selectedStatus,
                level: selectedLevel,
                page: 1,
                page_size: PAGE_SIZE,
              }).then((res) => {
                setStudentProjects(res.results);
                setCount(res?.count);
                setPage(1);
              });
            }}
          >
            <ArchiveBoxIcon className='inline-block h-4 mr-2' />
            <span className=''>All Subjects</span>
          </button>
          {pairedSkills.map((p) => (
            <button
              key={p?.uuid}
              className={`${
                selectedSubject === p?.uuid
                  ? 'font-bold border-2 border-blue-600 text-blue-600 bg-blue-300'
                  : ''
              } bg-gray-100 border-2 rounded-full px-4 py-2 text-xs flex`}
              onClick={async () => {
                setSelectedSubject(p?.uuid);

                await getProjects({
                  uuid: null,
                  search: searchTerm ? searchTerm : null,
                  skill: p?.uuid,
                  status: selectedStatus,
                  level: selectedLevel,
                  page: 1,
                  page_size: PAGE_SIZE,
                }).then((res) => {
                  setStudentProjects(res.results);
                  setCount(res?.count);
                  setPage(1);
                });
              }}
            >
              {/* <ArchiveBoxIcon className='inline-block h-4 mr-2' /> */}
              {selectedSubject === p?.uuid ? (
                <CheckIcon className={`inline-block h-4 mr-1`} />
              ) : null}
              <span className=''>{p?.name}</span>
            </button>
          ))}
        </div>
        <div className='flex felx-row w-full h-8 justify-between'>
          <div className='flex flex-row flex-wrap gap-2'>
            <Select
              customStyles={FILTER_SELECT_STYLE}
              placeholder='Difficulty'
              isSearchable={false}
              options={levels}
              value={selectedLevel}
              isClearable={true}
              onChange={(e) => {
                getProjects({
                  uuid: null,
                  page: page,
                  search: searchTerm ? searchTerm : null,
                  skill: selectedSubject,
                  status: selectedStatus,
                  level: e?.value,
                  page_size: PAGE_SIZE,
                }).then((res) => {
                  setStudentProjects(res.results);
                  setCount(res?.count);
                });

                setSelectedLevel(e?.value);
              }}
            />
            <Select
              customStyles={FILTER_SELECT_STYLE}
              placeholder='Status'
              isSearchable={false}
              isClearable={true}
              value={selectedStatus}
              options={[
                {
                  label: 'Not started',
                  value: 'not-started',
                },
                {
                  label: 'In progress',
                  value: 'in-progress',
                },
                {
                  label: 'Submitted',
                  value: 'submitted',
                },

                // {
                //   label: 'Evaluated',
                //   value: 2,
                // },
              ]}
              onChange={(e) => {
                getProjects({
                  uuid: null,
                  page: page,
                  search: searchTerm ? searchTerm : null,
                  skill: selectedSubject,
                  status: e?.value,
                  level: selectedLevel,
                  page_size: PAGE_SIZE,
                }).then((res) => {
                  setStudentProjects(res.results);
                  setCount(res?.count);
                });

                setSelectedStatus(e?.value);
              }}
            />

            {/* <Select
            customStyles={FILTER_SELECT_STYLE}
            placeholder='Subject'
            isSearchable={false}
            options={subjects}
            value={selectedSubject}
            isClearable={true}
            onChange={(e) => {
              getProjects({
                uuid: null,
                page: page,
                skill: selectedSubject,
                level: e?.value,
                page_size: PAGE_SIZE,
              }).then((res) => {
                setStudentProjects(res.results);
                setCount(res?.count);
              });

              setSelectedLevel(e?.value);
            }}
          /> */}
            <InputWithIcon
              Icon={MagnifyingGlassIcon}
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {(user?.roles?.some((r) => r?.name === 'ADMIN') ||
          user?.roles?.some((r) => r?.name === 'PROJECTADMIN')) && (
          <div className='flex mt-20 md:mt-0'>
            <Link to={'create'}>
              <Button
                className='max-h-8'
                text='Create New Problem'
                color='success-simple'
                Icon={PlusIcon}
              />
            </Link>
          </div>
        )}
        <div className='overflow-x-scroll lg:overflow-hidden'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='border-b border-gray-200'>
                <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
                  Status
                </th>
                <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
                  Code
                </th>
                <th className='px-4 py-2 text-left text-sm text-gray-500 font-normal'>
                  Title
                </th>
                <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
                  Domain
                </th>
                <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
                  Subject
                </th>
                <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
                  Difficulty
                </th>
              </tr>
            </thead>
            <tbody>
              {studentProjects?.map((p, i) => (
                <tr
                  key={p.uuid}
                  className='border-b border-gray-200 even:bg-gray-100 cursor-pointer hover:bg-gray-300'
                  onClick={() => navigate(`${'/projects/' + p?.uuid}`)}
                >
                  <td className='px-4 py-2 text-center'>
                    {p?.status === null && <></>}
                    {p?.status === 'not-started' && (
                      <span className='whitespace-nowrap text-gray-600 text-xs'>
                        Not started
                      </span>
                    )}
                    {p?.status === 'in-progress' && (
                      <div className='text-green-600 text-xs whitespace-nowrap'>
                        <PlayIcon className='h-4 inline-block' />
                        <span className='whitespace-nowrap'>In progress</span>
                      </div>
                    )}
                    {p?.status === 'waiting' && (
                      <div className='text-red-600 text-xs whitespace-nowrap'>
                        <ClockIcon className='h-4 inline-block' />
                        <span className='whitespace-nowrap'>Waiting</span>
                      </div>
                    )}
                    {p?.status === 'checking' && (
                      <div className='text-blue-600 text-xs whitespace-nowrap'>
                        <ClockIcon className='h-4 inline-block' />
                        <span className='whitespace-nowrap'>Checking</span>
                      </div>
                    )}
                    {p?.status === 'declined' && (
                      <div className='text-red-600 text-xs whitespace-nowrap'>
                        <XMarkIcon className='h-4 inline-block' />
                        <span className='whitespace-nowrap'>Declined</span>
                      </div>
                    )}
                    {p?.status === 'accepted' && (
                      <div className='text-green-600 text-xs whitespace-nowrap'>
                        <CheckIcon className='h-4 inline-block' />
                        <span className='whitespace-nowrap'>Accepted</span>
                      </div>
                    )}
                  </td>
                  <td className='px-4 py-2 text-center font-semibold text-sm'>
                    {p?.code}
                  </td>
                  <td className='px-4 py-2 text-left'>{p?.name}</td>
                  <td className='px-4 py-2 text-center text-green-600'>
                    {p?.domain?.name}
                  </td>
                  <td className='px-4 py-2 text-center'>
                    {p?.skill?.name ? p?.skill?.name : '-'}
                  </td>
                  <td
                    className={`px-4 py-2 text-center ${getColorForProjectLevel(
                      p?.level,
                    )}`}
                  >
                    {p?.level}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={page}
          onPageChange={onPageChange}
          totalPages={count / PAGE_SIZE}
          count={count}
        />
      </div>
    </div>
  );
}
