import { DocumentIcon } from '@heroicons/react/24/outline';
import React from 'react';

export default function FileAnswer({ content }) {
  const name = content?.answer?.file?.split('/')?.at(-1);
  const url = content?.answer?.file;

  return (
    <div className='flex flex-col gap-4'>
      {!!url && (
        <div
          className='flex flex-row items-center text-center p-2 rounded
                          border border-slate-300 w-fit'
        >
          <a
            className='text-primary hover:underline flex flex-row items-center gap-2'
            href={content?.answer?.file}
            target='_blank'
            rel='noreferrer'
            title={name}
          >
            <DocumentIcon className='h-5 text-red-400' />
            <p className='max-w-[9rem] overflow-hidden truncate text-start'>
              {name}
            </p>
          </a>
        </div>
      )}
    </div>
  );
}
