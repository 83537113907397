import bilAPI from '../api';

export const getSkills = ({ uuid, level, parent }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/projects/skill/`, {
          params: {
            uuid,
            level,
            parent,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getSkillPoint = ({ uuid, user_id, level }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/projects/skill_point/`, {
          params: {
            uuid,
            user_id,
            level,
            // page:,
            page_size: 40,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postPreferredPair = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/projects/skill_point/${uuid}/set_as_prefered/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
