import {
  ArrowUpTrayIcon,
  DocumentIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';

const MultipleUpload = ({
  className,
  uploadText = 'Upload files',
  files,
  setFiles,
  sizeLimit = 4194304,
  accept,
  filesLimit = 8,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [isOversized, setIsOversized] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleFile = (files) => {
    const validFiles = Array.from(files).filter(
      (file) => file.size < sizeLimit,
    );
    if (validFiles.length > filesLimit) return;

    setFiles(validFiles);
    // if (validFiles.length > 0) {
    //   setIsOversized(false);
    //   setFiles(prevFiles => [...prevFiles, ...validFiles]);
    // } else {
    //   setIsOversized(true);
    // }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      handleFile(e.target.files);
    }
  };

  return (
    <div
      className={`py-8 flex flex-col ${
        dragActive ? 'border-blue-500' : 'border-gray-300'
      } ${
        files?.length ? '' : 'border-dashed'
      } border-[5px] gap-2 justify-center items-center rounded-xl px-8 ${
        isOversized ? 'border-error' : ''
      } bg-opacity-10 cursor-pointer ${
        isOversized && 'border border-error'
      } ${className}`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={() => inputRef.current.click()}
    >
      {files?.length ? (
        <div className='grid grid-cols-4 gap-4' onDrop={handleDrop}>
          {files.map((file, i) => (
            <div className='group relative' key={i}>
              <DocumentIcon className='text-gray-300 max-h-20 min-h-10' />
              <XMarkIcon
                className='h-5 p-0.5 bg-red-100 text-red-500 rounded-full absolute right-0 top-0 hidden group-hover:block'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFiles((prev) => prev.filter((f) => f !== file));
                }}
              />
              <p className='w-16 text-ellipsis overflow-hidden whitespace-nowrap'>
                {file?.name}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <ArrowUpTrayIcon
          className='w-1/5 text-gray-300 max-h-20 min-h-10'
          onDrop={handleDrop}
        />
      )}

      <input
        ref={inputRef}
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleChange}
        multiple
      />
    </div>
  );
};

export default MultipleUpload;
