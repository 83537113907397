import React, { useContext, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { deleteClassroom } from '../../services/classrooms/classroomService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../utils/contexts/UserContext';
import bgImage from '../../assets/images/smallImg.jpg';
import { Button } from '../forms/Buttons/Button';

export default function ClassroomCard({ classroom, refreshData }) {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDeleteConfirmation = () => {
    const handleDelete = () => {
      setLoadingDelete(true);
      deleteClassroom(classroom?.uuid)
        .then(() => {
          toast.success(t('classrooms.deleted_successfully'));
          window.location.reload(); // Refresh the page
        })
        .catch(() => {})
        .finally(() => setLoadingDelete(false));
    };

    toast.dark(
      <>
        <div>Вы точно хотите удалить пост?</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button text='Да' color='error-solid' onClick={handleDelete} />
          <Button text='Нет' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  return (
    <a target='_blank' rel='noreferrer' href={`/classrooms/${classroom.uuid}/`}>
      <div className='relative w-72 h-48 rounded-xl overflow-hidden shadow-md border'>
        <img className='w-full' src={bgImage} alt='Placeholder' />
        <div className='absolute inset-0 bg-black opacity-50'></div>
        <div className='absolute top-0 left-0 p-4 text-white'>
          <div className='font-bold text-xl mb-2'>{classroom?.name}</div>
          <p className='text-base'>{classroom?.description}</p>
        </div>
        {user && classroom?.my_role === 'OWNER' ? (
          <div className='absolute flex flex-row justify-end bottom-0 left-0 w-full px-4 py-1 bg-white'>
            <div className='flex flex-row gap-1 items-center'>
              <span className=''>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteConfirmation();
                  }}
                  className='relative w-8 h-8 rounded-full hover:bg-red-100 focus:outline-none'
                >
                  <TrashIcon
                    className={`w-4 h-4 text-error absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                      loadingDelete && 'animate-pulse'
                    }`}
                  />
                </button>
              </span>

              {/* <span className=''>
                <button
                  onClick={() => {
                    
                    navigate(`/classrooms/edit/${classroom?.uuid}`);
                  }}
                  className='relative w-8 h-8 bg-white rounded-full border-4 border-gray-300 hover:border-gray-400 focus:outline-none'
                >
                  <PencilIcon className='w-4 h-4  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
                </button>
              </span> */}
            </div>
          </div>
        ) : undefined}
      </div>
    </a>
  );
}
