import React, { useCallback, useContext, useEffect, useState } from 'react';
import TestCard from './TestCard';
import Result from '../../Result/Result';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { ScrollContext } from '../../../../utils/contexts/ScrollContext';

const StudentTests = ({ data }) => {
  const { scrollRef } = useContext(ScrollContext);
  const [test, setTest] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const listenToScroll = useCallback(
    (a) => {
      const { scrollTop } = scrollRef?.current;
      let H = 200;
      if (scrollTop > H) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    },
    [scrollRef],
  );

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', listenToScroll);
    return () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollRef?.current?.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll, scrollRef]);

  useEffect(() => {
    if (!test) setTest(data[0]?.uuid);
  }, [data, test]);

  return (
    <div className='flex flex-col p-6'>
      {isVisible && (
        <ArrowUpCircleIcon
          className={`h-10 bottom-10 left-10 absolute z-20 text-primary cursor-pointer animate-[bounce_1s_ease-in-out_2.5] `}
          onClick={() => {
            scrollRef?.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
              block: 'start',
            });
          }}
        />
      )}
      <div className='flex flex-row gap-2'>
        {data?.map((t, i) => {
          return (
            <TestCard
              key={t.uuid}
              test={t}
              i={i}
              isSelected={test === t?.uuid}
              select={() => setTest(t.uuid)}
            />
          );
        })}
      </div>
      <div className='w-full px-8 border mt-12' />

      {!!data?.length && <Result test={test} />}
    </div>
  );
};

export default StudentTests;
