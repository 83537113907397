import React from 'react';
import { useParams } from 'react-router-dom';
import { postSubmitForm } from '../../../../services/forms/formsService';
import useForm from '../useForm';
import Checkboxes from './Options/Checkboxes';
import FileAnswer from './Options/FileAnswer';
import MultipleChoice from './Options/MultipleChoice';
import TextAnswer from './Options/TextAnswer';

const ViewForm = () => {
  const { uuid } = useParams();
  const { form, contents, fetchForm } = useForm(uuid);

  return (
    <div className='mx-auto p-8 lg:p-24 md:p-16 sm:p-12 bg-slate-200 min-h-[93vh] max-h-[93vh] overflow-y-auto border-t flex flex-col items-center relative'>
      <div className='flex flex-col gap-4 relative max-w-2xl lg:min-w-[42rem] min-w-full '>
        {form && form?.remaining_attempts < 1 ? (
          <div
            className={`p-6 flex flex-col rounded-lg gap-2
              bg-white border-t-[0.6rem] border-t-violet-700`}
          >
            <div
              className='text-3xl'
              dangerouslySetInnerHTML={{
                __html: form?.name,
              }}
            />
            Your response has been recorded
          </div>
        ) : (
          <>
            <div
              className={`p-6 flex flex-col rounded-lg gap-2
                  bg-white border-t-[0.6rem] border-t-violet-700`}
            >
              <div
                className='text-3xl'
                dangerouslySetInnerHTML={{
                  __html: form?.name,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: form?.descritption, // fix description
                }}
              />
            </div>
            {contents?.map((content) => {
              return (
                <div
                  className='relative flex flex-col bg-white rounded-lg p-6 gap-2'
                  key={content?.uuid}
                >
                  <div
                    className='pb-2'
                    dangerouslySetInnerHTML={{
                      __html: content.name, // fix description
                    }}
                  />
                  {content.attachment_img && (
                    <div className='relative w-fit my-2 group/image'>
                      <img
                        alt='attachment'
                        className='max-h-64 w-fit h-fit :'
                        src={content.attachment_img}
                      />
                    </div>
                  )}
                  {content.test_type === 'SELECTONE' && (
                    <MultipleChoice content={content} />
                  )}
                  {content.test_type === 'SELECT' && (
                    <Checkboxes content={content} />
                  )}
                  {content.test_type === 'TEXT' && (
                    <TextAnswer content={content} />
                  )}
                  {content.test_type === 'FILE' && (
                    <FileAnswer content={content} />
                  )}
                </div>
              );
            })}
            {!!form && (
              <div className='flex flex-row'>
                <button
                  className='bg-violet-700 text-slate-200 p-2 px-6 rounded text-sm font-semibold'
                  onClick={() => postSubmitForm(uuid)?.then(() => fetchForm())}
                >
                  Submit
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ViewForm;
