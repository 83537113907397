import { DocumentIcon } from '@heroicons/react/24/outline';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../components/forms/Inputs/Input';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import MultipleUpload from '../../../../../../components/upload/MultipleUpload';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import { patchClassroomMaterialEvent } from '../../../../../../services/classrooms/materialService';
import { extractVideoID } from '../../../../../../utils/helpers/helpers';

export default function MaterialEditForm({ event, updateEvent, setIsLoading }) {
  // const [isLoading, setIsLoading] = useState(false);
  // const [saved, setSaved] = useState(event);
  const [t] = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const body = new FormData();
    body.append('name', data.name);
    body.append('content', data.content);
    body.append('video_url', data.video_url);
    body.append('event', event.uuid);
    if (data.attached_files?.length) {
      data.attached_files.forEach((file) => {
        body.append('attached_files', file);
      });
    }

    setIsLoading(true);
    patchClassroomMaterialEvent(event.event.uuid, body)
      .then((res) => {
        updateEvent((prev) => ({ ...prev, event: res }));
      })
      ?.finally(() => setIsLoading(false));
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-2 overflow-hidden w-full`}
    >
      <Input
        inputClassName='bg-white border border-slate-400 focus:outline-none'
        label={'Title'}
        {...register('name', { value: event?.event?.name })}
      />
      {event.content}
      <TextArea
        inputClassName='focus:outline-none'
        label={'Description'}
        {...register('content', { value: event?.event?.content })}
      />
      <div className='flex flex-row gap-2 flex-wrap mt-2'>
        {event?.event?.attached_files?.map((file) => {
          const fileName = file.upload
            .split('/')
            ?.at(-1)
            ?.replace(`${file.uuid}_`, '');
          return (
            <a
              href={file.upload}
              target='_blank'
              rel='noreferrer'
              key={file.uuid}
            >
              <div
                className='flex flex-col justify-center p-2 rounded-2xl border w-24 h-24 text-primary hover:underline cursor-pointer group'
                title={fileName}
              >
                <DocumentIcon className='h-16 text-slate-400 group-hover:text-inherit' />
                <span className='truncate'>{fileName}</span>
              </div>
            </a>
          );
        })}
      </div>

      <Controller
        control={control}
        name='attached_files'
        defaultValue={[]}
        render={({ field }) => (
          <div>
            Attach file - limit 5mb. <b>Files will be rewrited</b>
            <div className='bg-white p-4 border-black border rounded-xl'>
              <MultipleUpload
                sizeLimit={5 * 1024 * 1024}
                files={field.value}
                setFiles={field.onChange}
              />
            </div>
          </div>
        )}
      />
      <div className='flex flex-col gap-1'>
        {'Video URL'}
        <Input
          errorMsg={errors?.weight?.message}
          type='url'
          className='w-full'
          inputClassName='pr-2 text-lg focus:outline-none border-2xl'
          min='0'
          {...register('video_url', { value: event?.event?.video_url })}
        />
        {errors?.start_time?.message && (
          <span className='mt-1 text-left text-error'>
            {errors?.start_time?.message}
          </span>
        )}
      </div>
      {(watch('video_url') ?? event?.event?.video_url) &&
        extractVideoID(watch('video_url') ?? event?.event?.video_url) && (
          <iframe
            className='w-full aspect-video'
            src={`https://www.youtube.com/embed/${extractVideoID(
              watch('video_url') ?? event?.event?.video_url,
            )}`}
            title={'Addtional video'}
            // allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen='allowfullscreen'
          />
        )}
      <Button type='submit' text='Save' color='success' />
    </form>
  );
}
