// src/Sidebar.js
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';

const SidebarModal = ({ uuid, children, HeaderIcon, headerText, onClose }) => {
  const { id, setId } = useContext(SidebarContext);
  const [last, setLast] = useState('');

  useEffect(() => {
    if (id !== last && onClose) {
      setLast(id);
      onClose();
    }
  }, [id, onClose, last]);
  return (
    <div
      className={`fixed bottom-0 right-0 h-[93vh] max-w-[screen] z-10 w-fit bg-white transform transition-transform border ${
        uuid === id
          ? `translate-x-0 duration-300 ${id ? '' : 'delay-200'}`
          : 'translate-x-full duration-300 z-20'
      }`}
    >
      <div className='flex flex-col gap-8 h-full'>
        <div className='flex flex-wrap justify-between p-4 pb-0'>
          <div className='flex flex-row gap-2'>
            {HeaderIcon && <HeaderIcon className='h-6 text-blue-600' />}
            <h6 className='self-end text-xl font-semibold'>{headerText}</h6>
          </div>
          <XMarkIcon
            className='h-6 cursor-pointer text-secondary'
            onClick={() => {
              onClose();
              setId('');
            }}
          />
        </div>
        <div className='h-full overflow-auto'>{children}</div>
      </div>
    </div>
  );
};

export default SidebarModal;
