import { CheckIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import Select from '../../components/forms/Select/Select';
import { getOrganizations } from '../../services/catalogs/organizationService';
import StackedBarChart from './Chart';
import GeneralTable from './GeneralTable';
import Table from './Table';

const Leaderboard = () => {
  const [t] = useTranslation();

  const [byDomain, setByDomain] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [org, setOrg] = useState();

  useEffect(() => {
    getOrganizations()
      .then((res) => {
        // console.log('orgs', res?.results);
        setOrgs(res?.results);
      })
      .catch(() => {});
  }, []);

  return (
    <div className='flex flex-col p-4 gap-4'>
      <div className='font-medium text-xl'>{t('stats.leaderboard')}</div>
      <div className='flex flex-row items-center gap-8'>
        <div>{t('stats.select_by')}</div>
        <div className='flex flex-row gap-4'>
          <Button
            text={t('stats.general')}
            color={byDomain ? '' : 'primary'}
            onClick={() => setByDomain(false)}
            Icon={byDomain ? null : CheckIcon}
            rounded
          />
          <Button
            text={t('studyplans.projects')}
            color={byDomain ? 'primary' : ''}
            onClick={() => setByDomain(true)}
            Icon={byDomain ? CheckIcon : null}
            rounded
          />
        </div>
      </div>
      {orgs?.length > 1 && (
        <Select
          options={[
            { label: t('stats.all'), value: null },
            ...orgs.map((d) => ({ label: d?.name, value: d?.uuid })),
          ]}
          onChange={(e) => setOrg(e?.value)}
          value={org}
          className='w-full lg:w-96'
        />
      )}

      <div className='pt-4'>
        {byDomain ? <Table org={org} /> : <GeneralTable org={org} />}
      </div>
    </div>
  );
};

export default Leaderboard;
