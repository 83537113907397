import React, { Fragment, useRef } from 'react';
import RichTextEditor from '../../../../components/forms/Inputs/RichTextEditor';
import Export from './Export';

const MBTI_LETTERS = {
  I: 'Introverted',
  E: 'Extraverted',
  N: 'Intuitive',
  S: 'Sensing',
  T: 'Thinking',
  F: 'Feeling',
  J: 'Judging',
  P: 'Perceiving',
};

const MBTI = ({ result, description, colors }) => {
  const ref = useRef(null);

  return (
    <div className='flex flex-col gap-4'>
      {!result ? (
        'No results'
      ) : !description ? (
        'No result description'
      ) : (
        <>
          <div className='flex flex-col gap-4 p-8 pr-16' ref={ref}>
            <div className='flex flex-row justify-between'>
              <span className='text-blue-500 text-3xl font-[800]'>
                Myers-Briggs Type Indicator Test
              </span>
              <div
                className='flex flex-row gap-2 min-w-fit'
                data-html2canvas-ignore
              >
                {description.files.map((file) => {
                  return (
                    <Export
                      key={file.uuid}
                      file={file}
                      text={file.name}
                      result={result.uuid}
                      params={{ user: result.user, file: file.uuid }}
                      fileName={file.name}
                      screen_ref={ref}
                    />
                  );
                })}
              </div>
            </div>
            <div className='flex flex-row gap-2 justify-between'>
              <div className='w-1/4'>
                <img
                  data-html2canvas-ignore
                  src={description.image}
                  className='max-h-[16rem] h-64'
                  alt='MBTI personality'
                />
                <p className='flex flex-col font-medium'>
                  <span>
                    Your result:{' '}
                    <span className='font-semibold text-blue-500 text-xl'>
                      {description.result?.result}
                    </span>
                  </span>
                  <span>
                    {description.result?.result?.split('')?.map((l, i) => (
                      <Fragment key={i}>
                        {!!i && ', '} {MBTI_LETTERS[l]}
                      </Fragment>
                    ))}
                  </span>
                </p>
              </div>
              <div className='flex flex-col flex-wrap w-3/4 gap-2 h-80 gap-x-4'>
                {description?.professions_related?.map((prof, i) => {
                  return (
                    <span
                      key={prof.uuid + i}
                      className={`${
                        colors[i % colors.length]
                      } p-2 rounded flex flex-row items-center text-white w-1/2 max-h-14 ${
                        prof.profession?.code &&
                        'cursor-pointer hover:brightness-[0.85]'
                      }`}
                      onClick={() => {
                        if (!prof.profession?.code) return;
                        window.open(
                          `universities?search=${prof.profession?.code}`,
                          '_blank',
                        );
                      }}
                    >
                      <span className='pl-2 text-4xl h-10 font-semibold'>
                        {`${i + 1}`}
                      </span>
                      <span className='text-center w-full break-normal text-xl font-bold'>{` ${prof.profession?.name}`}</span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className='flex flex-col items-start gap-2 mt-8  '>
              {/* <span className='text-3xl font-bold'>SHORT SUMMARY</span> */}
              <RichTextEditor value={description?.description} readOnly />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MBTI;
