import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import bgImage from '../../../../assets/images/bg.jpg';
import { Button } from '../../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import {
  getClassroomSections,
  patchClassroomSection,
  postClassroomSection,
} from '../../../../services/classrooms/sectionsService';
import SectionEvents from '../events/SectionEvents';
import { PlusIcon } from '@heroicons/react/24/solid';
import SectionListRow from './SectionListRow';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { drugReorder } from '../../../../utils/helpers/helpers';
import Settings from './Settings/Settings';

export default function TeacherClassroom({ sections, classroom }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  // const { user } = useContext(UserContext);
  const { uuid } = useParams();
  // const [loadingSave, setLoadingSave] = useState(false);
  const [classroomSections, setClassroomSections] = useState(sections);
  const [section, setSection] = useState('settings');
  const [draggingId, setDraggingId] = useState();

  const onDragStart = (start) => {
    setDraggingId(start.draggableId);
  };
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = drugReorder(
      classroomSections,
      result.source.index,
      result.destination.index,
    );

    const movedItem = classroomSections[result.source.index];
    setClassroomSections(reorderedItems);
    setDraggingId(null);

    patchClassroomSection(movedItem.uuid, {
      order: result.destination.index,
    });
  };
  return (
    <div>
      <div className='flex flex-col'>
        <div
          className='h-48 flex flex-row'
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
          }}
        >
          <div className='w-1/3 px-4 py-8'>
            <Button
              text={t('buttons.back')}
              color='secondary-solid'
              Icon={ChevronLeftIcon}
              onClick={() => navigate('/classrooms')}
            />
          </div>
          <div className='text-white w-2/3 flex flex-col h-full justify-center gap-8 px-8 py-8'>
            <div className='text-2xl lg:text-4xl font-medium pl-4'>
              {classroom.name}
            </div>
          </div>
        </div>
        <div className='flex lg:flex-row md:flex-row flex-col'>
          <div className='min-w-[20rem] px-4'>
            <div
              className='rounded-xl border shadow-lg py-4 flex flex-col divide-y-2
                w-full bg-white md:-mt-12 -mt-4 font-medium lg:text-lg'
            >
              <div
                className={`py-2 flex flex-row items-center gap-2 cursor-pointer px-8 ${
                  section === 'settings' && 'font-bold'
                }`}
                onClick={() => setSection('settings')}
              >
                <Cog6ToothIcon className='h-5' />
                {t('classrooms.settings')}
              </div>
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId='classSections'>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className='flex flex-col gap-4'
                    >
                      {classroomSections?.map((s, i) => {
                        return (
                          <Draggable
                            key={s.uuid}
                            draggableId={s.order?.toString()}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                // {...provided.dragHandleProps}
                                // {...provided.dragHandleProps}
                                style={{
                                  userSelect: 'none',
                                  // backgroundColor: '#fff',
                                  // border: '1px solid lightgrey',
                                  ...provided.draggableProps.style,
                                }}
                                className='relative'
                                key={s?.uuid}
                              >
                                {!!i && <div className='border-b' />}
                                <div className='px-6'>
                                  <SectionListRow
                                    key={s.uuid}
                                    section={s}
                                    selected={section === s.uuid}
                                    onClick={() => setSection(s.uuid)}
                                    dragProps={provided.dragHandleProps}
                                    isDragging={snapshot.isDragging}
                                    draggingId={draggingId}
                                    refeshData={() =>
                                      getClassroomSections({
                                        classroom: uuid,
                                        page_size: 100,
                                      })?.then((res) => {
                                        setClassroomSections(
                                          res.results.sort((a, b) =>
                                            a.order > b.order ? 1 : -1,
                                          ),
                                        );
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {section === 'settings' && (
              <div
                className='rounded-xl border shadow-xl py-4 px-6 flex flex-col
                w-full bg-white mt-4 cursor-pointer'
                onClick={() => {
                  postClassroomSection({
                    classroom: uuid,
                    description: 'New section',
                    name: `Section ${classroomSections?.length + 1}`,
                  })?.then((res) => {
                    setClassroomSections((prev) => [...prev, res]);
                  });
                }}
              >
                <PlusIcon className='h-5' />
              </div>
            )}
          </div>
          <div className='lg:w-3/4 md:w-3/4 py-6 px-4'>
            {section === 'settings' ? (
              <Settings />
            ) : (
              <SectionEvents
                classroom={classroom}
                section={section}
                isTeacher={['OWNER', 'TEACHER'].includes(classroom?.my_role)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
