import React from 'react';
import AdminDashboard from '../Dashboard/AdminDashboard/AdminDashboard';
import StudentProjects from './StudentProjects/StudentProjects';

export default function Projects() {
  if (true) {
    return <StudentProjects/>;
  }
  if (false) {
    return <AdminDashboard />;
  }
}
