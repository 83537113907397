import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

export default function NewStudyPlanCard() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className='relative w-72 h-48 rounded-xl border-4 cursor-pointer
      flex flex-col justify-center text-lg items-center font-bold
      '
      onClick={() => navigate(`/study-plans/create`)}
    >
      <PlusIcon className='h-12 text-amethyst' />
      <div className='bg-gradient-to-r from-amethyst to-error bg-clip-text text-transparent'>
        {t('studyplans.create_new_plan')}
      </div>
    </div>
  );
}
