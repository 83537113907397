import { ArrowSmallUpIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from '../../../components/forms/Buttons/Button';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { patchProjectSubmission } from '../../../services/projects/projectSubmissionService';
import MultipleUpload from '../../../components/upload/MultipleUpload';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { EditorState } from 'draft-js';

const EditSubmission = ({ submission, refreshData, setEditing }) => {
  const [t] = useTranslation();
  // const { uuid } = useParams();
  // const [file1, setFile1] = useState(submission?.file1);
  // const [file2, setFile2] = useState(submission?.file2);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [comment, setComment] = useState(
    submission?.comment === 'undefined'
      ? t('projects.no_comment')
      : submission?.comment,
  );
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  return (
    <div className='w-full flex flex-col gap-8 border-2 p-4 rounded-lg'>
      <div className='text-lg'>{t('projects.edit_submission')}</div>
      <div className='w-full flex flex-row gap-4'>
        <div className='w-full flex flex-col gap-4 shadow-md rounded-xl p-4'>
          <div>{t('projects.report_file')}</div>
          <MultipleUpload files={files} setFiles={setFiles} />
        </div>
        {/* <div className='w-full flex flex-col gap-4 rounded-xl shadow-md p-4'>
          <div>{t('projects.report_file')}</div>
          <Upload file={file2} setFile={setFile2} />
        </div> */}
      </div>
      <div>
        {/* <Editor
          editorState={editorState}
          onEditorStateChange={(state) => setEditorState(state)}
        /> */}
        <TextArea
          // label={t('accounting.comment')}
          rows={8}
          className='border rounded-lg shadow-md'
          placeholder={t('projects.type_comment')}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className='flex flex-row justify-end gap-2'>
        <Button
          color={'secondary'}
          text={t('projects.cancel')}
          onClick={() => {
            setEditing(null);
          }}
        />
        <Button
          color={'success'}
          text={t('projects.submit')}
          Icon={ArrowSmallUpIcon}
          loading={loadingSubmit}
          onClick={() => {
            const body = new FormData();
            if (files?.length) {
              // const fileBinaries = await Promise.all(
              //   files.map((file) => readFileAsBinary(file)),
              // );
              files.forEach((file) => {
                body.append('files', file);
              });
              // body.append('files', [files[0], files[1]]);
            }
            // if (file2 instanceof File) body.append('file2', file2);
            body.append('comment', comment);

            setLoadingSubmit(true);
            patchProjectSubmission(submission?.uuid, body)
              .then((res) => {
                toast.success(t('projects.submitted_successfully'));
                refreshData();
                setEditing(null);
              })
              .catch(() => {})
              .finally(() => setLoadingSubmit(false));
          }}
        />
      </div>
    </div>
  );
};

export default EditSubmission;
