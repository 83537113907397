import { CalendarIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClassroomDateTimePicker from '../../../../../../components/forms/DatePicker/ClassroomDateTimePicker';
import Input from '../../../../../../components/forms/Inputs/Input';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import MultipleUpload from '../../../../../../components/upload/MultipleUpload';
import {
  deleteClassroomEvent,
  postClassroomEvent,
} from '../../../../../../services/classrooms/eventsService';
import { postClassroomMaterialEvent } from '../../../../../../services/classrooms/materialService';
import { extractVideoID } from '../../../../../../utils/helpers/helpers';

export default function MaterialForm({ section, onClose, addObject }) {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    postClassroomEvent({
      section: section,
      start_time: data.start_time,
    })?.then((event) => {
      const body = new FormData();
      if (data.attached_files?.length) {
        data.attached_files.forEach((file) => {
          body.append('attached_files', file);
        });
      }
      body.append('name', data.name);
      body.append('content', data.description);
      body.append('event', event.uuid);
      body.append('video_url', data.video_url);
      postClassroomMaterialEvent(body)
        ?.then((material) => {
          addObject({ ...event, event: material });
          onClose();
        })
        ?.catch(() => deleteClassroomEvent(event.uuid))
        ?.finally(() => setIsLoading(false));
    });
  };

  return (
    <form
      className='flex flex-col h-full gap-2'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col px-4 gap-2'>
        <span className='text-2xl font-bold'>Material</span>
        <Input
          {...register('name', { required: '"Title" is required' })}
          errorMsg={errors?.name?.message}
          inputClassName='focus:outline-none'
        />
        <Controller
          control={control}
          name='start_time'
          defaultValue={new Date()}
          rules={{
            required: '"Start time" is required',
          }}
          render={({ field }) => (
            <div className='flex flex-row gap-1 items-center'>
              <CalendarIcon className='h-5 text-primary' />
              <ClassroomDateTimePicker
                date={field.value}
                setDate={field.onChange}
              />
              {errors?.start_time?.message && (
                <span className='mt-1 text-left text-error'>
                  {errors?.start_time?.message}
                </span>
              )}
            </div>
          )}
        />
      </div>
      <div className='flex flex-col min-h-full bg-purple-50 w-full p-6 gap-4'>
        <div className='flex flex-col'>
          <TextArea
            className='text-sm font-medium '
            errorMsg={errors?.description?.message}
            inputClassName='border-black border rounded-xl focus:border-current focus:ring-0'
            rows={5}
            label='Description'
            {...register('description', {
              required: '"Description" is required',
            })}
          />
        </div>
        <Controller
          control={control}
          name='attached_files'
          defaultValue={[]}
          render={({ field }) => (
            <div>
              Attach file - limit 5mb
              <div className='bg-white p-4 border-black border rounded-xl'>
                <MultipleUpload
                  sizeLimit={5 * 1024 * 1024}
                  files={field.value}
                  setFiles={field.onChange}
                />
              </div>
            </div>
          )}
        />
        <div className='flex flex-col gap-1'>
          {'Video URL'}
          <Input
            errorMsg={errors?.weight?.message}
            type='url'
            className='w-full'
            inputClassName='pr-2 text-lg focus:outline-none border-2xl'
            min='0'
            {...register('video_url')}
          />
          {errors?.start_time?.message && (
            <span className='mt-1 text-left text-error'>
              {errors?.start_time?.message}
            </span>
          )}
        </div>
        {watch('video_url') && extractVideoID(watch('video_url')) && (
          <iframe
            className='w-full aspect-video'
            src={`https://www.youtube.com/embed/${extractVideoID(
              watch('video_url'),
            )}`}
            title='Bohemian Rhapsody | Muppet Music Video | The Muppets'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          />
        )}

        <button
          className='bg-yellow-500 text-white p-2 rounded-xl'
          type='submit'
        >
          Create addtional material
        </button>
      </div>
    </form>
  );
}
