import React from 'react';
import NewPosts from '../StudentSidebar/NewPosts';
import TopStudents from '../TopStudents';

export default function TeacherSidebar({ user }) {
  return (
    <div className='flex flex-col gap-8 py-8 px-4'>
      <div className='flex flex-row gap-4'>
        <div className='flex flex-row justify-center'>
          {user?.profile_picture ? (
            <div className='relative inline-block w-20 h-20'>
              <img
                src={user?.profile_picture}
                alt='User Avatar'
                className='w-20 h-20 rounded-full object-cover'
              />
            </div>
          ) : (
            <div className='w-20 h-20 bg-blue-500 text-white rounded-full flex items-center justify-center text-3xl font-semibold'>
              {user?.username[0]}
            </div>
          )}
        </div>
        <div className='flex flex-col justify-center'>
          <h3 className='font-semibold'>
            {user?.first_name + ' ' + user?.last_name}
          </h3>
          <p className='text-sm break-all'>{user?.username}</p>
          <p className='text-xs font-semibold break-all text-blue-600'>
            {user?.roles[0]?.name}
          </p>
        </div>
      </div>
      <div>
        <TopStudents />
        <NewPosts />
      </div>
    </div>
  );
}
