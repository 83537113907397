import React from 'react';

const GripIcon = ({ className }) => {
  return (
    <svg
      width='26'
      height='42'
      viewBox='0 0 26 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      strokeWidth='1.5'
      stroke='currentColor'
      className={className ? className : 'h-5'}
    >
      <circle cx='5' cy='5' r='3' fill='currentColor' />
      <circle cx='21' cy='5' r='3' fill='currentColor' />
      <circle cx='5' cy='21' r='3' fill='currentColor' />
      <circle cx='21' cy='37' r='3' fill='currentColor' />
      <circle cx='5' cy='37' r='3' fill='currentColor' />
      <circle cx='21' cy='21' r='3' fill='currentColor' />
    </svg>
  );
};

export default GripIcon;
