import React, { useEffect, useState } from 'react';
import { postAnswerFormQuestion } from '../../../../../services/forms/formContentService';

export default function Checkboxes({ content }) {
  const [fields, setFields] = useState(content?.questionselectcontent);
  const [answered, setAnswered] = useState(content?.answer?.choices);

  return (
    <div className='flex flex-col gap-4'>
      {fields?.map((field) => {
        return (
          <div key={field?.uuid} className='flex flex-row gap-2'>
            <input
              id={`${field?.uuid}`}
              className='w-5 rounded-md bg-white 
            focus:outline-none
            transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer'
              type='checkbox'
              defaultChecked={answered?.includes(field?.uuid)}
              // checked={field.is_correct}
              onChange={(e) => {
                const isTrue = (prev) => [...prev, field?.uuid];
                const isFalse = (prev) =>
                  prev?.filter((e) => e !== field?.uuid);

                postAnswerFormQuestion(content.uuid, {
                  choices: e.target.checked
                    ? isTrue(answered)
                    : isFalse(answered),
                })?.then(() => {
                  setAnswered((prev) =>
                    e.target.checked ? isTrue(prev) : isFalse(prev),
                  );
                });
              }}
            />
            <label className='cursor-pointer' htmlFor={`${field?.uuid}`}>
              {field?.content}
            </label>
          </div>
        );
      })}
    </div>
  );
}
