import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getClassroomAssignmentEvents = ({
  page,
  page_size,
  section,
  classroom,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/assignment_event/`, {
          params: { page, page_size, section, classroom },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getClassroomAssignmentEvent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/assignment_event/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postClassroomAssignmentEvent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/class/assignment_event/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.error(
            'Error response:',
            err.response ? err.response.data : err.message,
          );
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchClassroomAssignmentEvent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/class/assignment_event/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteClassroomAssignmentEvent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/class/assignment_event/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
