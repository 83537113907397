import React, { useContext, useEffect, useState } from 'react';
import { getStudyPlans } from '../../services/studyPlans/studyPlanService';
import StudyPlanCard from '../../components/cards/StudyPlanCard';
import NewStudyPlanCard from '../../components/cards/NewStudyPlanCard';
import PlacedholderCard from '../../components/cards/PlaceholderCard';
import { UserContext } from '../../utils/contexts/UserContext';

export default function StudyPlans() {
  const { user } = useContext(UserContext);
  const [studyPlans, setStudyPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    getStudyPlans({})
      .then((res) => {
        // console.log('res', res);
        setStudyPlans(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='border p-6 rounded-md bg-white flex flex-col gap-8'>
      <h2 className='text-lg font-semibold'>Problem sets</h2>
      <div className='flex flex-row flex-wrap gap-4'>
        {(user?.roles?.some((r) => r?.name === 'ADMIN') ||
          user?.roles?.some((r) => r?.name === 'PROJECTADMIN')) &&
          (loading ? <PlacedholderCard /> : <NewStudyPlanCard />)}
        {loading ? (
          <PlacedholderCard />
        ) : (
          studyPlans.map((sp) => (
            <StudyPlanCard
              key={sp?.uuid}
              studyPlan={sp}
              refreshData={getData}
            />
          ))
        )}
      </div>
    </div>
  );
}
