import React, { useEffect } from 'react';
import StudentSidebar from './StudentSidebar/StudentSidebar';
import TeacherSidebar from './TeacherSidebar/TeacherSidebar';

export default function ProfileSidebar({ user }) {
  useEffect(() => {
    // console.log('user', user);
    return () => {};
  }, [user]);

  // const navigate = useNavigate();

  if (user?.roles?.some((role) => role.name === 'STUDENT')) {
    return <StudentSidebar user={user} />;
  }

  if (
    user?.roles?.some((role) => role.name === 'PROJECTADMIN') ||
    user?.roles.some((role) => role.name === 'PROJECTREVIEWER')
  ) {
    return <TeacherSidebar user={user} />;
  }

  return <div></div>;
}
