import { useCallback, useContext, useEffect } from 'react';
import { create } from 'zustand';
import {
  getFormResponses,
  getFormUsers,
} from '../../../../../services/forms/formsService';
import { UserContext } from '../../../../../utils/contexts/UserContext';

const initialState = {
  formUUID: null,
  isLoading: true,
  response: [],
  users: [],
  count: 0,
  page: 0,
  points: 0,
};

const useResponsesStore = create((set, get) => ({
  ...initialState,
  customSet: (key, value) => {
    let v = value;
    if (typeof value === 'function') {
      v = value(get()[key]);
    }
    set({ [key]: v });
  },
  setFormUUID: (formUUID) => set({ formUUID }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setPage: (v) => get().customSet('page', v),
  setPoints: (v) => get().customSet('points', v),
  setResponse: (response) => set({ response }),
  setCount: (count) => set({ count }),
  setUsers: (users) => set({ users }),
  clear: () => set(initialState),
}));

export default function useResponses(uuid) {
  const { user } = useContext(UserContext);
  const { formUUID, setFormUUID } = useResponsesStore();
  const { isLoading, setIsLoading } = useResponsesStore();
  const { response, setResponse } = useResponsesStore();
  const { page, setPage } = useResponsesStore();
  const { count, setCount } = useResponsesStore();
  const { users, setUsers } = useResponsesStore();
  const { points, setPoints } = useResponsesStore();

  const fetchUsers = useCallback(() => {
    if (!formUUID || users.length) return;
    getFormUsers({ form: formUUID, page_size: 100 })?.then((res) => {
      setUsers(res.results?.filter((r) => r.user?.uuid !== user?.uuid));
      setCount(res.count - 1);
    });
  }, [formUUID, setCount, setUsers, user?.uuid, users.length]);

  const fetchResponse = useCallback(() => {
    if (!users[page]?.user?.uuid) return;
    setIsLoading(true);
    getFormResponses({ form: formUUID, uuid: users[page]?.user?.uuid })
      ?.then((res) => {
        if (res?.results[0]) {
          setPoints(
            res?.results[0]?.content?.reduce(
              (prev, cur) => prev + cur?.answer?.point,
              0,
            ),
          );
          setResponse(res?.results[0]);
        }
      })
      ?.finally(() => setIsLoading(false));
  }, [formUUID, page, setIsLoading, setPoints, setResponse, users]);

  const getNextResponse = useCallback(() => {
    setPage((prev) => (prev < count - 1 ? prev + 1 : prev));
  }, [count, setPage]);

  const getPrevResponse = useCallback(() => {
    setPage((prev) => (prev > 0 ? prev - 1 : prev));
  }, [setPage]);

  useEffect(() => {
    fetchResponse();
  }, [fetchResponse]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (formUUID !== uuid) {
      setPage(0);
      setCount(0);
      setPoints(0);
      setFormUUID(uuid);
    }
  }, [setPage, formUUID, setFormUUID, uuid, setCount, setPoints]);

  return {
    response,
    count,
    getNextResponse,
    getPrevResponse,
    users,
    setPage,
    page,
    isLoading,
    points,
    setPoints,
  };
}
