import bilAPI from '../api';

export const getAccount = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/account/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
