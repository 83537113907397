import React from 'react';

const MultipleChoiceIcon = ({ className }) => {
  return (
    <svg
      width='17'
      height='32'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      // viewBox='0 0 320 320'
      // width='24'
      // height='24'
      strokeWidth={1.5}
      stroke='currentColor'
      className={`${className ?? ' w-6 h-6 text-primary'}`}
      //   viewBox='0 0 300 300'
      //   fill='none'
      //   xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='15' cy='15' r='13' stroke='currentColor' strokeWidth='2' />
      <circle cx='15' cy='15' r='7' fill='currentColor' />
    </svg>
  );
};

export default MultipleChoiceIcon;
