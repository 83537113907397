import {
  MagnifyingGlassIcon,
  PlusCircleIcon,
  PlusSmallIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/InputBil';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { toast } from 'react-toastify';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import { format } from 'date-fns';
import { postRoadmapSubtask } from '../../../services/roadmapTask/roadmapSubtaskService';
import { UserContext } from '../../../utils/contexts/UserContext';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';
import SidebarModal from '../../../components/modals/SidebarModal/SidebarModal';
import AsyncSelect from 'react-select/async';
import { getProjects } from '../../../services/projects/projectService';

const AddSub = ({ task, refreshData, studentUuid }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [projects, setProjects] = useState([]);
  const problemsTimer = useRef();

  const reset = () => {
    setTitle('');
    setDescription('');
    setProjects([]);
    setStartDate(null);
    setEndDate(null);
  };

  const onClose = () => {
    reset();
  };

  const promiseProblemsOptions = (inputValue) =>
    new Promise((resolve) => {
      if (problemsTimer.current) {
        clearTimeout(problemsTimer.current);
      }
      problemsTimer.current = setTimeout(() => {
        getProjects({ search: inputValue }).then((res) =>
          resolve(res?.results.map((r) => ({ label: r?.name, value: r }))),
        );
      }, 500);

      return problemsTimer.current;
    });

  return (
    <>
      <PlusCircleIcon
        className='h-5 text-primary cursor-pointer'
        onClick={() => {
          setId(uuid);
        }}
      />

      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            required
          />
          <TextArea
            label={t('projects.description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div className='flex flex-row gap-4'>
            <DatePicker
              date={startDate}
              setDate={(d) => {
                // if (endDate < d && endDate) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setStartDate(d);
              }}
              label={t('roadmap.start_date')}
              required
            />
            <DatePicker
              date={endDate}
              setDate={(d) => {
                // if (startDate > d && startDate) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setEndDate(d);
              }}
              label={t('roadmap.end_date')}
              required
            />
          </div>
          <div>
            <div className='flex flex-row gap-1 items-center'>
              <MagnifyingGlassIcon className='h-5' />
              <AsyncSelect
                cacheOptions
                loadOptions={promiseProblemsOptions}
                placeholder={t('studyplans.start_typing_to_find_project')}
                className='w-full'
                onChange={(e) => {
                  setProjects((prev) => [...prev, e.value]);
                }}
              />
            </div>
            <div className='flex flex-col gap-1 '>
              {projects?.map((f, i) => (
                <div
                  key={f.uuid}
                  className='flex flex-row items-center group mt-1 gap-1'
                >
                  <span className='truncate'>{`${i + 1}. ${f.name}`}</span>
                  <XMarkIcon
                    className='h-5 p-0.5 text-error bg-red-100 rounded-full 
                      invisible group-hover:visible cursor-pointer'
                    onClick={() => {
                      setProjects((prev) =>
                        prev?.filter((e) => e.uuid !== f.uuid),
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('roadmap.add')}
              loading={loadingSubmit}
              onClick={() => {
                if (!title || !startDate || !endDate) {
                  toast.error(t('roadmap.required_fields_empty'));
                  return;
                }

                if (startDate > endDate) {
                  toast.error(t('roadmap.reversed_date_error'));
                  return;
                }

                const body = {};
                body['name'] = title;
                body['description'] = description;
                body['student'] = studentUuid ?? user?.uuid;
                body['parent'] = task;
                body['start_date'] = format(startDate, 'yyyy-MM-dd');
                body['deadline'] = format(endDate, 'yyyy-MM-dd');
                body['student'] = studentUuid ?? user?.uuid;
                body['attached_projects'] = projects?.map((e) => e.uuid);
                body['assignor'] = user?.uuid;

                setLoadingSubmit(true);
                postRoadmapSubtask(body)
                  .then(() => {
                    toast.success(t('roadmap.added_successfully'));
                    refreshData();
                    onClose();
                    setId('');
                  })
                  .catch((err) => {
                    toast.error(
                      err?.response?.data?.link?.length
                        ? err?.response?.data?.link[0]
                        : t('roadmap.something_went_wrong'),
                    );
                  })
                  .finally(() => setLoadingSubmit(false));
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default AddSub;
