import bilAPI from '../api';
import contentAPI from '../contentApi';
import contentApi from '../contentApi';

export const getFormContents = ({ form, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/forms/content/`, {
          params: { page, page_size, form },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postFormContent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentApi
        .post(`/forms/content/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchFormContent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/forms/content/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteFormContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/forms/content/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postAnswerFormQuestion = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/forms/content/${uuid}/answer/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRateAnswer = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/forms/content/${uuid}/set_point/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.log(err);
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postAnswerFileFormQuestion = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentApi
        .post(`/forms/content/${uuid}/answer/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRemoveContentImage = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      contentApi
        .post(`/forms/content/${uuid}/remove_attachment_img/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRemoveContentFileAnswer = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      contentApi
        .post(`/forms/content/${uuid}/remove_attachment_from_answer/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
