import React, { useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import {
  patchFormContent,
  postFormContent,
} from '../../../../../services/forms/formContentService';
import SimpleLoader from '../../../../../components/Loader/SimpleLoader';

const FormToolbar = ({ update, uuid, order = 0 }) => {
  const [isCreating, setIsCreating] = useState(false);
  return (
    <div className='absolute -right-10'>
      <div className='bg-white flex flex-col p-1 rounded-lg'>
        {isCreating ? (
          <div className='relative'>
            <SimpleLoader className='h-5' />
          </div>
        ) : (
          <PlusCircleIcon
            className='h-5 cursor-pointer'
            title={'add question'} // to translate
            onClick={() => {
              setIsCreating(true);
              postFormContent({
                form: uuid,
                test_type: 'SELECTONE',
                order: order,
              })
                ?.then((res) => {
                  patchFormContent(res.uuid, { order: order })
                    ?.then(() => {
                      update()?.finally(() => setIsCreating(false));
                    })
                    ?.catch(() => {
                      setIsCreating(false);
                    });
                })
                ?.catch(() => setIsCreating(false));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FormToolbar;
