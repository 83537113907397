import {
  MagnifyingGlassIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/InputBil';
import { toast } from 'react-toastify';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import {
  deleteRoadmapSubtask,
  patchRoadmapSubtask,
} from '../../../services/roadmapTask/roadmapSubtaskService';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { format } from 'date-fns';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';
import SidebarModal from '../../../components/modals/SidebarModal/SidebarModal';
import { getProjects } from '../../../services/projects/projectService';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';

const EditSub = ({ datum, refreshData, taskStatus, className, isStudent }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [projects, setProjects] = useState([]);
  const problemsTimer = useRef();
  const navigate = useNavigate();

  const handleDeleteConfirmation = () => {
    toast.dark(
      <>
        <div>{t('roadmap.are_you_sure')}</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text={t('buttons.yes')}
            color='error-solid'
            onClick={() => {
              setLoadingDelete(true);
              deleteRoadmapSubtask(datum?.uuid)
                .then(() => {
                  toast.success(t('roadmap.deleted_successfully'));
                  refreshData();
                  onClose();
                })
                .finally(() => setLoadingDelete(false));
            }}
          />
          <Button
            text={t('buttons.no')}
            className='text-white'
            onClick={toast.dismiss}
          />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  const reset = useCallback(() => {
    setTitle(datum?.name);
    setDescription(datum?.description);
    setProjects(datum?.attached_projects);
    setStartDate(new Date(datum?.start_date));
    setEndDate(new Date(datum?.deadline));
  }, [
    datum?.attached_projects,
    datum?.deadline,
    datum?.description,
    datum?.name,
    datum?.start_date,
  ]);

  const onClose = () => {
    reset();
  };
  const promiseProblemsOptions = (inputValue) =>
    new Promise((resolve) => {
      if (problemsTimer.current) {
        clearTimeout(problemsTimer.current);
      }
      problemsTimer.current = setTimeout(() => {
        getProjects({ search: inputValue }).then((res) =>
          resolve(res?.results.map((r) => ({ label: r?.name, value: r }))),
        );
      }, 500);

      return problemsTimer.current;
    });

  useEffect(() => {
    if (datum && title === null) {
      reset();
    }
  }, [datum, reset, title]);

  return (
    <>
      <div
        className={className}
        onClick={() => {
          setId(uuid);
        }}
      >
        {datum?.name}
      </div>
      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.edit')}
        HeaderIcon={PencilIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title ?? ''}
            required
            readOnly={isStudent}
          />
          <TextArea
            label={t('projects.description')}
            readOnly={isStudent}
            value={description ?? ''}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className='flex flex-row gap-4'>
            <DatePicker
              date={startDate ? new Date(startDate) : null}
              setDate={(d) => {
                // if (endDate < d) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setStartDate(d);
              }}
              readOnly={isStudent}
              label={t('roadmap.start_date')}
              required
            />
            <DatePicker
              date={endDate ? new Date(endDate) : null}
              setDate={(d) => {
                // if (startDate > d) {
                //   toast.error(t('roadmap.reversed_date_error'));
                //   return;
                // }
                setEndDate(d);
              }}
              readOnly={isStudent}
              label={t('roadmap.end_date')}
              required
            />
          </div>
          <div>
            {!isStudent && (
              <div className='flex flex-row gap-1 items-center'>
                <MagnifyingGlassIcon className='h-5' />
                <AsyncSelect
                  cacheOptions
                  loadOptions={promiseProblemsOptions}
                  placeholder={t('studyplans.start_typing_to_find_project')}
                  className='w-full'
                  onChange={(e) => {
                    setProjects((prev) => [...prev, e.value]);
                  }}
                />
              </div>
            )}
            <div className='flex flex-col gap-1 '>
              {projects?.map((f, i) => (
                <div
                  key={f.uuid}
                  className='flex flex-row items-center group mt-1 gap-1 p-2 bg-white border rounded-2xl'
                >
                  <a
                    href={`/projects/${f.uuid}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span className='truncate hover:underline hover:text-primary cursor-pointer'>{`${
                      i + 1
                    }. ${f.name}`}</span>
                  </a>
                  {!isStudent && (
                    <XMarkIcon
                      className='min-w-fit h-5 p-0.5 text-error bg-red-100 rounded-full 
                      invisible group-hover:visible cursor-pointer'
                      onClick={() => {
                        setProjects((prev) =>
                          prev?.filter((e) => e.uuid !== f.uuid),
                        );
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          {!isStudent && (
            <div className='flex flex-row justify-center gap-4 mt-4'>
              <Button
                color='error'
                text={t('roadmap.delete')}
                loading={loadingDelete}
                onClick={() => {
                  handleDeleteConfirmation();
                }}
                disabled={taskStatus === 'finished'}
              />
              <Button
                text={t('roadmap.save')}
                loading={loadingSubmit}
                onClick={() => {
                  if (!title || !startDate || !endDate) {
                    toast.error(t('roadmap.required_fields_empty'));
                    return;
                  }

                  if (startDate > endDate) {
                    toast.error(t('roadmap.reversed_date_error'));
                    return;
                  }

                  const body = {};
                  body['name'] = title;
                  body['description'] = description;
                  body['start_date'] = format(
                    new Date(startDate),
                    'yyyy-MM-dd',
                  );
                  body['deadline'] = format(new Date(endDate), 'yyyy-MM-dd');
                  body['attached_projects'] = projects?.map((e) => e.uuid);

                  setLoadingSubmit(true);
                  patchRoadmapSubtask(datum?.uuid, body)
                    .then(() => {
                      toast.success(t('roadmap.added_successfully'));
                      refreshData();
                      onClose();
                      setId('');
                    })
                    .catch((err) => {
                      toast.error(
                        err?.response?.data?.link?.length
                          ? err?.response?.data?.link[0]
                          : t('roadmap.something_went_wrong'),
                      );
                    })
                    .finally(() => setLoadingSubmit(false));
                }}
                disabled={taskStatus === 'finished'}
              />
            </div>
          )}
        </div>
      </SidebarModal>
    </>
  );
};

export default EditSub;
