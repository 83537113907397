import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

export default function Checkboxes({ content, is_all_correct }) {
  const [fields] = useState(content?.questionselectcontent);
  const [answered] = useState(content?.answer?.choices);

  return (
    <div className='flex flex-col gap-1'>
      {fields?.map((field) => {
        const is_checked = answered?.includes(field?.uuid);
        return (
          <div
            key={field?.uuid}
            className={`flex flex-row justify-between p-2 rounded ${
              field?.is_correct && is_checked && 'bg-green-100'
            } ${!field?.is_correct && is_checked && 'bg-red-100'}`}
          >
            <div className={`flex flex-row gap-2 `}>
              <input
                readOnly
                id={`${field?.uuid}`}
                className='w-5 rounded-md bg-white 
            focus:outline-none
            transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer'
                type='checkbox'
                checked={is_checked}
              />
              <label className='cursor-pointer' htmlFor={`${field?.uuid}`}>
                {field?.content}
              </label>
            </div>
            {field?.is_correct && is_checked && (
              <CheckIcon className='h-5 text-success' />
            )}
            {!field?.is_correct && is_checked && (
              <XMarkIcon className='h-5 text-error' />
            )}
          </div>
        );
      })}
      {!is_all_correct && (
        <div>
          Correct answers
          {fields
            ?.filter((f) => f.is_correct)
            ?.map((field) => {
              return (
                <div key={field?.uuid} className={`flex flex-row gap-2 p-2`}>
                  <input
                    readOnly
                    id={`${field?.uuid}`}
                    className='w-5 rounded-md bg-white 
                      focus:outline-none
                      transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer'
                    type='checkbox'
                    checked={true}
                  />
                  <label className='cursor-pointer' htmlFor={`${field?.uuid}`}>
                    {field?.content}
                  </label>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
