import React, { useContext, useEffect, useState } from 'react';
import PreferredPairs from './PreferredPairs/PreferredPairs';
import { UserContext } from '../../../utils/contexts/UserContext';
import { Tab } from '@headlessui/react';
import Progress from './Progress/Progress';
import Projects from './Projects/Projects';
import StudentProfileCard from './StudentProfileCard';
import TestResults from './TestResults/TestResults';
import RoadmapTab from './RoadmapTab/RoadmapTab';
import ProjectSets from './ProjectSets/ProjectSets';
import Courses from './Courses/Courses';

export default function StudentProfile({ uuid, tab }) {
  const { user } = useContext(UserContext);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOwnProfile, setIsOwnProfile] = useState(null);

  useEffect(() => {
    if (user?.uuid && uuid) {
      if (user.uuid === uuid) {
        setIsOwnProfile(true);
      } else {
        setIsOwnProfile(false);
      }
    }

    return () => {};
  }, [uuid, user]);

  useEffect(() => {
    // if (tab === 'preferred') {
    //   setSelectedIndex(3);
    // }

    return () => {};
  }, [tab]);

  return (
    <div className='w-full flex flex-col gap-4  px-4 py-2'>
      {!isOwnProfile && <StudentProfileCard studentUuid={uuid} />}
      <div className='flex flex-col gap-4 border rounded-md'>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className='flex flex-row gap-5 border-b border-gray-200'>
            <Tab
              key={'roadmap'}
              className={({ selected }) =>
                selected
                  ? 'py-2 text-sm font-medium px-4 border-b-2 border-blue-500'
                  : 'py-2 text-sm font-medium px-4 text-gray-500 hover:border-b-2 hover:border-gray-300 hover:text-gray-800'
              }
            >
              Roadmap
            </Tab>

            <Tab
              key={'test'}
              className={({ selected }) =>
                selected
                  ? 'py-2 text-sm font-medium px-4 border-b-2 border-blue-500'
                  : 'py-2 text-sm font-medium px-4 text-gray-500 hover:border-b-2 hover:border-gray-300 hover:text-gray-800'
              }
            >
              Personality & skills
            </Tab>

            <Tab
              key={'projectSets'}
              className={({ selected }) =>
                selected
                  ? 'py-2 text-sm font-medium px-4 border-b-2 border-blue-500'
                  : 'py-2 text-sm font-medium px-4 text-gray-500 hover:border-b-2 hover:border-gray-300 hover:text-gray-800'
              }
            >
              Problems Sets
            </Tab>
            <Tab
              key={'Projects'}
              className={({ selected }) =>
                selected
                  ? 'py-2 text-sm font-medium px-4 border-b-2 border-blue-500'
                  : 'py-2 text-sm font-medium px-4 text-gray-500 hover:border-b-2 hover:border-gray-300 hover:text-gray-800'
              }
            >
              Problems
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <RoadmapTab studentUuid={uuid} />
            </Tab.Panel>

            <Tab.Panel>
              <TestResults studentUuid={uuid} />
            </Tab.Panel>

            <Tab.Panel>
              <ProjectSets uuid={uuid} />
            </Tab.Panel>

            <Tab.Panel>
              <Projects uuid={uuid} isOwnProfile={isOwnProfile} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>

    // <div className='flex flex-col border rounded-md bg-white shadow-md mx-2 md:mx-0'>
    //   <PreferredPairs />
    // </div>
  );
}
