import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import {
  getGeneralAllSchoolsStats,
  getGeneralSchoolStats,
  getGenerallGradeStats,
} from '../../services/stats/statsService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { toast } from 'react-toastify';
import { Button } from '../../components/forms/Buttons/Button';
import { Link } from 'react-router-dom';

const SCHOOL_COLUMNS = {
  type: 'school',
  columns: [
    {
      accessorKey: 'organization_name',
      header: () => 'School Name',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },

    {
      accessorKey: 'projects_finished',
      header: () => 'Projects Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'studyplans_finished',
      header: () => 'Project Sets Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'courses_finished',
      header: () => 'Courses Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'tests_passed',
      header: () => 'Students Passed Tests',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'roadmap_tasks_completed',
      header: () => 'Students Using Roadmap',
      footer: (props) => props.column.id,
    },
  ],
};

const GRADE_COLUMNS = {
  type: 'grade',
  columns: [
    {
      accessorKey: 'group_name',
      header: () => 'Group Name',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },

    {
      accessorKey: 'stats.projects_finished',
      header: () => 'Projects Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'stats.studyplans_finished',
      header: () => 'Project Sets Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'stats.courses_finished',
      header: () => 'Courses Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'stats.tests_passed',
      header: () => 'Students Passed Tests',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'stats.roadmap_tasks_completed',
      header: () => 'Students Using Roadmap',
      footer: (props) => props.column.id,
    },
  ],
};

const STUDENT_COLUMNS = {
  type: 'student',
  columns: [
    {
      accessorKey: 'name',
      header: () => 'Student Name',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => (
        <Link
          to={`/profile/${row.original.uuid}`}
          target='_blank'
          className='hover:underline cursor-pointer'
        >
          {row.original.name}
        </Link>
      ),
      footer: (props) => props.column.id,
    },

    {
      accessorKey: 'projects_finished',
      header: () => 'Projects Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'studyplans_finished',
      header: () => 'Project Sets Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'courses_finished',
      header: () => 'Courses Finished',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'tests_passed',
      header: () => 'Test passed',
      footer: (props) => props.column.id,
    },
    {
      accessorKey: 'roadmap_tasks_completed',
      header: () => 'Roadmap Tasks Finished',
      footer: (props) => props.column.id,
    },
  ],
};

export default function GeneralTable({ selectedSchool }) {
  const [dataToShow, setDataToShow] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [gradeData, setGradeData] = useState([]);
  const [tableTitle, setTableTitle] = useState('Schools');
  const [sorting, setSorting] = useState([]);
  const [tableColumns, setTableColumns] = useState(SCHOOL_COLUMNS);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(() => tableColumns.columns, [tableColumns]);

  const table = useReactTable({
    data: dataToShow,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    if (selectedSchool?.length > 3) {
      setLoading(true);
      getGeneralSchoolStats(selectedSchool)
        .then((res) => {
          setGradeData(res?.groups);
          setDataToShow(res?.groups);
          setTableTitle('School: ' + res?.organization);
          setTableColumns(GRADE_COLUMNS);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (selectedSchool === 'all') {
      if (tableColumns.type === 'grade') {
        setTableColumns(SCHOOL_COLUMNS);
        setTableTitle('Schools');
        setDataToShow(schoolData);
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  useEffect(() => {
    setLoading(true);
    getGeneralAllSchoolsStats()
      .then((res) => {
        setDataToShow(res.results);
        setSchoolData(res.results);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, []);

  if (loading) {
    return (
      <div className='border-2 rounded-md p-4'>
        <SimpleLoader className='h-12' />
      </div>
    );
  }

  return (
    <div className='flex flex-col border-2 rounded-md'>
      <div className='flex flex-row p-2 gap-2 items-center'>
        {tableColumns?.type !== 'school' && (
          <Button
            className='max-h-8'
            text={'Back'}
            onClick={() => {
              // console.log('row', row.original.uuid);
              setLoading(true);

              if (tableColumns.type === 'grade') {
                setTableColumns(SCHOOL_COLUMNS);
                setTableTitle('Schools');
                setDataToShow(schoolData);
              }
              if (tableColumns.type === 'student') {
                setTableColumns(GRADE_COLUMNS);
                setDataToShow(gradeData);
                setTableTitle((v) => getSubstringBeforeChar(v, ' |'));
              }

              setLoading(false);
            }}
          />
        )}
        <h3 className='text-xl'>{tableTitle}</h3>
      </div>
      <table className='min-w-full divide-y divide-gray-200'>
        <thead className=''>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className='border-b-2 sticky top-0 bg-gray-200'
            >
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className='group max-w-th p-1 text-left text-gray-600'
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        className='flex items-center justify-between break-all'
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, 60)
            .map((row) => {
              return (
                <tr
                  key={row.id}
                  className={`border-b-2 last:border-b-0 ${
                    tableColumns.type !== 'student' &&
                    'hover:bg-slate-200 cursor-pointer'
                  }`}
                  onClick={async () => {
                    // console.log('row', row.original.uuid);
                    if (tableColumns.type !== 'student') {
                      setLoading(true);
                      try {
                        if (tableColumns.type === 'school') {
                          await getGeneralSchoolStats(row.original.uuid)
                            .then((res) => {
                              setGradeData(res?.groups);
                              setDataToShow(res?.groups);
                              setTableTitle('School: ' + res?.organization);
                              setTableColumns(GRADE_COLUMNS);
                            })
                            .finally(() => {
                              setLoading(false);
                            });
                        }
                        if (tableColumns.type === 'grade') {
                          await getGenerallGradeStats(row.original.uuid)
                            .then((res) => {
                              setTableColumns(STUDENT_COLUMNS);
                              setDataToShow(res?.students);
                              setTableTitle((v) => v + ' | ' + res?.group);
                            })
                            .finally(() => {
                              setLoading(false);
                            });
                        }
                      } catch (error) {
                        toast.error('Something went wrong');
                      }
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className='py-1 px-2 text-center first:text-left'
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

function getSubstringBeforeChar(str, char) {
  var index = str.indexOf(char);
  if (index !== -1) {
    return str.substring(0, index); // or str.slice(0, index);
  }
  return str; // return the original string if the character is not found
}
