import bilAPI from '../api';

export const getRoadmapTasks = ({ student, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/tasks/`, {
          params: {
            student,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getRoadmapTask = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/tasks/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRoadmapTask = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/roadmap/tasks/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchRoadmapTask = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/roadmap/tasks/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteRoadmapTask = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/roadmap/tasks/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const acceptRoadmapTask = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/roadmap/tasks/${uuid}/accept_task/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const unacceptRoadmapTask = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/roadmap/tasks/${uuid}/unaccept_task/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postTaskWithAI = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/roadmap/ai_tasks/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
