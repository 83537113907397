import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { getStudentsStatsByLevels } from '../../services/stats/statsService';
import { Button } from '../../components/forms/Buttons/Button';

const LEVELS = ['Beginner', 'Easy', 'Medium', 'Hard'];
const PAGE_SIZE = 100;

const StackedBarChart = ({ org }) => {
  // const { user } = useContext(UserContext);
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [n, setN] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    getStudentsStatsByLevels({ organization: org, page_size: PAGE_SIZE })
      .then((res) => {
        // console.log('byLevel', res?.results);
        setData(res?.results);
        setN(res?.count);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  }, [org]);

  const addData = () => {
    setLoadingData(true);
    getStudentsStatsByLevels({
      organization: org,
      page_size: PAGE_SIZE,
      page: page + 1,
    })
      .then((res) => {
        setData((prev) => [...prev, ...res?.results]);
        setPage(page + 1);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  };

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      // height: '1600px',
      // events: {
      //   legendClick: (chartContext, seriesIndex, config) => {
      //     // console.log('chartContext', chartContext);
      //     // console.log('seriesIndex', seriesIndex);
      //     // console.log('config', config);
      //   },
      // },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 20,
            style: {
              fontSize: '13px',
              fontWeight: 600,
            },
            formatter: (val, opt) => {
              return `${val} ${t('stats._projects')}`;
            },
          },
        },
      },
    },
    xaxis: {
      categories: data?.map((d) => `${d?.first_name} ${d?.last_name}`),
      axisTicks: {
        show: true,
      },
      position: 'top',
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '14px',
      onItemClick: { toggleDataSeries: false },
    },
    noData: {
      text: t('stats.no_data'),
    },
    // colors: [
    //   ({ value, seriesIndex, dataPointIndex, w }) => {
    //     let i = w.config.series[0].data[dataPointIndex]['x'];

    //     if (i === data?.find((d) => d?.uuid === user?.current_school)?.name) {
    //       return '#6DD24A';
    //     } else {
    //       return '#2C59F8';
    //     }
    //   },
    // ],
    yaxis: {
      show: true,
      showForNullSeries: false,
      labels: {
        show: true,
        align: 'left',
        // minWidth: 50,
        maxWidth: 600,
        // offsetX: 10,
        style: {
          fontSize: '14px',
          cssClass: 'yaxis-label',
          // colors: data?.length
          //   ? data.map((d) =>
          //       d?.organization?.uuid === user?.organization ? '#3b82f6' : null,
          //     )
          //   : [],
        },
        formatter: (value, indexes) => {
          return `${
            indexes?.dataPointIndex + 1
          }.\u00A0 ${value}\u00A0\u00A0\u00A0\u00A0`;
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
  };

  const series = LEVELS.map((l) => ({
    name: l,
    data: data?.map((d) => (Object.keys(d).some((k) => k === l) ? d[l] : null)),
  }));

  return (
    <>
      {loadingData ? (
        <div className='flex flex-row justify-center pt-8'>
          <SimpleLoader className={'w-12 h-12'} />
        </div>
      ) : data?.length ? (
        <Chart
          options={options}
          series={series}
          type='bar'
          height={data?.length > 30 ? '1800' : data?.length < 15 ? '450' : ''}
        />
      ) : (
        <div className='p-16 w-full'>{t('stats.no_data')}</div>
      )}
      {page * PAGE_SIZE < n && (
        <div className='flex flex-row justify-center py-4'>
          <Button text={t('stats.see_more')} onClick={() => addData()} />
        </div>
      )}
    </>
  );
};

export default StackedBarChart;
