import { useEffect } from 'react';

const useAutosizeTextArea = (ref, value) => {
  useEffect(() => {
    if (ref) {
      ref.current.style.height = '0px';
      const scrollHeight = ref?.current?.scrollHeight;
      ref.current.style.height = scrollHeight + 'px';
    }
  }, [ref, value]);
};

export default useAutosizeTextArea;
