import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import bgImage from '../../../../assets/images/bg.jpg';
import { Button } from '../../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { postClassroomSection } from '../../../../services/classrooms/sectionsService';
import { PlusIcon } from '@heroicons/react/24/solid';
import SectionEvents from '../events/SectionEvents';

export default function StudentClassroom({ sections, classroom }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [classroomSections, setClassroomSections] = useState(sections);
  const [section, setSection] = useState(sections[0]?.uuid);

  return (
    <div>
      <div className='flex flex-col'>
        <div
          className='h-48 flex flex-row'
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
          }}
        >
          <div className='w-1/3 px-4 py-8'>
            <Button
              text={t('buttons.back')}
              color='secondary-solid'
              Icon={ChevronLeftIcon}
              onClick={() => navigate('/classrooms')}
            />
          </div>
          <div className='text-white w-2/3 flex flex-col h-full justify-center gap-8 px-8 py-8'>
            <div className='text-2xl lg:text-4xl font-medium pl-4'>
              {classroom.name}
            </div>
          </div>
        </div>
        <div className='flex lg:flex-row md:flex-row flex-col'>
          <div className='min-w-[20rem] px-4'>
            <div
              className='rounded-xl border shadow-lg py-4 flex flex-col divide-y-2
                w-full bg-white md:-mt-12 -mt-4 font-medium lg:text-lg'
            >
              <div className='flex flex-col gap-4'>
                {classroomSections?.map((s, i) => {
                  return (
                    <>
                      {!!i && <div className='border-b' />}
                      <div
                        className={`px-6 cursor-pointer ${
                          section === s.uuid && 'font-bold'
                        }`}
                        onClick={() => setSection(s.uuid)}
                      >
                        {s.name}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {section === 'settings' && (
              <div
                className='rounded-xl border shadow-xl py-4 px-6 flex flex-col
                w-full bg-white mt-4 cursor-pointer'
                onClick={() => {
                  postClassroomSection({
                    classroom: uuid,
                    description: 'New section',
                    name: `Section ${classroomSections?.length + 1}`,
                  })?.then((res) => {
                    setClassroomSections((prev) => [...prev, res]);
                  });
                }}
              >
                <PlusIcon className='h-5' />
              </div>
            )}
          </div>
          <div className='lg:w-2/3 md:w-2/3 py-6 px-4'>
            <SectionEvents
              classroom={classroom}
              section={section}
              isTeacher={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
