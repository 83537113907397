import React, { useEffect, useState } from 'react';
import { getAccount } from '../../../services/auth/authService';

export default function StudentProfileCard({ studentUuid }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (studentUuid) {
      getAccount(studentUuid).then((res) => {
        setUser(res);
      });
    }

    return () => {};
  }, [studentUuid]);

  return (
    <div className='flex flex-col md:flex-row gap-2'>
      <div className='flex flex-row justify-center'>
        {user?.profile_picture ? (
          <div className='relative inline-block w-20 h-20'>
            <img
              src={user?.profile_picture}
              alt='User Avatar'
              className='w-20 h-20 rounded-full object-cover'
            />
          </div>
        ) : (
          <div className='w-20 h-20 bg-blue-500 text-white rounded-full flex items-center justify-center text-3xl font-semibold'>
            {user?.first_name[0]}
          </div>
        )}
      </div>
      {user?.uuid ? (
        <div className='grid grid-cols-2 border p-2 rounded-md'>
          <div className='col-span-2 mb-2'>
            <h2 className='font-semibold text-lg'>
              {user?.first_name + ' ' + user?.last_name}
            </h2>
          </div>
          <div className='mr-4'>
            <p className='text-xs text-gray-600'>School:</p>
            <p className='text-sm'>{user?.username}</p>
          </div>
          <div>
            <p className='text-xs text-gray-600'>Position:</p>
            <p className='text-sm'>{user?.roles[0]?.name}</p>
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-2 border p-2 rounded-md'>
          <div className='col-span-2 mb-2'>
            <div className='font-semibold text-lg animate-pulse bg-slate-500'></div>
          </div>
          <div className='mr-4'>
            <p className='text-xs text-gray-600'>School:</p>
            <p className='text-sm'>{user?.username}</p>
          </div>
          <div>
            <p className='text-xs text-gray-600'>Position:</p>
            <p className='text-sm'>{user?.roles[0]?.name}</p>
          </div>
        </div>
      )}
    </div>
  );
}
