import React, { useState } from 'react';
import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  deleteClassroomSection,
  patchClassroomSection,
} from '../../../../services/classrooms/sectionsService';
import GripIcon from '../../../../components/Icons/GripIcon';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';

export default function SectionListRow({
  section,
  selected,
  onClick,
  dragProps,
  draggingId,
  isDragging,
  refeshData,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [title, setTitle] = useState(section?.name);
  const [currentTitle, setCurrentTitle] = useState(section?.name);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsEdit(false);
    setIsDelete(false);
    if (isDelete) {
      setIsDeleting(true);
      deleteClassroomSection(section.uuid)
        ?.then(() => {
          refeshData()?.finally(() => setIsDeleting(false));
        })
        ?.catch(() => setIsDeleting(false));
      return;
    }
    // setIsEditing(true);
    patchClassroomSection(section.uuid, { name: title })
      ?.then((res) => {
        setCurrentTitle(res?.name);
      })
      ?.catch(() => {
        setTitle(currentTitle);
      });
    // .finaly(() => setIsEditing(false));
  };

  const onDecline = (e) => {
    e.preventDefault();

    setIsEdit(false);
    setIsDelete(false);

    setTitle(currentTitle);
  };

  return (
    <>
      <div
        className={`py-2 flex flex-row group/card justify-between items-center cursor-pointer ${
          selected && 'font-bold'
        } ${isDragging && 'opacity-50'}`}
        onClick={onClick}
      >
        <div className='flex flex-row gap-2 items-center truncate w-full'>
          <div
            {...dragProps}
            className={`${
              draggingId && draggingId !== section.order?.toString()
                ? ''
                : 'group-hover/card:visible'
            } ${!(isDragging || selected) && 'invisible'} text-secondary`}
          >
            <GripIcon className='h-4 2' />
          </div>
          <input
            className={`${
              isEdit ? '' : 'cursor-pointer border-transparent'
            } bg-white border-b w-full focus:outline-0`}
            readOnly={!isEdit}
            value={title}
            onClick={() => {
              if (isEdit) return;
              onClick();
            }}
            onDoubleClick={() => {
              if (isDelete) return;
              setIsEdit(true);
            }}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={(e) => {
              switch (e?.key) {
                case 'Enter':
                  onSubmit(e);
                  break;
                case 'Escape':
                  onDecline(e);
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
        <div
          className='flex flex-row gap-2 items-center min-w-fit'
          onClick={(e) => e.preventDefault()}
        >
          {isEdit || isDelete ? (
            <>
              <CheckIcon
                className={`h-7 p-1 ${
                  isEdit
                    ? 'text-green-600 hover:bg-green-100'
                    : 'text-red-600 hover:bg-red-100'
                } rounded-full`}
                onClick={onSubmit}
              />
              <XMarkIcon
                className={`h-7 p-1 ${
                  isEdit
                    ? 'text-secondary hover:bg-gray-200'
                    : 'text-secondary hover:bg-gray-200'
                } rounded-full`}
                onClick={onDecline}
              />
            </>
          ) : (
            <>
              {isDeleting ? (
                <div className='relative'>
                  <SimpleLoader className='h-7 p-1' />
                </div>
              ) : (
                <TrashIcon
                  className='ml-9 h-7 p-1 hover:bg-red-100 text-red-600 rounded-full'
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDelete(true);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
