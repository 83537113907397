import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../../../components/forms/Buttons/Button';
import SimpleLoader from '../../../../../../../../components/Loader/SimpleLoader';
import { deleteFormContentQuestion } from '../../../../../../../../services/forms/formContentQuestionService';

export default function OptionBlank({
  children,
  field,
  fields,
  setFields,
  update,
  selected,
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <div className='flex flex-row gap-2 w-full'>
      {children}
      {field.is_correct && (
        <CheckIcon className='h-5 text-success' title='correct answer' /> // to translate
      )}
      {fields?.length > 1 &&
        selected &&
        (isDeleting ? (
          <div className='relative '>
            <SimpleLoader className='h-4' />
          </div>
        ) : (
          <XMarkIcon
            title={'Remove'} // to translate
            className='h-5 cursor-pointer'
            onClick={() => {
              toast.dark(
                <>
                  <div>You sure want to delete option {field.content}?</div>
                  {/*to translate*/}
                  <div className='flex justify-center mt-3 gap-3'>
                    <Button
                      text={'Yes'} // to translate
                      autoFocus
                      color='error-solid'
                      onClick={(e) => {
                        setIsDeleting(true);
                        deleteFormContentQuestion(field?.uuid)
                          ?.then(() => {
                            const newFields = fields.filter((f) => {
                              return field.uuid !== f.uuid;
                            });
                            setFields(newFields);
                            //   update();
                          })
                          ?.catch((err) => {
                            console.log(err);
                            toast.error(
                              `Error when deleting "${field.content}"`,
                            );
                          })
                          ?.finally(() => setIsDeleting(false));
                      }}
                    />
                    <Button
                      text={'No'} // to translate
                      className='text-white'
                      onClick={toast.dismiss}
                    />
                  </div>
                </>,
                {
                  autoClose: false,
                  closeButton: true,
                },
              );
            }}
          />
        ))}
    </div>
  );
}
