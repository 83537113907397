import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import { useParams } from 'react-router-dom';
import Student from './Student/Student';
import ContentCreater from './ContentCreater/ContentCreater';
import { getSections } from '../../../services/courses/sectionService';
import { getCourses } from '../../../services/courses/courseService';

const Course = () => {
  const { user } = useContext(UserContext);
  const { uuid } = useParams();
  const [sections, setSections] = useState([]);
  const [course, setCourse] = useState([]);

  const getSectionsData = useCallback(() => {
    getSections({ course: uuid }).then((res) => {
      setSections(res.results);
    });
  }, [uuid]);

  const getCourse = useCallback(() => {
    getCourses({ uuid }).then((res) => {
      setCourse(res);
    });
  }, [uuid]);

  useEffect(() => {
    getCourse();
    getSectionsData();
  }, [getCourse, getSectionsData]);

  if (user?.roles?.some((r) => ['ADMIN']?.includes(r.name))) {
    return (
      <ContentCreater
        data={sections}
        refreshData={getSectionsData}
        course={course}
      />
    );
  } else if (user?.roles?.some((r) => r?.name === 'STUDENT')) {
    return (
      <Student
        data={sections}
        refreshData={getSectionsData}
        course={course}
        refreshCourse={getCourse}
      />
    );
  } else {
    return <></>;
  }
};

export default Course;
