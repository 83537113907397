import React, { useEffect, useState } from 'react';
import { getGeneralStudentsLeaderboard } from '../../services/stats/statsService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import { Link } from 'react-router-dom';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const PAGE_SIZE = 100;

const GeneralTable = ({ org }) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  // eslint-disable-next-line
  const [n, setN] = useState(0);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState();

  const addData = () => {
    setLoadingMore(true);
    getGeneralStudentsLeaderboard({
      organization: org,
      page_size: PAGE_SIZE,
      page: page + 1,
      ordering,
    })
      .then((res) => {
        setData((prev) => prev.concat(res?.results));
        setPage(page + 1);
      })
      .catch(() => {})
      .finally(() => setLoadingMore(false));
  };

  const getData = () => {
    setLoadingData(true);
    getGeneralStudentsLeaderboard({
      organization: org,
      page_size: PAGE_SIZE,
      page: 1,
      ordering,
    })
      .then((res) => {
        // console.log('data', res?.results);
        // setData((prev) => prev.concat(res?.results));
        setData(res?.results);
        setN(res?.count);
        setPage(1);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [org]);

  const handleOrdering = (newOrdering) => {
    newOrdering = `-${newOrdering}`;
    if (ordering === newOrdering) {
      if (ordering.charAt(0) === '-') {
        setOrdering(ordering.substring(1));
      } else {
        setOrdering(`-${ordering}`);
      }
    } else {
      setOrdering(newOrdering);
    }

    getData();
  };

  return (
    <div className='flex flex-col gap-4'>
      {loadingData && page === 1 ? (
        <div className='flex flex-col p-8 items-center'>
          <SimpleLoader className={'h-12 w-12'} />
        </div>
      ) : (
        <div className='border-2 rounded-xl p-2'>
          <table className='table-auto w-full'>
            <thead>
              <tr className='border-b-2 sticky top-0 bg-white'>
                <th className='pb-2 px-2'>#</th>
                <th className='text-left'>{t('stats.student')}</th>
                <th className='text-left'>{t('stats.school')}</th>
                <th className=''>
                  <div
                    className='flex flex-row items-center justify-center cursor-pointer gap-1'
                    onClick={() => handleOrdering(`projects_finished`)}
                  >
                    {t('leaderboard.projects_finished')}
                    {ordering === `projects_finished` && (
                      <ChevronDownIcon className='h-4' />
                    )}
                    {ordering === `-projects_finished` && (
                      <ChevronUpIcon className='h-4' />
                    )}
                  </div>
                </th>
                <th className=''>
                  <div
                    className='flex flex-row items-center justify-center cursor-pointer gap-1'
                    onClick={() => handleOrdering(`studyplans_finshed`)}
                  >
                    {t('leaderboard.project_sets_finished')}
                    {ordering === `studyplans_finshed` && (
                      <ChevronDownIcon className='h-4' />
                    )}
                    {ordering === `-studyplans_finshed` && (
                      <ChevronUpIcon className='h-4' />
                    )}
                  </div>
                </th>
                <th className=''>
                  <div
                    className='flex flex-row items-center justify-center cursor-pointer gap-1'
                    onClick={() => handleOrdering(`courses_finished`)}
                  >
                    {t('leaderboard.courses_finished')}
                    {ordering === `courses_finished` && (
                      <ChevronDownIcon className='h-4' />
                    )}
                    {ordering === `-courses_finished` && (
                      <ChevronUpIcon className='h-4' />
                    )}
                  </div>
                </th>
                <th className=''>
                  <div
                    className='flex flex-row items-center justify-center cursor-pointer gap-1'
                    onClick={() => handleOrdering(`test_passed`)}
                  >
                    {t('leaderboard.tests_passed')}
                    {ordering === `test_passed` && (
                      <ChevronDownIcon className='h-4' />
                    )}
                    {ordering === `-test_passed` && (
                      <ChevronUpIcon className='h-4' />
                    )}
                  </div>
                </th>
                <th className='pr-2'>
                  <div
                    className='flex flex-row items-center justify-center cursor-pointer gap-1'
                    onClick={() => handleOrdering(`count_roadmap_tasks`)}
                  >
                    {t('leaderboard.roadmap_tasks_completed')}
                    {ordering === `count_roadmap_tasks` && (
                      <ChevronDownIcon className='h-4' />
                    )}
                    {ordering === `-count_roadmap_tasks` && (
                      <ChevronUpIcon className='h-4' />
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, index) => (
                <tr key={index} className='border-b-2 last:border-b-0'>
                  <td className='py-1 px-2 text-center'>{index + 1}</td>
                  <td>
                    <Link
                      to={`/profile/${d?.uuid}`}
                      className='hover:underline cursor-pointer'
                    >
                      {`${d?.first_name} ${d?.last_name}`}
                    </Link>
                  </td>
                  <td>{d?.organization?.name}</td>
                  <td className='text-center'>{d?.projects_finished}</td>
                  <td className='text-center'>{d?.studyplans_finished}</td>
                  <td className='text-center'>{d?.courses_finished}</td>
                  <td className='text-center'>{d?.test_passed}</td>
                  <td className='text-center pr-2'>{d?.count_roadmap_tasks}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {page * PAGE_SIZE < n && (
            <div className='flex flex-row justify-center py-4'>
              <Button
                loading={loadingMore}
                text={t('stats.see_more')}
                onClick={() => addData()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GeneralTable;
