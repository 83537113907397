import React, { useRef } from 'react';

import { Outlet } from 'react-router-dom';
import NavSidebar from '../navigation/NavSidebars/NavSidebar';
import { ScrollContext } from '../../../utils/contexts/ScrollContext';
import { SidebarProvider } from '../../../utils/contexts/SidebarContext';

export default function FormLayout({ user, setUser }) {
  // const [t] = useTranslation();
  // const location = useLocation();
  const scrollRef = useRef();

  return (
    <>
      <div className='min-h-screen max-h-full w-full bg-sheet overflow-x-hidden'>
        <SidebarProvider>
          <NavSidebar user={user} setUser={setUser} className='z-20' />
          <ScrollContext.Provider value={{ scrollRef }}>
            <main
              className='flex flex-row w-full overflow-y-visible h-full'
              ref={scrollRef}
            >
              <div className='w-screen'>
                <Outlet />
              </div>
            </main>
          </ScrollContext.Provider>
        </SidebarProvider>
      </div>
    </>
  );
}
