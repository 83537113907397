import React, { useContext, useEffect, useState } from 'react';
import { getClassrooms } from '../../../services/classrooms/classroomService';
import ClassroomCard from '../../../components/cards/ClassroomCard';
import Add from './Add';
import { UserContext } from '../../../utils/contexts/UserContext';

export default function Classrooms() {
  const { user } = useContext(UserContext);
  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassrooms({ page: 1, page_size: 10 })
      .then((data) => {
        setClassrooms(data.results);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className='flex flex-wrap gap-4'>
      {user?.uuid && user?.roles?.some((r) => r?.name === 'PROJECTADMIN') && (
        <Add />
      )}
      {classrooms.map((classroom) => (
        <ClassroomCard key={classroom.uuid} classroom={classroom} />
      ))}
    </div>
  );
}
