import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from '../../../../../components/forms/Select/Select';
import ContentCard from './ContentCard';
import Checkboxes from './Options/Checkboxes';
import FileAnswer from './Options/FileAnswer';
import MultipleChoice from './Options/MultipleChoice';
import TextAnswer from './Options/TextAnswer';
import useResponses from './useResponses';

export default function Responses() {
  const { uuid } = useParams();
  const {
    response,
    count,
    getNextResponse,
    getPrevResponse,
    page,
    users,
    setPage,
    points,
    setPoints,
  } = useResponses(uuid);
  const [contents, setContent] = useState([]);

  useEffect(() => {
    if (response) {
      setContent(response.content);
    }
  }, [response]);

  return (
    <div className='flex flex-col gap-4 relative min-w-[48rem] max-w-3xl'>
      <div
        className={`p-6 flex flex-col rounded-lg gap-2
                  bg-white`}
      >
        <span className='text-2xl'>{count} responses</span>
        <div className='border-t' />
        <div className={`flex flex-row justify-between ${!count && 'hidden'}`}>
          <div className='flex flex-row gap-3 items-center'>
            <Select
              className='w-40'
              options={users?.map((user) => ({
                label: `${user.user?.first_name} ${user.user?.last_name}`,
                value: user,
              }))}
              value={users[page]}
              onChange={(e) => {
                setPage(users?.indexOf(e.value));
              }}
            />
            <span
              className={`text-lg ${
                page === 0 ? 'text-secondary cursor-default' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (page === 0) return;
                getPrevResponse();
              }}
            >
              {'<'}
            </span>
            {page + 1} <span>of</span> {count}
            <span
              className={`text-lg ${
                page + 1 === count
                  ? 'text-secondary cursor-default'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                if (page + 1 === count) return;
                getNextResponse();
              }}
            >
              {'>'}
            </span>
          </div>
          <div className='flex flex-row gap-1 items-center'>
            <span>{points}</span> of
            <span>
              {contents?.reduce((prev, cur) => prev + cur.points, 0)}
            </span>{' '}
            points
          </div>
        </div>
      </div>
      {page === -1 && (
        <div className='relative flex flex-col bg-white rounded-lg p-6 gap-2'>
          No such response
        </div>
      )}
      {response?.content === contents &&
        contents?.map((content) => {
          return (
            <ContentCard
              setPoints={setPoints}
              user={users[page]}
              content={content}
              key={content?.uuid + response.uuid}
            />
          );
        })}
    </div>
  );
}
