import React, { useEffect, useState } from 'react';
import { getStudentsRatingStats } from '../../../../services/stats/statsService';
import { Link } from 'react-router-dom';

export default function TopStudents() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getStudentsRatingStats({ page_size: 10 }).then((res) => {
      setData(res?.results);
    });

    return () => {};
  }, []);

  return (
    <div className='border border-gray-100 rounded-md shadow-lg p-2'>
      <div className='font-bold text-base ml-1 mb-2'>Top students</div>
      <table className='table-auto w-full'>
        <thead>
          <tr className='bg-white text-sm'>
            <th className=''>#</th>
            <th className='text-left'>{'Student'}</th>
            <th>Point</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <tr key={index} className='border-b text-sm last:border-b-0'>
              <td className='py-1 px-2 text-center'>{index + 1}</td>
              <td>
                <Link
                  to={`/profile/${d?.uuid}`}
                  className='hover:underline cursor-pointer'
                >
                  {`${d?.first_name} ${d?.last_name}`}
                </Link>
              </td>
              <td className='text-center'>{d?.point}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link
        to='rating'
        className='flex justify-center items-center cursor-pointer text-blue-600 text-sm hover:text-blue-500'
      >
        see more
      </Link>
    </div>
  );
}
