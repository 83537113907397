import { DocumentIcon } from '@heroicons/react/24/outline';
import { extractVideoID } from '../../../../../../utils/helpers/helpers';

export default function MaterialView({ event }) {
  return (
    <div className={`overflow-hidden w-full`}>
      <textarea
        readOnly
        className='focus:outline-none w-full'
        style={{ resize: 'none' }}
        label={'Description'}
        value={event?.event?.content}
      />
      <div className='flex flex-row gap-2 flex-wrap my-6'>
        {event?.event?.attached_files?.map((file) => {
          const fileName = file.upload
            .split('/')
            ?.at(-1)
            ?.replace(`${file.uuid}_`, '');
          return (
            <a href={file.upload} target='_blank' rel='noreferrer'>
              <div
                className='flex flex-col justify-center p-2 rounded-2xl border w-24 h-24 
                    text-primary hover:underline cursor-pointer group hover:bg-gray-200'
                title={fileName}
              >
                <DocumentIcon className='h-16 text-slate-400 group-hover:text-inherit' />
                <span className='truncate text-sm'>{fileName}</span>
              </div>
            </a>
          );
        })}
      </div>
      {event?.event?.video_url && extractVideoID(event?.event?.video_url) && (
        <iframe
          className='w-full aspect-video'
          src={`https://www.youtube.com/embed/${extractVideoID(
            event?.event?.video_url,
          )}`}
          title={'Addtional video'}
          allowfullscreen='allowfullscreen'
        />
      )}
    </div>
  );
}
