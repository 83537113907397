import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getClassroom } from '../../../services/classrooms/classroomService';
import { getClassroomSections } from '../../../services/classrooms/sectionsService';
import TeacherClassroom from './Teacher/TeacherClassroom';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import StudentClassroom from './Student/StudentClassroom';

export default function Classroom() {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [classroom, setClassroom] = useState('');
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setLoading(true);
    getClassroom(uuid)
      .then((data) => {
        setClassroom(data);
        getClassroomSections({ classroom: uuid, page_size: 100 })
          ?.then((res) => {
            setSections(res.results);
          })
          ?.finally(() => setLoading(false));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t('Failed to load classroom data'));
        console.error(
          'Error loading classroom data:',
          err.response ? err.response.data : err.message,
        );
      });
  }, [uuid, t]);

  return loading ? (
    <div className='relative'>
      <SimpleLoader className='h-10' />
    </div>
  ) : ['OWNER', 'TEACHER'].includes(classroom.my_role) ? (
    <TeacherClassroom classroom={classroom} sections={sections} />
  ) : (
    <StudentClassroom classroom={classroom} sections={sections} />
  );
}
