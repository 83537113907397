import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentCard from './ContentCard/ContentCard';
import MiniRichTextEditor from '../../../../../components/forms/Inputs/MiniRichTextEditor';
import FormToolbar from './FormToolbar';
import { patchForm } from '../../../../../services/forms/formsService';
import useForm from '../../useForm';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { patchFormContent } from '../../../../../services/forms/formContentService';
import { drugReorder } from '../../../../../utils/helpers/helpers';

export default function Questions() {
  const { uuid } = useParams();
  const { form, contents, setContents, fetchContents, points, setPoints } =
    useForm(uuid);

  const [selected, setSelected] = useState(uuid);
  const [title, setTitle] = useState('');
  const [draggingId, setDraggingId] = useState();

  const titleTimerRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionTimerRef = useRef(null);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = drugReorder(
      contents,
      result.source.index,
      result.destination.index,
    );

    const movedItem = contents[result.source.index];
    setContents(reorderedItems);
    setDraggingId(null);

    patchFormContent(movedItem.uuid, { order: result.destination.index + 1 });
  };

  const saveForm = useCallback(
    (value, field) => {
      patchForm(uuid, { [field]: value })
        ?.then((res) => {
          setTitle(res.name);
        })
        .catch((err) => console.error('Ошибка при сохранении формы:', err));
    },
    [uuid],
  );

  const debounceSaveData = useCallback(
    (value, ref, field) => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
      ref.current = setTimeout(() => {
        if (field === 'name' && value === '') {
          titleRef.current.innerHTML = title || form.name;
          return;
        }

        saveForm(value, field);
      }, 1000);
    },
    [saveForm, title, form?.name],
  );

  const onDragStart = (start) => {
    setDraggingId(start.draggableId);
  };

  return (
    <div className='flex flex-col gap-4 relative min-w-[48rem] max-w-3xl'>
      {selected === uuid && <FormToolbar update={fetchContents} uuid={uuid} />}
      <div
        onClick={() => setSelected(uuid)}
        className={`p-6 flex flex-col rounded-lg gap-2
                  bg-white border-l-[0.4rem] ${
                    selected === uuid ? 'border-l-blue-500' : 'border-l-white'
                  } border-t-[0.6rem] border-t-violet-700`}
      >
        <MiniRichTextEditor
          ref={titleRef}
          borderColor='text-violet-700'
          className={`${
            selected === uuid && 'border-b'
          } focus:outline-none text-3xl min-h-[3.125rem]`}
          defaultValue={form?.name}
          onChange={(e) => {
            debounceSaveData(e, titleTimerRef, 'name');
          }}
        />
        <MiniRichTextEditor
          borderColor='text-violet-700'
          className={`${selected === uuid && 'border-b'} focus:outline-none`}
          list
          defaultValue={form?.descritption} // fix description
          onChange={(e) => {
            debounceSaveData(e, descriptionTimerRef, 'descritption'); // fix description
          }}
          placeholder={'Form description'} // to translate
        />
        <div className='flex flex-row justify-end'>total points: {points} </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId='contents'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='flex flex-col gap-4'
            >
              {contents?.map((content, i) => {
                let isSelected = selected === content?.uuid;
                return (
                  <Draggable
                    key={content.uuid}
                    draggableId={content.order?.toString()}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={{
                          userSelect: 'none',
                          // backgroundColor: '#fff',
                          // border: '1px solid lightgrey',
                          ...provided.draggableProps.style,
                        }}
                        className='relative'
                        key={content?.uuid}
                      >
                        {isSelected && (
                          <FormToolbar
                            update={fetchContents}
                            uuid={uuid}
                            order={i + 1}
                          />
                        )}
                        <ContentCard
                          id={content?.uuid}
                          content={content}
                          selected={isSelected}
                          selectContent={() => setSelected(content?.uuid)}
                          update={() => fetchContents()}
                          setContents={setContents}
                          setPoints={setPoints}
                          dragProps={provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                          draggingId={draggingId}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
