import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getClassroomMaterialEvents = ({ page, page_size, section }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/material_event/`, {
          params: { page, page_size, section },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getClassroomMaterialEvent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/class/material_event/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postClassroomMaterialEvent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/class/material_event/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          console.error(
            'Error response:',
            err.response ? err.response.data : err.message,
          );
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchClassroomMaterialEvent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/class/material_event/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteClassroomMaterialEvent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/class/material_event/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
