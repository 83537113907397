import {
  DocumentTextIcon,
  InformationCircleIcon,
  PlusIcon,
  PlusSmallIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import SidebarModal from '../../../../../../components/modals/SidebarModal/SidebarModal';
import { SidebarContext } from '../../../../../../utils/contexts/SidebarContext';
import AssignmentForm from './AssignmentForm';
import MaterialForm from './MaterialForm';
import MeetingForm from './MeetingForm';

export default function Add({ section, addObject, classroom }) {
  const [t] = useTranslation();
  const { id, setId } = useContext(SidebarContext);
  const [type, setType] = useState();
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const onClose = () => {
    setType();
  };

  useEffect(() => {
    setId('');
  }, [section, setId]);

  return (
    <>
      <Button
        Icon={PlusIcon}
        iconColor='h-7 stroke-[2.5]'
        onClick={() => {
          setId(uuid);
        }}
      />
      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='w-[30rem] flex flex-col h-full'>
          {!!type && (
            <div className='px-4 pb-4'>
              <Button text='back' onClick={() => setType()} />
            </div>
          )}
          {type === 'meeting' ? (
            <MeetingForm
              classroom={classroom}
              section={section}
              onClose={() => {
                onClose();
                setId('');
              }}
              addObject={addObject}
            />
          ) : type === 'material' ? (
            <MaterialForm
              section={section}
              onClose={() => {
                onClose();
                setId('');
              }}
              addObject={addObject}
            />
          ) : type === 'assignment' ? (
            <AssignmentForm
              section={section}
              onClose={() => {
                onClose();
                setId('');
              }}
              addObject={addObject}
            />
          ) : (
            <div className='flex flex-row justify-center'>
              <div className='divide-y-2 max-w-fit border rounded-xl text-2xl cursor-pointer overflow-hidden'>
                <div
                  className='flex flex-row gap-2 items-center p-4 hover:bg-gray-200'
                  onClick={() => setType('meeting')}
                >
                  <VideoCameraIcon className='h-7 stroke-1' />
                  <span>Create meeting</span>
                </div>
                <div
                  className='flex flex-row gap-2 items-center p-4 hover:bg-gray-200'
                  onClick={() => setType('material')}
                >
                  <InformationCircleIcon className='h-7 stroke-1' />
                  <span>Create additional material</span>
                </div>
                <div
                  className='flex flex-row gap-2 items-center p-4 hover:bg-gray-200'
                  onClick={() => setType('assignment')}
                >
                  <DocumentTextIcon className='h-7 stroke-1' />
                  <span>Create assignment</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </SidebarModal>
    </>
  );
}
