import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getClassroomAssignmentEvents } from '../../../../../../services/classrooms/assignmentService';
import { getStudentSubmissionsReview } from '../../../../../../services/classrooms/submitionServise';
import './FixedColumnsTable.css'; // Import custom CSS

export default function Grades() {
  const { uuid } = useParams();
  const [grades, setGrades] = useState([]);
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    if (uuid) {
      getClassroomAssignmentEvents({ classroom: uuid }).then((res) => {
        console.log('assignments', res);

        setAssignments(
          res?.results?.map((as) => {
            return { uuid: as.uuid, name: as.name };
          }),
        );
      });

      getStudentSubmissionsReview({ classroom: uuid }).then((res) => {
        console.log('res', res);
        setGrades(res?.results);
      });
    }

    return () => {};
  }, [uuid]);

  return (
    <div className='overflow-x-auto'>
      <table className='min-w-full border-collapse'>
        <thead>
          <tr>
            <th className='sticky left-0 bg-white border-b text-start font-normal border-gray-200 z-10'>
              Student name
            </th>
            {assignments?.map((as) => (
              <th
                key={as?.uuid}
                className='border-b border-gray-200 font-normal text-start'
              >
                {as.name}
              </th>
            ))}
            <th className='sticky right-0 bg-white border-b border-gray-200 z-10 text-start'>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {grades?.map((g, index) => (
            <tr key={index} className='odd:bg-slate-200 even:bg-white'>
              <td className='sticky left-0 bg-inherit border-b  border-gray-200 z-10'>
                {`${g?.first_name} ${g?.last_name}`}
              </td>
              {assignments?.map((as, i) => (
                <td key={i} className='border-b  border-gray-200'>
                  <p className=''>
                    {g[as?.uuid] === null ? 'No grade' : g[as?.uuid]}
                  </p>
                </td>
              ))}
              <td className='sticky right-0 bg-slate-200 border-b  border-gray-200 z-10 font-semibold'>
                {g?.total}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
