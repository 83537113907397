import React, { useEffect, useRef } from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import NavSidebar from '../navigation/NavSidebars/NavSidebar';
import { ScrollContext } from '../../../utils/contexts/ScrollContext';
import { SidebarProvider } from '../../../utils/contexts/SidebarContext';
import ProfileSidebar from '../navigation/ProfileSidebars/ProfileSidebar';
import FormLayout from './FormLayout';

export default function BaseLayout({ user, setUser }) {
  const { pathname } = useLocation();
  // const [t] = useTranslation();
  const scrollRef = useRef();

  if (pathname?.startsWith('/forms/')) {
    return <FormLayout user={user} setUser={setUser} />;
  }

  return (
    <>
      <div className='h-screen w-full bg-sheet overflow-x-hidden'>
        <SidebarProvider>
          <NavSidebar
            user={user}
            setUser={setUser}
            className='z-20 sticky top-0'
          />
          <ScrollContext.Provider value={{ scrollRef }}>
            <main
              className='flex flex-row w-full overflow-y-auto h-[92vh]'
              ref={scrollRef}
            >
              <div className='py-2 md:py-2 sm:px-4 lg:px-6 w-screen'>
                {/* <div className='py-2 md:py-2 sm:px-4 lg:px-6 w-screen md:w-9/12 lg:w-4/5'> */}
                <Outlet />
              </div>
              <div className='hidden md:block md:w-3/12 lg:w-1/5 h-[90vh]'>
                <ProfileSidebar user={user} />
              </div>
            </main>
          </ScrollContext.Provider>
        </SidebarProvider>
      </div>
    </>
  );
}
