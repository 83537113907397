import {
  EyeIcon,
  EyeSlashIcon,
  PaperClipIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../components/forms/Buttons/Button';
import SimpleLoader from '../../../../../components/Loader/SimpleLoader';
import { deleteClassroomEvent } from '../../../../../services/classrooms/eventsService';
import FullEventCard from './FullEventCard';

export default function EventCard({ event, removeEvent, isTeacher }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteConfirmation = () => {
    const handleDelete = () => {
      setIsLoading(true);
      deleteClassroomEvent(event.uuid)
        ?.then(() => {
          removeEvent();
        })
        ?.finally(() => {
          setIsLoading(false);
        });
    };

    toast.dark(
      <>
        <div>Вы точно хотите удалить пост?</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button text='Да' color='error-solid' onClick={handleDelete} />
          <Button text='Нет' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  const color =
    event?.event?.type === 'meeting'
      ? 'bg-primary'
      : event?.event?.type === 'material'
      ? 'bg-yellow-500'
      : event?.event?.type === 'assignment'
      ? 'bg-violet-700'
      : '';

  return (
    <div>
      <div
        className={`flex flex-row h-24 w-full rounded-xl ${
          isOpen && 'rounded-b-none'
        } border relative group `}
      >
        <div className='absolute group-hover:block hidden -top-2 -right-2'>
          {isLoading ? (
            <div className='relative bg-white'>
              <SimpleLoader className='h-4' />
            </div>
          ) : (
            isTeacher && (
              <XMarkIcon
                className='bg-red-100 text-error rounded-full h-5 p-0.5 cursor-pointer'
                onClick={() => {
                  handleDeleteConfirmation();
                }}
              />
            )
          )}
        </div>
        <div
          className={`flex flex-col justify-center items-center w-1/6 
                   text-white text-xl font-semibold ${
                     isOpen ? 'rounded-tl-xl' : 'rounded-l-xl'
                   }
                   ${color}`}
        >
          {format(new Date(event.start_time), 'HH:mm')}
        </div>
        <div className='flex flex-row justify-between w-full'>
          <div className='flex flex-col px-8 pt-4 pb-2 justify-between'>
            <span className='font-semibold text-lg'>{event.event?.name}</span>
            <div>
              {!!event.attachments_number && (
                <div className='flex flex-row items-center gap-1'>
                  <PaperClipIcon className='h-5 -rotate-45' />
                  {event.attachments_number}
                  <span className='text-sm'>attachments</span>
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col justify-center pr-4'>
            {isOpen ? (
              <EyeSlashIcon
                onClick={() => setIsOpen(false)}
                className={`h-10 p-2 rounded-full ${color} text-white cursor-pointer`}
              />
            ) : (
              <EyeIcon
                onClick={() => setIsOpen(true)}
                className={`h-10 p-2 rounded-full ${color} text-white cursor-pointer`}
              />
            )}
          </div>
        </div>
      </div>
      {isOpen && <FullEventCard uuid={event.uuid} isTeacher={isTeacher} />}
    </div>
  );
}
