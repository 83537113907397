import React, { useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { patchFormContent } from '../../../../../../services/forms/formContentService';
import CustomDialog from '../../../../../../components/modals/CustomDialog/CustomDialog';
import Upload from '../../../../../../components/upload/Upload';

export default function UploadImageModal({ uuid, update, setUploading }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <PhotoIcon
        className='p-2.5 h-11 min-w-fit cursor-pointer hover:bg-slate-100 rounded-full'
        onClick={() => {
          setOpen(true);
        }}
      />
      <CustomDialog
        isOpen={open}
        closeModal={() => setOpen(false)}
        children={
          <div>
            <Upload
              accept='image/png, image/gif, image/jpeg'
              setFile={(file) => {
                const body = new FormData();
                body.append('attachment_img', file);
                setUploading(true);
                patchFormContent(uuid, body)
                  ?.then(() => {
                    update()?.finally(() => {
                      setUploading(false);
                    });
                  })
                  ?.catch(() => {
                    setUploading(false);
                  });
                setOpen(false);
              }}
            />
          </div>
        }
      />
    </>
  );
}
