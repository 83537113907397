import React, { useEffect, useState } from 'react';
import TableOfProjects from '../../../../components/tables/TableOfProjects/TableOfProjects';
import {
  DocumentArrowUpIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { getProjectAssignments } from '../../../../services/submissions/projectAssignmentService';
import { getProjectSubmissions } from '../../../../services/submissions/projectSubmissionService';
import { RadioGroup } from '@headlessui/react';
import TableOfSubmissions from '../../../../components/tables/TableOfProjects/TableOfSubmissions';
import NoContentCard from '../../../../components/cards/NoContentCard';

const plans = [
  {
    icon: DocumentTextIcon,
    name: 'In progress',
  },
  {
    icon: DocumentArrowUpIcon,
    name: 'Submissions',
  },
  {
    icon: DocumentCheckIcon,
    name: 'Evaluated',
  },
];

export default function Projects({ uuid, isOwnProfile }) {
  const [selected, setSelected] = useState(plans[0]);
  const [projectsInProgress, setProjectsInProgress] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [evaluated, setEvaluated] = useState([]);

  useEffect(() => {
    if (uuid) {
      getProjectAssignments({
        uuid: null,
        student: uuid,
        is_in_progress: true,
        submitted: false,
      }).then((res) => {
        setProjectsInProgress(res.results);
      });

      getProjectSubmissions({
        uuid: null,
        student: uuid,
        evaluated: false,
      }).then((res) => {
        setSubmissions(res.results);
      });

      getProjectSubmissions({
        uuid: null,
        student: uuid,
        evaluated: true,
      }).then((res) => {
        setEvaluated(res.results);
      });
    }

    return () => {};
  }, [uuid]);

  return (
    <div className='w-full h-full flex flex-col gap-4 shadow-md'>
      <div>
        <div className='w-full px-4 pt-8'>
          <div className='w-full'>
            <RadioGroup
              value={selected}
              onChange={(v) => {
                // getProjects()
                setSelected(v);
              }}
            >
              <RadioGroup.Label className='sr-only'>
                Server size
              </RadioGroup.Label>
              <div className='flex flex-col gap-4 lg:flex-row lg:gap-8'>
                {plans.map((plan) => (
                  <RadioGroup.Option
                    key={plan.name}
                    value={plan}
                    className={({ active, checked }) =>
                      `${
                        active
                          ? 'ring-2 ring-white ring-opacity-60 ring-offset-2'
                          : ''
                      }
          ${checked ? 'bg-gray-200 ' : 'bg-white'}
            relative flex cursor-pointer rounded-lg px-4 py-1 border focus:outline-none`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className='flex w-full justify-between'>
                          <div className='flex'>
                            <div className='text-sm'>
                              <RadioGroup.Label
                                as='p'
                                className={`font-medium text-lg  ${
                                  checked ? 'text-black' : 'text-gray-500'
                                }`}
                              >
                                <plan.icon className='h-6 inline-block mr-2' />
                                {plan.name}
                              </RadioGroup.Label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
      {isOwnProfile ? (
        <div className='overflow-x-scroll lg:overflow-hidden'>
          {selected === plans[0] &&
            (projectsInProgress.length ? (
              <TableOfProjects
                data={projectsInProgress}
                typeOfData={'project'}
              />
            ) : (
              <div className='flex flex-row'>
                <NoContentCard />
              </div>
            ))}
          {selected === plans[1] &&
            (submissions.length ? (
              <TableOfProjects data={submissions} typeOfData={'submission'} />
            ) : (
              <div className='flex flex-row'>
                <NoContentCard />
              </div>
            ))}
          {selected === plans[2] &&
            (evaluated.length ? (
              <TableOfProjects data={evaluated} typeOfData={'submission'} />
            ) : (
              <div className='flex flex-row'>
                <NoContentCard />
              </div>
            ))}
        </div>
      ) : (
        <div className='overflow-x-scroll lg:overflow-hidden'>
          {selected === plans[0] &&
            (projectsInProgress.length ? (
              <TableOfSubmissions
                data={projectsInProgress}
                typeOfData={'project'}
              />
            ) : (
              <div className='flex flex-row'>
                <NoContentCard />
              </div>
            ))}
          {selected === plans[1] &&
            (submissions.length ? (
              <TableOfSubmissions
                data={submissions}
                typeOfData={'submission'}
              />
            ) : (
              <div className='flex flex-row'>
                <NoContentCard />
              </div>
            ))}
          {selected === plans[2] &&
            (evaluated.length ? (
              <TableOfSubmissions data={evaluated} typeOfData={'submission'} />
            ) : (
              <div className='flex flex-row'>
                <NoContentCard />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
