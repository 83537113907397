import React from 'react';

export default function InputWithIcon({
  name,
  label,
  placeholder,
  Icon,
  onChange,
  onIconClick,
  classnameIcon,
  defaultValue,
}) {
  return (
    <div>
      <label
        htmlFor='price'
        className='block text-sm font-medium leading-6 text-gray-900'
      >
        {label}
      </label>
      <div className='relative rounded-md shadow-sm'>
        <div
          className={`${
            !onIconClick ? 'pointer-events-none' : 'cursor-pointer'
          } absolute inset-y-0 left-0 flex items-center pl-2`}
        >
          <span>
            <Icon className={`h-4 ${classnameIcon}`} onClick={onIconClick} />
          </span>
        </div>
        <input
          type='text'
          name={name}
          id={name}
          defaultValue={defaultValue}
          className='block h-8 w-full rounded-md border-0 py-2 pl-10 pr-20 text-gray-900 ring-1 ring-inset 
            ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none sm:text-sm sm:leading-6'
          placeholder={placeholder ? placeholder : 'Search'}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
