import React from 'react';
import { Navigate } from 'react-router-dom';

export default function CommonRoute({ user }) {
  if (user?.roles?.some((role) => role?.name === 'STUDENT')) {
    return <Navigate to={'projects'} replace />;
  }

  if (
    user?.roles?.some((role) => role?.name === 'PROJECTADMIN') ||
    user?.roles.some((role) => role.name === 'PROJECTREVIEWER')
  ) {
    return <Navigate to={'submissions'} replace />;
  }
}
