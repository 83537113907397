import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../../../../../utils/contexts/UserContext';
import { toast } from 'react-toastify';
import {
  getClassroomUsers,
  getClassroomOwner,
  deleteClassroomUser,
} from '../../../../../services/classrooms/classroomService';
import { Button } from '../../../../../components/forms/Buttons/Button';

const Users = () => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const { uuid } = useParams();
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);

  useEffect(() => {
    getClassroomUsers(uuid)
      .then((data) => {
        setTeachers(data.filter((user) => user.role_type === 'TEACHER'));
        setStudents(data.filter((user) => user.role_type === 'STUDENT'));

        setIsTeacher(
          data.some(
            (u) => u.account.uuid === user.uuid && u.role_type === 'TEACHER',
          ),
        );
      })
      .catch((err) => {
        toast.error(t('Failed to load classroom users'));
        console.error(
          'Error loading classroom users:',
          err.response ? err.response.data : err.message,
        );
      });

    getClassroomOwner(uuid)
      .then((data) => {
        setIsOwner(
          data.results.some((owner) => owner.account.uuid === user.uuid),
        );
      })
      .catch((err) => {
        toast.error(t('Failed to load classroom owner'));
        console.error(
          'Error loading classroom owner:',
          err.response ? err.response.data : err.message,
        );
      });
  }, [uuid, t, user.uuid]);

  const handleRemoveUser = (userId) => {
    const handleDelete = () => {
      deleteClassroomUser(userId)
        .then(() => {
          toast.success(t('User removed successfully'));
          getClassroomUsers(uuid)
            .then((data) => {
              setTeachers(data.filter((user) => user.role_type === 'TEACHER'));
              setStudents(data.filter((user) => user.role_type === 'STUDENT'));
            })
            .catch((err) => {
              toast.error(t('Failed to load classroom users'));
              console.error(
                'Error loading classroom users:',
                err.response ? err.response.data : err.message,
              );
            });
        })
        .catch((err) => {
          toast.error(t('Failed to remove user'));
          console.error(
            'Error removing user:',
            err.response ? err.response.data : err.message,
          );
        });
    };

    toast.dark(
      <>
        <div>Вы точно хотите удалить пользователя?</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button text='Да' color='error-solid' onClick={handleDelete} />
          <Button text='Нет' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  return (
    <div className='flex flex-col gap-4 border p-4'>
      <div className='flex flex-col border'>
        <div className='flex flex-row justify-between items-center p-2'>
          <div className='font-bold'>{t('classrooms.teachers')}</div>
          {false && isOwner && <UserPlusIcon className='h-6 cursor-pointer' />}
        </div>
        <div className='flex flex-col border-t'>
          {teachers.map((teacher, index) => (
            <div
              key={teacher.uuid}
              className={`flex flex-row justify-between items-center p-2 ${
                index % 2 === 0 ? 'bg-white' : 'bg-blue-100'
              }`}
            >
              <div>{`${teacher.account.first_name} ${teacher.account.middle_name} ${teacher.account.last_name}`}</div>
              {isOwner && (
                <div
                  className='text-red-500 cursor-pointer'
                  onClick={() => handleRemoveUser(teacher.uuid)}
                >
                  remove
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='flex flex-col border'>
        <div className='flex flex-row justify-between items-center p-2'>
          <div className='font-bold'>{t('classrooms.students')}</div>
          {false && (isOwner || isTeacher) && (
            <UserPlusIcon className='h-6 cursor-pointer' />
          )}
        </div>
        <div className='flex flex-col border-t'>
          {students.map((student, index) => (
            <div
              key={student.uuid}
              className={`flex flex-row justify-between items-center p-2 ${
                index % 2 === 0 ? 'bg-white' : 'bg-blue-100'
              }`}
            >
              <a
                className='hover:underline hover:text-primary'
                href={`/profile/${student.user}`}
                target='_blank'
                rel='noreferrer'
              >{`${student.account.first_name} ${student.account.middle_name} ${student.account.last_name}`}</a>
              {(isOwner || isTeacher) && (
                <div
                  className='text-red-500 cursor-pointer'
                  onClick={() => handleRemoveUser(student.uuid)}
                >
                  remove
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Users;
