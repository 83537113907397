import bilAPI from '../api';
import contentApi from '../contentApi';

export const getPosts = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/posts/posts/')
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getPost = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/posts/posts/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postPosts = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentApi
        .post(`/posts/posts/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const likePost = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/posts/posts/${uuid}/like_post/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const pinPost = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/posts/posts/${uuid}/toggle_pin/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchPost = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/posts/posts/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deletePost = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/posts/posts/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
