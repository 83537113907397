import React from 'react';
import {
  ChartBarIcon,
  ChatBubbleLeftIcon,
  CheckIcon,
  ClockIcon,
  LightBulbIcon,
  PlayCircleIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { useNavigate } from 'react-router-dom';
import { getColorForProjectLevel } from '../../../utils/helpers/helpers';

// DO NOT USE IT YET :)
// DO NOT USE IT YET :)
// DO NOT USE IT YET :)
// DO NOT USE IT YET :)
// DO NOT USE IT YET :)
// DO NOT USE IT YET :)

export default function TableOfSubmissions({ data, typeOfData }) {
  const navigate = useNavigate();

  return (
    <table className='table-auto w-full'>
      <thead>
        <tr className='border-b border-gray-200'>
          <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
            Status
          </th>
          <th className='px-4 py-2 text-left text-sm text-gray-500 font-normal'>
            Title
          </th>
          <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
            Domain
          </th>
          <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
            Subject
          </th>
          <th className='px-4 py-2 text-center text-sm text-gray-500 font-normal'>
            Difficulty
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((p, i) => (
          <tr
            key={p.uuid}
            className={`border-b border-gray-200 even:bg-gray-100 ${
              typeOfData === 'submission'
                ? 'cursor-pointer hover:bg-gray-300'
                : 'cursor-pointer hover:bg-gray-300'
            }`}
            onClick={() => {
              if (typeOfData === 'submission') {
                navigate(`${'/submissions/' + p?.uuid}`);
              }
              if (typeOfData === 'project') {
                navigate(`${'/projects/' + p?.project?.uuid}`);
              }
            }}
          >
            <td className='px-4 py-2 text-center '>
              {/* in progress */}
              {typeOfData === 'project' && (
                <PlayCircleIcon className='h-4 inline-block text-green-600' />
              )}
              {/* submissions  */}
              {!p?.is_checking && p?.evaluation === null && (
                <ClockIcon className='h-4 inline-block text-orange-600' />
              )}
              {p?.is_checking && p?.evaluation === null && (
                <ClockIcon className='h-4 inline-block text-blue-600' />
              )}
              {typeOfData === 'submission' &&
                p?.evaluation === null &&
                (p?.is_checking ? (
                  <span className='text-sm ml-1 text-blue-600'>Checking</span>
                ) : (
                  <span className='text-sm ml-1 text-orange-600'>Waiting</span>
                ))}
              {/* evaluated */}
              {p?.evaluation === 1 && (
                <div>
                  <CheckIcon className='h-4 inline-block text-green-600' />
                  <span className='text-sm ml-1 text-green-600'>Accepted</span>
                </div>
              )}
              {p?.evaluation === 0 && (
                <div>
                  <XMarkIcon className='h-4 inline-block text-red-600' />
                  <span className='text-sm ml-1 text-red-600'>Declined</span>
                </div>
              )}
            </td>
            <td className='px-4 py-2 text-left'>
              {typeOfData === 'submission' &&
                p?.project_assignment?.project?.name}
              {typeOfData === 'project' && p?.project?.name}
              {/* {!typeOfData && p?.name} */}
            </td>
            <td className='px-4 py-2 text-center text-green-600'>
              {typeOfData === 'submission' &&
                p?.project_assignment?.project?.domain?.name}
              {typeOfData === 'project' && p?.project?.domain?.name}
            </td>
            <td className='px-4 py-2 text-center'>
              {typeOfData === 'submission' &&
                p?.project_assignment?.project?.skill?.name}
              {typeOfData === 'project' && p?.project?.skill?.name}
            </td>
            <td
              className={`px-4 py-2 text-center ${getColorForProjectLevel(
                typeOfData === 'submission'
                  ? p?.project_assignment?.project?.level
                  : p?.project?.level,
              )}`}
            >
              {typeOfData === 'submission' &&
                p?.project_assignment?.project?.level}
              {typeOfData === 'project' && p?.project?.level}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
