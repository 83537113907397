import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { postForm } from '../../../services/forms/formsService';

export default function Add() {
  const navigate = useNavigate();

  return (
    <div
      className='relative w-72 h-48 rounded border-4 cursor-pointer
        flex flex-col justify-center text-lg items-center font-bold
        hover:border-violet-500 hover:border
        '
      onClick={() => {
        postForm({})?.then((res) => {
          navigate(`${res.uuid}/edit`);
        });
      }}
    >
      <PlusIcon className='h-12 text-amethyst' />
      <div className='bg-gradient-to-r from-amethyst to-error bg-clip-text text-transparent'>
        {'create new form'}
      </div>
    </div>
  );
}
