import { PencilIcon } from '@heroicons/react/24/outline';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/InputBil';
import Select from '../../../components/forms/Select/Select';
import { toast } from 'react-toastify';
import {
  acceptRoadmapTask,
  deleteRoadmapTask,
  patchRoadmapTask,
  unacceptRoadmapTask,
} from '../../../services/roadmapTask/roadmapTaskService';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import { UserContext } from '../../../utils/contexts/UserContext';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';
import SidebarModal from '../../../components/modals/SidebarModal/SidebarModal';

const Edit = ({ datum, domains, refreshData, className, isStudent }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const [title, setTitle] = useState(null);
  const [domain, setDomain] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const reset = useCallback(() => {
    setDomain(datum?.domains?.map((dd) => dd?.uuid));
    setTitle(datum?.name);
  }, [datum?.domains, datum?.name]);

  useEffect(() => {
    if (datum && title === null) {
      reset();
    }
  }, [datum, reset, title]);

  const handleDeleteConfirmation = () => {
    toast.dark(
      <>
        <div>{t('roadmap.are_you_sure')}</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text={t('buttons.yes')}
            color='error-solid'
            onClick={() => {
              setLoadingDelete(true);
              deleteRoadmapTask(datum?.uuid)
                .then(() => {
                  toast.success(t('roadmap.deleted_successfully'));
                  refreshData();
                  setId();
                })
                .finally(() => setLoadingDelete(false));
            }}
          />
          <Button
            text={t('buttons.no')}
            className='text-white'
            onClick={toast.dismiss}
          />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  const onClose = () => {
    reset();
  };

  return (
    <>
      <div
        className={className}
        onClick={() => {
          setId(uuid);
        }}
      >
        {datum?.name}
      </div>
      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.edit')}
        HeaderIcon={PencilIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title ?? ''}
            readOnly={isStudent}
            required
          />
          <Select
            label={t('stats.domain')}
            options={domains?.map((d) => ({ label: d?.name, value: d?.uuid }))}
            onChange={(e) => {
              setDomain(e?.map((v) => v.value));
            }}
            value={domain}
            isMulti
            closeMenuOnSelect={false}
            isDisabled={isStudent}
            required
          />

          <div className='flex flex-row gap-4 text-gray-500'>
            <DatePicker
              date={datum?.start_date ? new Date(datum?.start_date) : null}
              label={t('roadmap.start_date')}
              readOnly
            />
            <DatePicker
              date={datum?.deadline ? new Date(datum?.deadline) : null}
              label={t('roadmap.end_date')}
              readOnly
            />
          </div>

          <div className='flex flex-row gap-4 text-gray-500'>
            <DatePicker
              date={datum?.created ? new Date(datum?.created) : null}
              label={t('roadmap.created_date')}
              readOnly
            />
            <DatePicker
              date={datum?.last_updated ? new Date(datum?.last_updated) : null}
              label={t('roadmap.last_modified_date')}
              readOnly
            />
          </div>

          <div className='flex flex-row gap-2'>
            <p>{`${t('roadmap.task_added_by')}:`}</p>
            <p
              className={`${
                datum?.assignor ? 'text-primary' : 'text-secondary italic'
              }`}
            >
              {datum?.assignor
                ? `${datum?.assignor?.first_name} ${datum?.assignor?.last_name}`
                : t('performance.none')}
            </p>
          </div>

          <div className='flex flex-row gap-2'>
            <p>{`${t('roadmap.task_accepted_by')}:`}</p>
            <p
              className={`${
                datum?.reviewer ? 'text-primary' : 'text-secondary italic'
              }`}
            >
              {datum?.reviewer
                ? `${datum?.reviewer?.first_name} ${datum?.reviewer?.last_name}`
                : t('performance.none')}
            </p>
          </div>
          {!isStudent && (
            <div
              className={`flex flex-row gap-4 mt-4 ${
                isStudent ? 'justify-center' : 'justify-between'
              }`}
            >
              <div className='flex flex-row gap-2'>
                <Button
                  color='error'
                  text={t('roadmap.delete')}
                  loading={loadingDelete}
                  onClick={() => {
                    handleDeleteConfirmation();
                  }}
                  disabled={datum?.status === 'finished'}
                />
                <Button
                  text={t('roadmap.save')}
                  loading={loadingSubmit}
                  onClick={() => {
                    if (!title || domain?.length === 0) {
                      toast.error(t('roadmap.required_fields_empty'));
                      return;
                    }

                    const body = {};
                    body['name'] = title;
                    body['domains'] = domain.map((d) => d?.value);

                    setLoadingSubmit(true);
                    patchRoadmapTask(datum?.uuid, body)
                      .then(() => {
                        toast.success(t('roadmap.saved_successfully'));
                        refreshData();
                        setId('');
                      })
                      .finally(() => setLoadingSubmit(false));
                  }}
                  disabled={datum?.status === 'finished'}
                />
              </div>

              <div>
                {datum?.status === 'finished' ? (
                  <Button
                    color='primary'
                    text={t('roadmap.open_task')}
                    loading={loadingOpen}
                    onClick={() => {
                      setLoadingOpen(true);
                      unacceptRoadmapTask(datum?.uuid)
                        .then(() => {
                          toast.success(t('roadmap.opened_successfully'));
                          refreshData();
                          onClose();
                        })
                        .finally(() => setLoadingOpen(false));
                    }}
                  />
                ) : (
                  <Button
                    color='success'
                    text={t('roadmap.accept_close')}
                    loading={loadingAccept}
                    onClick={() => {
                      const body = {};
                      body['reviewer'] = user?.uuid;

                      setLoadingAccept(true);
                      acceptRoadmapTask(datum?.uuid, body)
                        .then(() => {
                          toast.success(t('roadmap.accepted_successfully'));
                          refreshData();
                          onClose();
                          setId('');
                        })
                        .finally(() => setLoadingAccept(false));
                    }}
                    disabled={datum?.status === 'in-progress'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </SidebarModal>
    </>
  );
};

export default Edit;
